<template>
    <div class="content">
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <div class="d-flex align-items-center">
              <h6 class="card-title font-weight-semibold">Informasi Tindakan Kedokteran</h6>
            </div>
          </div>
  
        <validation-observer ref="VForm">
            <b-form @submit.prevent="doSubmit" class="card">
            <div class="form-underlying card-body p-3">
              <div class="row">
                <div class="col-md-6 col-xl-4">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Form DNR dibuat atas <strong class="text-danger">*</strong></label>
                    <v-select placeholder="-- Pilih Salah Satu --" v-model="rowDNR.aud_form_dibuat_atas" :options="Config.mr.dnrDibuatAtas" label="text" value="value" :clearable="true" :reduce="v=>v.value"></v-select>
                    <VValidate 
                        name="Dibuat Atas" 
                        v-model="rowDNR.aud_form_dibuat_atas" 
                        :rules="toValidate(mrValidationDNR.aud_form_dibuat_atas)"
                    />
                  </div>
                </div>
              </div>
              
              <div id="opsiDpjp" v-if="rowDNR.aud_form_dibuat_atas == 'DPJP'">
                <div class="row">
                  <div class="col-md-9 col-lg-8">
                    <div class="form-group">
                      <label for="">Pernyataan dan Instruksi Dokter<strong class="text-danger">*</strong></label>
                      <b-form-radio class="custom-control custom-radio" value="komprehensif" v-model="rowDNR.aud_dpjp_usaha" name="pernyataanInstruksi">Usaha komprehensif untuk mencegah henti jantung atau henti nafas, TANPA melakukan intubasi, DNR jika henti jantung atau henti nafas terjadi, TIDAK melakukan CPR.</b-form-radio>
                      
                      <b-form-radio class="custom-control custom-radio" value="suportif" v-model="rowDNR.aud_dpjp_usaha" name="pernyataanInstruksi">
                      Usaha suportif sebelum terjadi henti jantung atau henti nafas yang meliputi pembukaan jalan nafas secara non invasif, pemberian oksigen, mengontrol pendarahan, memposisikan pasien dengan <input type="text" v-model="rowDNR.aud_form_dibuat_atas_lainnya" class="form-control form-control-sm w-auto d-inline-block">, obat-obatan anti alergi, TIDAK melakukan CPR bila henti jantung atau henti nafas terjadi.</b-form-radio>
                      
                      <VValidate 
                        name="DPJP Usaha" 
                        v-model="rowDNR.aud_dpjp_usaha" 
                        :rules="toValidate(mrValidationDNR.aud_dpjp_usaha)"
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div class="form-group">
                <label for="">Penandatanganan Dokumen</label>
                <div>
                  <b-form-radio-group
                    :options="Config.mr.ttdCreatedBy"
                    v-model="rowDNR['aud_ttd_by']"
                  />
                  <VValidate 
                      name="Penandatanganan Dokumen" 
                      v-model="rowDNR.aud_ttd_by" 
                      :rules="toValidate(mrValidationDNR.aud_ttd_by)"
                  />
                </div>
              </div>
              <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                <div class="d-flex">
                  <i class="icon-info22"></i><span class="ml-2">Simpan pengisian dokumen ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                </div>
              </div>

              <div id="opsiTtdDigital" class="opsiTtd" v-if="rowDNR['aud_ttd_by'] == 'Digital'">
                <a href="javascript:;" @click="openDoc(rowDNR.aud_form_dibuat_atas)" class="btn btn-primary" data-toggle="modal" data-target="#dnrPasienSigning">Tandatangani Dokumen</a>
              </div>
            </div>


            <!-- template -->
            <div class="d-none">
            <div v-if="rowDNR.aud_form_dibuat_atas == 'Pasien'" class="row">
              <div class="col-md-8">
                <div class="document-framer">
                  <div class="df-content">
                  <div
                    style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                    <div>
                      <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                          <tr>
                            <td style="padding: 4px 8px;" width="20%" rowspan="4">
                              <div style="text-align: center;">
                                <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="max-width: 100%;width: 56px;" alt="">
                                <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">Rumah Sakit Harapan</h2>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" width="40%"><span>No. RM: <strong>{{rowPasien.ap_code||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{rowReg.bu_full_name||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Nama Pasien: <strong>{{rowPasien.ap_fullname||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;"><span>NIK: <strong>{{rowPasien.ap_nik||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                            <strong v-if="rowPasien.ap_dob">{{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong>
                            <span v-else> - </span>
                            </span></td>
                            <td style="padding: 4px 8px;"><span>Jenis Kelamin: <strong>
                            {{rowPasien.ap_gender == 1 ? 'L' : rowPasien.ap_gender == 2 ? 'P' : 'TK'}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Masuk: <strong>{{rowReg.aur_reg_date | moment("DD MMMM YYYY, HH:mm")}}</strong></span></td>
                            <td style="padding: 4px 8px;"><span>Ruang: <strong>UGD</strong></span></td>
                          </tr>
                        <tr>
                          <td style="padding: 4px 8px;" colspan="3">
                            <div style="text-transform: uppercase;text-align: center;">
                              <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;">DNR (Do Not Resuscitate)</h3>
                              <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;margin-top: 4px;">Surat Pernyataan Jangan Dilakukan Resusitasi</h3>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 16px;line-height: 1.5;">
                        <tr>
                          <td style="padding: 4px 8px;" colspan="2">
                            <p style="margin: 0;margin-bottom: 4px;">Yang bertanda tangan dibawah ini saya:</p>
                            <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 8px;line-height: 1.5;">
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Nama</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{rowPasien.ap_fullname||"-"}}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Tanggal Lahir</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{rowPasien.ap_dob|moment("DD MMMM YYYY")}}</td>
                              </tr>
                            </table>
                            <p style="margin: 0;margin-bottom: 8px;">Dengan ini menyatakan bahwa saya membuat keputusan do not resuscitate (jangan diresusitasi).</p>
                            <p style="margin: 0;margin-bottom: 8px;">Saya menyatakan bahwa jika jantung saya berhenti berdetak atau jika saya berhenti bernafas, saya menghendaki tidak dilakukan prosedur medis untuk mengembalikan fungsi jantung dan pernafasan.</p>
                            <p style="margin: 0;margin-bottom: 8px;">Saya memahami bahwa keputusan ini tidak akan mencegah saya menerima pelayanan kesehatan lainnya seperti pemberian manuver Heimlich atau pemberian oksigen dan langkah-langkah perawatan untuk meningkatkan kenyamanan lainnya.</p>
                            <p style="margin: 0;">Saya memberikan izin agar informasi ini diberikan kepada seluruh staf rumah sakit, saya memahami bahwa saya dapat mencabut pernyataan ini setiap saat.</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;" width="50%">
                            <div style="text-align:center;">
                              <div style="margin-bottom: 4px;">Perawat</div>
                              <template v-if="rowDNR['aud_ttd_by'] == 'Digital'">
                                <img  :src="rowDNR.aud_ttd_perawat ? rowDNR.aud_ttd_perawat: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                              </template>
                              <template v-else>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                              </template>

                              <div style="margin-top: 4px;">(<span style="padding: 0 64px;">
                              {{rowReg.perawat||"-"}}
                              </span>)</div>
                            </div>
                          </td>
                          <td style="padding: 4px 8px;" width="50%">
                            <div style="text-align:center;">
                              <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                              <template v-if="rowDNR['aud_ttd_by'] == 'Digital'">
                                <img  :src="rowDNR.aud_ttd_pasien ? rowDNR.aud_ttd_pasien: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                              </template>
                              <template v-else>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                              </template>
                              <div style="margin-top:4px">(<span> {{rowPasien.ap_fullname}} </span>)</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;" colspan="2">
                            <div style="text-align:center;">
                              <div style="margin-bottom: 4px;">Saksi</div>
                              
                              <template v-if="rowDNR['aud_ttd_by'] == 'Digital'">
                                <img  :src="rowDNR.aud_ttd_saksi ? rowDNR.aud_ttd_saksi: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPerawat">
                              </template>
                              <template v-else>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                              </template>
                              
                              <div style="margin-top: 4px;">(<span style="padding: 0 64px;">
                              {{rowDNR.aud_ttd_saksi && rowDNR.aud_nama_saksi ? rowDNR.aud_nama_saksi : "-"}}
                              </span>)</div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="rowDNR.aud_form_dibuat_atas == 'DPJP'" class="row">
              <div class="col-md-8">
                <div class="document-framer">
                  <div class="df-content">
                    <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                      <div>
                        <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                          <tr>
                            <td style="padding: 4px 8px;" width="20%" rowspan="4">
                              <div style="text-align: center;">
                                <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="max-width: 100%;width: 56px;" alt="">
                                <p style="margin:0;margin-top: 8px;">
                                  RS Harapan<br />
                                  Jl. P. Senopati No. 11 Magelang
                                </p>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;text-align: center;" colspan="2">
                              <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;">Formulir Instruksi DPJP</h2>
                              <strong style="margin: 8px 0;">( Dokter Penanggung Jawab Pelayanan )</strong>
                              <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;">Kepada Dokter Jaga / Perawat untuk DNR</h2>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;" width="40%"><span>Nama Pasien: <strong>{{rowPasien.ap_fullname||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{rowReg.bu_full_name||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                            <strong v-if="rowPasien.ap_dob">{{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong>
                            <span v-else> - </span>
                            </span></td>
                            <td style="padding: 4px 8px;"><span>Ruang: <strong>UGD</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>No. RM: <strong>{{rowPasien.ap_code||"-"}}</strong></span></td>
                          </tr>
                        </table>
                        <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 16px;line-height: 1.5;">
                          <tr>
                            <td style="padding: 4px 8px;" colspan="2">
                              <p style="margin: 0;margin-bottom: 8px;">Formulir ini adalah perintah DPJP kepada dokter jaga/perawat agar tidak boleh melakukan resusitasi bila pasien dengan identitas dibawah ini mengalami henti jantung ( dimana tidak ada denyut nadi ) atau henti nafas ( tidak ada pernafasan spontan ).</p>
                              <p style="margin: 0;margin-bottom: 8px;">Formulir ini juga menginstruksikan kepada dokter jaga/perawat untuk tetap melakukan interval atau pengobatan atau tata laksana lainnya sebelum terjadi henti jantung atau henti nafas.</p>
                              <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 12px;line-height: 1.5;">
                                <tr>
                                  <th colspan="3" style="padding: 4px 8px;vertical-align:top;padding-left: 0;text-align: left;">Identitas Pasien</th>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowPasien.ap_fullname||"-"}}</td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">
                                  <strong v-if="rowPasien.ap_dob">{{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong>
                                  <span v-else> - </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">No. Rekam Medis</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowPasien.ap_code || "-"}}</td>
                                </tr>
                              </table>
                              <p style="margin: 0;margin-bottom: 4px;"><strong>Pernyataan dan Instruksi Dokter</strong></p>
                              <p style="margin: 0;margin-bottom: 4px;">Saya dokter yang bertanda tangan dibawah ini menginstruksikan tenaga medis emergecy untuk melakukan hal yang tertulis dibawah ini.</p>
                              <table width="100%" style="font-size: 16px;line-height: 1.5;margin-bottom: 12px;">
                                <tr v-if="rowDNR.aud_dpjp_usaha == 'komprehensif'">
                                  <td style="vertical-align: top;">
                                    <p style="margin: 0;">Usaha komprehensif untuk mencegah henti jantung atau henti nafas, TANPA melakukan intubasi, DNR jika henti jantung atau henti nafas terjadi, TIDAK melakukan CPR</p>
                                  </td>
                                </tr>
                                <tr v-else-if="rowDNR.aud_dpjp_usaha == 'suportif'">
                                  <td style="vertical-align: top;">
                                    <p style="margin: 0;">Usaha suportif sebelum terjadi henti jantung atau henti nafas yang meliputi pembukaan jalan nafas secara non invasif, pemberian oksigen, mengontrol pendarahahan, memposisikan pasien dengan <span style="text-decoration:underline;">{{rowDNR.aud_form_dibuat_atas_lainnya}}</span> obat-obatan anti alergi, TIDAK melakukan CPR bila henti jantung atau henti nafas terjadi.</p>
                                  </td>
                                </tr>
                              </table>
                              <p style="margin: 0;margin-bottom: 8px;">Saya dokter yang bertanda tangan di bawah ini menyatakan bahwa keputusan DNR di atas diambil setelah pasien/keluarga/wali yang sah diberi penjelasan dan menandatangani informed consent<br/>( Penolakan resusutasi / DNR )</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;" width="50%">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">&nbsp;</div>
                                <div style="margin-bottom: 4px;">Dokter</div>
                                
                                <template v-if="rowDNR['aud_ttd_by'] == 'Digital'">
                                  <img  :src="rowDNR.aud_ttd_dokter ? rowDNR.aud_ttd_dokter: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                </template>
                                <template v-else>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                </template>
                                
                                <div style="margin-top:4px;">(<span style="padding: 0 64px;"></span>)</div>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" width="50%">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                                <div style="margin-bottom: 4px;">DPJP</div>
                                <template v-if="rowDNR['aud_ttd_by'] == 'Digital'">
                                  <img  :src="rowDNR.aud_ttd_dpjp ? rowDNR.aud_ttd_dpjp: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                </template>
                                <template v-else>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                </template>
                                <div style="margin-top:4px">(<span> {{rowDNR.aud_nama_dpjp||"-"}} </span>)</div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="row">
              <div class="col-md-8">
                <div class="document-framer">
                  <div class="df-content">
                    <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                      <div>
                        <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                            <tr>
                              <td style="padding: 4px 8px;" width="20%" rowspan="4">
                                <div style="text-align: center;">
                                  <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="max-width: 100%;width: 56px;" alt="">
                                  <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">Rumah Sakit Harapan</h2>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;" width="40%"><span>No. RM: <strong>{{rowPasien.ap_code||"-"}}</strong></span></td>
                              <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{rowReg.bu_full_name||"-"}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Nama Pasien: <strong>{{rowPasien.ap_fullname||"-"}}</strong></span></td>
                              <td style="padding: 4px 8px;"><span>NIK: <strong>{{rowPasien.ap_nik||"-"}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                              <strong v-if="rowPasien.ap_dob">{{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong>
                              <span v-else> - </span>
                              </span></td>
                              <td style="padding: 4px 8px;"><span>Jenis Kelamin: 
                              <strong>
                              {{rowPasien.ap_gender == 1 ? 'L' : rowPasien.ap_gender == 2 ? 'P' : 'TK'}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Tgl. Masuk: <strong>{{rowReg.aur_reg_date | moment("DD MMMM YYYY, HH:mm")}}</strong></span></td>
                              <td style="padding: 4px 8px;"><span>Ruang: <strong>UGD</strong></span></td>
                            </tr>
                          <tr>
                            <td style="padding: 4px 8px;" colspan="3">
                              <div style="text-transform: uppercase;text-align: center;">
                                <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;">DNR (Do Not Resuscitate)</h3>
                                <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;margin-top: 4px;">Surat Pernyataan Jangan Dilakukan Resusitasi</h3>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 16px;line-height: 1.5;">
                          <tr>
                            <td style="padding: 4px 8px;" colspan="2">
                              <p style="margin: 0;margin-bottom: 4px;">Yang bertanda tangan dibawah ini:</p>
                              <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 8px;line-height: 1.5;">
                                
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowDNR.aud_kel_nama||"-"}}</td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">
                                  <strong v-if="rowDNR.aud_kel_dob">{{ rowDNR.aud_kel_dob | moment("DD MMMM YYYY")}}</strong>
                                  <span v-else> - </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Umur/Jenis Kelamin</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%" v-if="rowDNR.aud_kel_jenis_kelamin">
                                  {{rowDNR.aud_kel_dob | moment("from", "now", true)}}, {{getConfigDynamic(Config.mr.StatusKelamin,rowDNR.aud_kel_jenis_kelamin)||"-"}}</td>
                                  
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%" v-else>
                                    -
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Alamat</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowDNR.aud_kel_address||"-"}}</td>
                                </tr>
                                
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Untuk</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowDNR.aud_kel_untuk||"-"}}</td>
                                  
                                </tr>
                              </table>
                              <p style="margin: 0;margin-bottom: 8px;">Dengan ini menyatakan bahwa saya membuat keputusan dan menyetujui do not resuscitate (jangan diresusitasi).</p>
                              <p style="margin: 0;margin-bottom: 8px;">Saya menyatakan bahwa jika jantung pasien berhenti berdetak atau jika pasien berhenti bernafas, saya menghendaki tidak dilakukan prosedur medis untuk mengembalikan fungsi jantung dan pernafasan.</p>
                              <p style="margin: 0;margin-bottom: 8px;">Saya memahami bahwa keputusan ini tidak akan mencegah pasien menerima pelayanan kesehatan lainnya seperti pemberian manuver Heimlich atau pemberian oksigen dan langkah-langkah perawatan untuk meningkatkan kenyamanan lainnya.</p>
                              <p style="margin: 0;">Saya memberikan izin agar informasi ini diberikan kepada seluruh staf rumah sakit, saya memahami bahwa saya dapat mencabut pernyataan ini setiap saat.</p>
                            </td>
                          </tr>
                         
                          <tr>
                            <td style="padding: 4px 8px;" width="50%">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                                <template v-if="rowDNR['aud_ttd_by'] == 'Digital'">
                                  <img  :src="rowDNR.aud_ttd_keluarga ? rowDNR.aud_ttd_keluarga: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                </template>
                                <template v-else>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                </template>
                                <div style="margin-top:4px">(<span> Keluarga </span>)</div>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" colspan="2">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">Saksi</div>

                                <template v-if="rowDNR['aud_ttd_by'] == 'Digital'">
                                  <img  :src="rowDNR.aud_ttd_saksi ? rowDNR.aud_ttd_saksi: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                </template>
                                <template v-else>
                                  <br/>
                                  <br/>
                                  <br/>
                                  <br/>
                                </template>

                                <div style="margin-top: 4px;">(<span style="padding: 0 64px;">
                                {{rowDNR.aud_ttd_saksi && rowDNR.aud_nama_saksi ? rowDNR.aud_nama_saksi : "-"}}</span>)</div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <!-- template -->
            
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
            </b-form>
        </validation-observer>

          <b-modal v-model="openTTD" scrollable :title="'Pernyataan DNR'" size="xl" @ok.prevent="simpanDNR()" ok-title="Simpan">
            <div v-if="rowDNR.aud_form_dibuat_atas == 'Pasien'" class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanda Tangan Pasien</label>
                  <div class="signing-element" data-target-id="ttdPasien">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPasien"
                        @onend="onEnd('ttdPasien')"
                    />
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdPasien','aud_ttd_pasien')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button type="button" @click="output('ttdPasien','aud_ttd_pasien')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Tanda Tangan Saksi</label>
                  <div class="mb-2">
                    <b-form-input type="text" class="form-control" v-model="rowDNR.aud_nama_saksi" placeholder="Nama Saksi"/>
                    <VValidate 
                      name="Nama Saksi" 
                      v-model="rowDNR.aud_nama_saksi" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>
                  
                  <div class="signing-element" data-target-id="ttdSaksi">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdSaksi"
                        @onend="onEnd('ttdSaksi')"
                    />
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdSaksi','aud_ttd_saksi')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button type="button" @click="output('ttdSaksi','aud_ttd_saksi')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Tanda Tangan Perawat</label>
                  <div class="signing-element" data-target-id="ttdPerawat">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPerawat"
                        @onend="onEnd('ttdPerawat')"
                    />
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdPerawat','aud_ttd_perawat')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button type="button" @click="output('ttdPerawat','aud_ttd_perawat')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="document-framer">
                  <div class="df-content">
                  <div
                    style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                    <div>
                      <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                          <tr>
                            <td style="padding: 4px 8px;" width="20%" rowspan="4">
                              <div style="text-align: center;">
                                <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="max-width: 100%;width: 56px;" alt="">
                                <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">Rumah Sakit Harapan</h2>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" width="40%"><span>No. RM: <strong>{{rowPasien.ap_code||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{rowReg.bu_full_name||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Nama Pasien: <strong>{{rowPasien.ap_fullname||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;"><span>NIK: <strong>{{rowPasien.ap_nik||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                            <strong v-if="rowPasien.ap_dob">{{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong>
                            <span v-else> - </span>
                            </span></td>
                            <td style="padding: 4px 8px;"><span>Jenis Kelamin: <strong>
                            {{rowPasien.ap_gender == 1 ? 'L' : rowPasien.ap_gender == 2 ? 'P' : 'TK'}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Masuk: <strong>{{rowReg.aur_reg_date | moment("DD MMMM YYYY, HH:mm")}}</strong></span></td>
                            <td style="padding: 4px 8px;"><span>Ruang: <strong>UGD</strong></span></td>
                          </tr>
                        <tr>
                          <td style="padding: 4px 8px;" colspan="3">
                            <div style="text-transform: uppercase;text-align: center;">
                              <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;">DNR (Do Not Resuscitate)</h3>
                              <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;margin-top: 4px;">Surat Pernyataan Jangan Dilakukan Resusitasi</h3>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 16px;line-height: 1.5;">
                        <tr>
                          <td style="padding: 4px 8px;" colspan="2">
                            <p style="margin: 0;margin-bottom: 4px;">Yang bertanda tangan dibawah ini saya:</p>
                            <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 8px;line-height: 1.5;">
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Nama</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{rowPasien.ap_fullname||"-"}}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Tanggal Lahir</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{rowPasien.ap_dob|moment("DD MMMM YYYY")}}</td>
                              </tr>
                            </table>
                            <p style="margin: 0;margin-bottom: 8px;">Dengan ini menyatakan bahwa saya membuat keputusan do not resuscitate (jangan diresusitasi).</p>
                            <p style="margin: 0;margin-bottom: 8px;">Saya menyatakan bahwa jika jantung saya berhenti berdetak atau jika saya berhenti bernafas, saya menghendaki tidak dilakukan prosedur medis untuk mengembalikan fungsi jantung dan pernafasan.</p>
                            <p style="margin: 0;margin-bottom: 8px;">Saya memahami bahwa keputusan ini tidak akan mencegah saya menerima pelayanan kesehatan lainnya seperti pemberian manuver Heimlich atau pemberian oksigen dan langkah-langkah perawatan untuk meningkatkan kenyamanan lainnya.</p>
                            <p style="margin: 0;">Saya memberikan izin agar informasi ini diberikan kepada seluruh staf rumah sakit, saya memahami bahwa saya dapat mencabut pernyataan ini setiap saat.</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;" width="50%">
                            <div style="text-align:center;">
                              <div style="margin-bottom: 4px;">Perawat</div>
                              <img :src="rowDNR.aud_ttd_perawat ? rowDNR.aud_ttd_perawat: 'https://placehold.co/400x160'" alt="signature'" width="200" id="ttdSaksi">
                              <div style="margin-top: 4px;">(<span style="padding: 0 64px;">
                              {{rowReg.perawat||"-"}}
                              </span>)</div>
                            </div>
                          </td>
                          <td style="padding: 4px 8px;" width="50%">
                            <div style="text-align:center;">
                              <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                              <img  :src="rowDNR.aud_ttd_pasien ? rowDNR.aud_ttd_pasien: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                              <div style="margin-top:4px">(<span> {{rowPasien.ap_fullname}} </span>)</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;" colspan="2">
                            <div style="text-align:center;">
                              <div style="margin-bottom: 4px;">Saksi</div>
                              <img  :src="rowDNR.aud_ttd_saksi ? rowDNR.aud_ttd_saksi: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPerawat">
                              <div style="margin-top: 4px;">(<span style="padding: 0 64px;">
                              {{rowDNR.aud_ttd_saksi && rowDNR.aud_nama_saksi ? rowDNR.aud_nama_saksi : "-"}}
                              </span>)</div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="rowDNR.aud_form_dibuat_atas == 'DPJP'" class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanda Tangan Dokter</label>
                  <div class="signing-element" data-target-id="ttdDokter">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdDokter"
                        @onend="onEnd('ttdDokter')"
                    />
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDokter','aud_ttd_dokter')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button type="button" @click="output('ttdDokter','aud_ttd_dokter')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Tanda Tangan DPJP</label>
                  <div class="signing-element" data-target-id="ttdDpjp">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdDpjp"
                        @onend="onEnd('ttdDpjp')"
                    />
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDpjp','aud_ttd_dpjp')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button type="button" @click="output('ttdDpjp','aud_ttd_dpjp')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                
                <div class="form-group">
                  <label for="">Nama DPJP<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-input type="text" class="form-control" v-model="rowDNR.aud_nama_dpjp" placeholder="Nama DPJP"/>
                    <VValidate 
                      name="Nama DPJP" 
                      v-model="rowDNR.aud_nama_dpjp" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>
                </div>

              </div>
              <div class="col-md-8">
                <div class="document-framer">
                  <div class="df-content">
                    <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                      <div>
                        <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                          <tr>
                            <td style="padding: 4px 8px;" width="20%" rowspan="4">
                              <div style="text-align: center;">
                                <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="max-width: 100%;width: 56px;" alt="">
                                <p style="margin:0;margin-top: 8px;">
                                  RS Harapan<br />
                                  Jl. P. Senopati No. 11 Magelang
                                </p>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;text-align: center;" colspan="2">
                              <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;">Formulir Instruksi DPJP</h2>
                              <strong style="margin: 8px 0;">( Dokter Penanggung Jawab Pelayanan )</strong>
                              <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;">Kepada Dokter Jaga / Perawat untuk DNR</h2>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;" width="40%"><span>Nama Pasien: <strong>{{rowPasien.ap_fullname||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{rowReg.bu_full_name||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                            <strong v-if="rowPasien.ap_dob">{{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong>
                            <span v-else> - </span>
                            </span></td>
                            <td style="padding: 4px 8px;"><span>Ruang: <strong>UGD</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>No. RM: <strong>{{rowPasien.ap_code||"-"}}</strong></span></td>
                          </tr>
                        </table>
                        <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 16px;line-height: 1.5;">
                          <tr>
                            <td style="padding: 4px 8px;" colspan="2">
                              <p style="margin: 0;margin-bottom: 8px;">Formulir ini adalah perintah DPJP kepada dokter jaga/perawat agar tidak boleh melakukan resusitasi bila pasien dengan identitas dibawah ini mengalami henti jantung ( dimana tidak ada denyut nadi ) atau henti nafas ( tidak ada pernafasan spontan ).</p>
                              <p style="margin: 0;margin-bottom: 8px;">Formulir ini juga menginstruksikan kepada dokter jaga/perawat untuk tetap melakukan interval atau pengobatan atau tata laksana lainnya sebelum terjadi henti jantung atau henti nafas.</p>
                              <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 12px;line-height: 1.5;">
                                <tr>
                                  <th colspan="3" style="padding: 4px 8px;vertical-align:top;padding-left: 0;text-align: left;">Identitas Pasien</th>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowPasien.ap_fullname||"-"}}</td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">
                                  <strong v-if="rowPasien.ap_dob">{{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong>
                                  <span v-else> - </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">No. Rekam Medis</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowPasien.ap_code || "-"}}</td>
                                </tr>
                              </table>
                              <p style="margin: 0;margin-bottom: 4px;"><strong>Pernyataan dan Instruksi Dokter</strong></p>
                              <p style="margin: 0;margin-bottom: 4px;">Saya dokter yang bertanda tangan dibawah ini menginstruksikan tenaga medis emergecy untuk melakukan hal yang tertulis dibawah ini.</p>
                              <table width="100%" style="font-size: 16px;line-height: 1.5;margin-bottom: 12px;">
                                <tr v-if="rowDNR.aud_dpjp_usaha == 'komprehensif'">
                                  <td style="vertical-align: top;">
                                    <p style="margin: 0;">Usaha komprehensif untuk mencegah henti jantung atau henti nafas, TANPA melakukan intubasi, DNR jika henti jantung atau henti nafas terjadi, TIDAK melakukan CPR</p>
                                  </td>
                                </tr>
                                <tr v-else-if="rowDNR.aud_dpjp_usaha == 'suportif'">
                                  <td style="vertical-align: top;">
                                    <p style="margin: 0;">Usaha suportif sebelum terjadi henti jantung atau henti nafas yang meliputi pembukaan jalan nafas secara non invasif, pemberian oksigen, mengontrol pendarahahan, memposisikan pasien dengan <span style="text-decoration:underline;">{{rowDNR.aud_form_dibuat_atas_lainnya}}</span> obat-obatan anti alergi, TIDAK melakukan CPR bila henti jantung atau henti nafas terjadi.</p>
                                  </td>
                                </tr>
                              </table>
                              <p style="margin: 0;margin-bottom: 8px;">Saya dokter yang bertanda tangan di bawah ini menyatakan bahwa keputusan DNR di atas diambil setelah pasien/keluarga/wali yang sah diberi penjelasan dan menandatangani informed consent<br/>( Penolakan resusutasi / DNR )</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;" width="50%">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">&nbsp;</div>
                                <div style="margin-bottom: 4px;">Dokter</div>
                                <img :src="rowDNR.aud_ttd_dokter ? rowDNR.aud_ttd_dokter: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                <div style="margin-top:4px;">(<span style="padding: 0 64px;"></span>)</div>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" width="50%">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                                <div style="margin-bottom: 4px;">DPJP</div>
                                <img :src="rowDNR.aud_ttd_dpjp ? rowDNR.aud_ttd_dpjp: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                <div style="margin-top:4px">(<span> {{rowDNR.aud_nama_dpjp||"-"}} </span>)</div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanda Tangan Keluarga</label> 
                  <div class="signing-element" data-target-id="ttdUtamaKeluarga">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdUtamaKeluarga"
                        @onend="onEnd('ttdUtamaKeluarga')"
                    />
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdUtamaKeluarga','aud_ttd_keluarga')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button type="button" @click="output('ttdUtamaKeluarga','aud_ttd_keluarga')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
               
                <div class="form-group">
                  <label for="">Tanda Tangan Saksi</label>
                  <div class="mb-2">
                    <b-form-input type="text" class="form-control" v-model="rowDNR.aud_nama_saksi" placeholder="Nama Saksi"/>
                    <VValidate 
                      name="Nama Saksi" 
                      v-model="rowDNR.aud_nama_saksi" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>

                  <div class="signing-element" data-target-id="ttdSaksi">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdSaksi"
                        @onend="onEnd('ttdSaksi')"
                    />
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdSaksi','aud_ttd_saksi')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button type="button" @click="output('ttdSaksi','aud_ttd_saksi')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Nama Keluarga<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-input type="text" class="form-control" v-model="rowDNR.aud_kel_nama" placeholder="Nama Keluarga"/>
                    <VValidate 
                      name="Nama Keluarga" 
                      v-model="rowDNR.aud_kel_nama" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>
                </div>
                
                <div class="form-group">
                  <label for="">Tanggal Lahir<strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <div class="input-group mb-3">
                      <datepicker 
                        input-class="form-control transparent"
                        placeholder="Tanggal Lahir" class="my-datepicker"
                        calendar-class="my-datepicker_calendar"
                        v-model="rowDNR.aud_kel_dob"
                        >
                      </datepicker>
                      <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i
                            class="icon-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                  <VValidate 
                    name="Tanggal Lahir" 
                    v-model="rowDNR.aud_kel_dob" 
                    :rules="{required: 1}"
                  />
                </div>

                <div class="form-group">
                  <label for="">Jenis Kelamin<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-radio-group
                      :options="Config.mr.StatusKelamin"
                      v-model="rowDNR['aud_kel_jenis_kelamin']"
                    />
                    <VValidate 
                      name="Jenis Kelamin" 
                      v-model="rowDNR.aud_kel_jenis_kelamin" 
                      :rules="{required: 1}"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Alamat<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-textarea type="text" class="form-control" v-model="rowDNR.aud_kel_address" placeholder="Alamat"/>
                    <VValidate 
                      name="Alamat" 
                      v-model="rowDNR.aud_kel_address" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Untuk<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-input type="text" class="form-control" v-model="rowDNR.aud_kel_untuk" placeholder="Untuk"/>
                    <VValidate 
                      name="Untuk" 
                      v-model="rowDNR.aud_kel_untuk" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>
                </div>


                

              </div>
              
              <div class="col-md-8">
                <div class="document-framer">
                  <div class="df-content">
                    <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                      <div>
                        <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                            <tr>
                              <td style="padding: 4px 8px;" width="20%" rowspan="4">
                                <div style="text-align: center;">
                                  <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="max-width: 100%;width: 56px;" alt="">
                                  <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">Rumah Sakit Harapan</h2>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;" width="40%"><span>No. RM: <strong>{{rowPasien.ap_code||"-"}}</strong></span></td>
                              <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{rowReg.bu_full_name||"-"}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Nama Pasien: <strong>{{rowPasien.ap_fullname||"-"}}</strong></span></td>
                              <td style="padding: 4px 8px;"><span>NIK: <strong>{{rowPasien.ap_nik||"-"}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                              <strong v-if="rowPasien.ap_dob">{{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong>
                              <span v-else> - </span>
                              </span></td>
                              <td style="padding: 4px 8px;"><span>Jenis Kelamin: 
                              <strong>
                              {{rowPasien.ap_gender == 1 ? 'L' : rowPasien.ap_gender == 2 ? 'P' : 'TK'}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Tgl. Masuk: <strong>{{rowReg.aur_reg_date | moment("DD MMMM YYYY, HH:mm")}}</strong></span></td>
                              <td style="padding: 4px 8px;"><span>Ruang: <strong>UGD</strong></span></td>
                            </tr>
                          <tr>
                            <td style="padding: 4px 8px;" colspan="3">
                              <div style="text-transform: uppercase;text-align: center;">
                                <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;">DNR (Do Not Resuscitate)</h3>
                                <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;margin-top: 4px;">Surat Pernyataan Jangan Dilakukan Resusitasi</h3>
                              </div>
                            </td>
                          </tr>
                        </table>

                        
                        <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 16px;line-height: 1.5;">
                          <tr>
                            <td style="padding: 4px 8px;" colspan="2">
                              <p style="margin: 0;margin-bottom: 4px;">Yang bertanda tangan dibawah ini:</p>
                              <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 8px;line-height: 1.5;">
                                
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowDNR.aud_kel_nama||"-"}}</td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">
                                  <strong v-if="rowDNR.aud_kel_dob">{{ rowDNR.aud_kel_dob | moment("DD MMMM YYYY")}}</strong>
                                  <span v-else> - </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Umur/Jenis Kelamin</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%" v-if="rowDNR.aud_kel_jenis_kelamin">
                                  {{rowDNR.aud_kel_dob | moment("from", "now", true)}}, {{getConfigDynamic(Config.mr.StatusKelamin,rowDNR.aud_kel_jenis_kelamin)||"-"}}</td>
                                  
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%" v-else>
                                    -
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Alamat</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowDNR.aud_kel_address||"-"}}</td>
                                </tr>
                                
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Untuk</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{rowDNR.aud_kel_untuk||"-"}}</td>
                                  
                                </tr>
                              </table>

                              <p style="margin: 0;margin-top: 4px;margin-bottom: 4px;">Dengan Pasien Bernama : </p>
                              <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 8px;line-height: 1.5;">
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Nama</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{rowPasien.ap_fullname||"-"}}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Tanggal Lahir</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{rowPasien.ap_dob|moment("DD MMMM YYYY")}}</td>
                              </tr>
                              </table>

                              <p style="margin: 0;margin-bottom: 8px;">Dengan ini menyatakan bahwa saya membuat keputusan dan menyetujui do not resuscitate (jangan diresusitasi).</p>
                              <p style="margin: 0;margin-bottom: 8px;">Saya menyatakan bahwa jika jantung pasien berhenti berdetak atau jika pasien berhenti bernafas, saya menghendaki tidak dilakukan prosedur medis untuk mengembalikan fungsi jantung dan pernafasan.</p>
                              <p style="margin: 0;margin-bottom: 8px;">Saya memahami bahwa keputusan ini tidak akan mencegah pasien menerima pelayanan kesehatan lainnya seperti pemberian manuver Heimlich atau pemberian oksigen dan langkah-langkah perawatan untuk meningkatkan kenyamanan lainnya.</p>
                              <p style="margin: 0;">Saya memberikan izin agar informasi ini diberikan kepada seluruh staf rumah sakit, saya memahami bahwa saya dapat mencabut pernyataan ini setiap saat.</p>
                            </td>
                          </tr>
                         
                          <tr>
                            <td style="padding: 4px 8px;" width="50%">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                                <img  :src="rowDNR.aud_ttd_keluarga ? rowDNR.aud_ttd_keluarga: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                <div style="margin-top:4px">(<span> Keluarga </span>)</div>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" colspan="2">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">Saksi</div>
                                <img  :src="rowDNR.aud_ttd_saksi ? rowDNR.aud_ttd_saksi: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPerawat">
                                <div style="margin-top: 4px;">(<span style="padding: 0 64px;">
                                {{rowDNR.aud_ttd_saksi && rowDNR.aud_nama_saksi ? rowDNR.aud_nama_saksi : "-"}}</span>)</div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>

        </div>
        <!-- /content area -->
        <!-- Footer -->
        <!-- /footer -->
    </div>
      
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'

export default{
    extends: GlobalVue,    
    components: {
      Datepicker
    },
    computed: {
        isParent(){
            return this.$parent
        },
        now(){
            return moment().format('DD MMM YYYY')
        },
    },
    data(){
        return {
            rowDNR: {},
            rowPasien: {},
            rowReg: {},
            mrValidationDNR: {},
            openTTD: false,
            options: {
              penColor: "#000",
            },
        }
    },
    methods: {
        getConfigDynamic(master, value) {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index]['text']
                }
            }
            return text
        },
        simpanDNR(){
          let inval = 0
          if(this.rowDNR.aud_form_dibuat_atas == 'Pasien'){
            if(this.rowDNR.aud_ttd_pasien == null || this.rowDNR.aud_ttd_perawat == null || this.rowDNR.aud_ttd_saksi == null){
              inval = inval + 1
            }
          }
          else if(this.rowDNR.aud_form_dibuat_atas == 'DPJP'){
            if(this.rowDNR.aud_ttd_dpjp == null || this.rowDNR.aud_ttd_dokter == null){
              inval = inval + 1
            }
          }
          else if(this.rowDNR.aud_form_dibuat_atas == 'Keluarga'){
            if(this.rowDNR.aud_ttd_keluarga == null || this.rowDNR.aud_ttd_saksi == null){
              inval = inval + 1

            }
          }


          if(inval){
            return this.$swal({
              icon: 'error',
              title: 'Tanda tangan tidak lengkap',
            })
          }

          this.openTTD = false
        },  
        onBegin() {
          console.log('=== Begin ===')
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowDNR[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowDNR[field] = data
          }
        },
        openDoc(temp){
          if(this.rowDNR.aud_form_dibuat_atas == 'DPJP' && !this.rowDNR.aud_dpjp_usaha){
            return this.$swal({
              icon: 'error',
              title: 'Mohon isi data Pernyataan dan Instruksi Dokter terlebih dahulu'
            })
          }

          this.openTTD = true
          setTimeout(()=>{
            $(".sign-container canvas").attr("width",400)
            $(".sign-container canvas").attr("height",160)                
            this.$nextTick(() => {
              if(this.rowDNR.aud_ttd_keluarga){
                if(this.$refs['ttdUtamaKeluarga']){
                  this.$refs['ttdUtamaKeluarga'].fromDataURL(this.rowDNR.aud_ttd_keluarga)
                }
              }
              if(this.rowDNR.aud_ttd_saksi){
                if(this.$refs['ttdSaksi']){
                  this.$refs['ttdSaksi'].fromDataURL(this.rowDNR.aud_ttd_saksi)
                }
              }
              if(this.rowDNR.aud_ttd_pasien){
                if(this.$refs['ttdPasien']){
                  this.$refs['ttdPasien'].fromDataURL(this.rowDNR.aud_ttd_pasien)
                }
              }
              if(this.rowDNR.aud_ttd_perawat){
                if(this.$refs['ttdPerawat']){                
                 this.$refs['ttdPerawat'].fromDataURL(this.rowDNR.aud_ttd_perawat)
                }                
              }
              if(this.rowDNR.aud_ttd_dpjp){
                if(this.$refs['ttdDpjp']){                
                 this.$refs['ttdDpjp'].fromDataURL(this.rowDNR.aud_ttd_dpjp)
                }                
              }
              if(this.rowDNR.aud_ttd_dokter){
                if(this.$refs['ttdDokter']){                
                 this.$refs['ttdDokter'].fromDataURL(this.rowDNR.aud_ttd_dokter)
                }                
              }
            })
          },200)

        },
        apiGetDNR(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-dnr',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })

        },
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
          
        autoSave: _.debounce(function (data) {
          data.type = 'auto-save-data-dnr'
          Gen.apiRest(
            "/do/" + 'UGDTindakLanjut', {
              data: data
            },
            "POST"
          )
        }, 1000),

        doSubmit(){
            this.$refs['VForm'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                this.rowDNR.aud_template_doc = $(".document-framer").html()
                      
                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowDNR

                            data.type = 'submit-data-dnr'
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
    },  
    mounted() {
        this.apiGetDNR()
    },
    watch: {
      rowDNR: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      }
    }
}      
</script>
