<template>
    <div>
        <validation-observer ref="VFormUpload">
            <b-form @submit.prevent="doSubmit">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <div>
                            <Uploader v-model="rowReg.aur_pemeriksaan_egd_file" isDocument type="doc_only" />
                            <VValidate 
                                name="Dokumen" 
                                v-model="rowReg.aur_pemeriksaan_egd_file" 
                                :rules="{required : 1}"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="text-right">
                    <button type="button" class="btn" @click="isParent.signingModal = false" data-dismiss="modal">Batal</button>
                    <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
                    </button>
                    </div>
                </div>
            </div>
            </b-form>
        </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowPasien: {},
            rowData: {},
            rowReg: {},
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        doSubmit(){
            this.$refs['VFormUpload'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowReg
                            data.type = 'upload-data-ttd-ekg'
                            data.status = 'Y'
                            data.aur_id = this.$route.query.regId ? this.$route.query.regId : this.$route.params.pageSlug
                            data.file = this.rowReg.aur_pemeriksaan_egd_file
                            
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.signingModal = false
                                    this.isParent.$parent.apiGet()
                                    
                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
    }
}
</script>

