<template>
    <div class="content">
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <div class="d-flex align-items-center">
              <h6 class="card-title font-weight-semibold">Surat Keterangan Sehat Dokter</h6>
            </div>
          </div>
          
          <validation-observer ref="VForm">
            <b-form @submit.prevent="doSubmit" class="card">
            <div class="form-underlying card-body p-3">
              <div class="row">
                <div class="col-md-7">
                  <div>
                    <label for="">Keperluan<strong class="text-danger">*</strong></label>
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th width="50">#</th>
                          <th>Keperluan</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (rowSKD.aukskd_keperluan||[])" :key="k">
                          <td>{{k+1}}</td>
                          <td>
                            <b-form-input v-model="rowSKD.aukskd_keperluan[k]" type="text" placeholder="cth. pendaftaran sekolah" class="form-control form-control-sm"/>
                            <VValidate 
                                :name="'Keperluan '+(k+1)" 
                                v-model="rowSKD.aukskd_keperluan[k]" 
                                :rules="{required: 1,min:2,max:128}"
                            />
                          </td>
                          <td>
                            <a href="javascript:;" class="list-icons-item"
                            @click="rowSKD.aukskd_keperluan.splice(k,1)"
                            data-toggle="tooltip" data-placement="top" title="Delete"><i
                                class="icon-bin"></i></a>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td class="text-center" colspan="3">
                            <a href="javascript:;" @click="rowSKD.aukskd_keperluan.push('')" class="btn btn-sm alpha-blue border-blue">
                                <i class="icon-plus2 mr-2"></i>
                                <span>Tambah</span>
                            </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
            </b-form>
           </validation-observer>
        </div>    
    </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

const moment = require('moment')
export default{
    extends: GlobalVue,    
    computed: {
        isParent(){
            return this.$parent
        },
        now(){
            return moment().format('DD MMM YYYY')
        },
    },
    data(){
        return {
            rowSKD: {},
            mrValidationSKD: {},
        }
    },
    methods: {
        apiGetSKD(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-skd',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })

        },
        toValidate(val){
            return {...val}
        },
          
        autoSave: _.debounce(function (data) {
          data.type = 'auto-save-data-skd'
          Gen.apiRest(
            "/do/" + 'UGDTindakLanjut', {
              data: data
            },
            "POST"
          )
        }, 1000),

        doSubmit(){
            this.$refs['VForm'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }
                        
                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowSKD
                            data.type = 'submit-data-skd'
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
    },  
    mounted() {
        this.apiGetSKD()
    },
    watch: {
      rowSKD: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
    }
}      
</script>
