<template>
    <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit">
            <div class="row mt-2">
                <div class="col-md-12 checkbox-block">
                    <b-form-group>
                        <label>Pilih Kriteria Masuk <span class="text-danger mr5">*</span></label>
                        <b-form-radio-group
                            :options="Config.mr.typeICU"
                            v-model="rowKriteriaICU.aukic_type"
                        />
                    </b-form-group>
                    <VValidate 
                        name="Kriteria Masuk" 
                        v-model="rowKriteriaICU.aukic_type" 
                        :rules="toValidate(mrValidationKriteriaICU.aukic_type)"
                    />

                    <template v-if="rowKriteriaICU.aukic_type == 'ICU'">
                        <h6>PARAMETER MASUK ICU</h6>
                        <b-form-group class="mt-3">
                            <label>Tanda Vital</label>
                            <b-form-checkbox-group
                                id="aukic_ttv"
                                v-model="rowKriteriaICU.aukic_ttv"
                                :options="Config.mr.icuTTV"
                                name="aukic_ttv"
                            ></b-form-checkbox-group>
                        </b-form-group>
                        
                        <b-form-group class="mt-3">
                            <label>Nilai Laboratorium</label>
                            <b-form-checkbox-group
                                id="aukic_nilai_lab"
                                v-model="rowKriteriaICU.aukic_nilai_lab"
                                :options="Config.mr.icuNilaiLab"
                                name="aukic_nilai_lab"
                            ></b-form-checkbox-group>
                        </b-form-group>
                        
                        <b-form-group class="mt-3">
                            <label>Radiografi / Ultrasonografi</label>
                            <b-form-checkbox-group
                                id="aukic_radiografi"
                                v-model="rowKriteriaICU.aukic_radiografi"
                                :options="Config.mr.icuNilaiRadiologi"
                                name="aukic_radiografi"
                            ></b-form-checkbox-group>
                        </b-form-group>
                        
                        <b-form-group class="mt-3">
                            <label>Pemeriksaan Fisisk (Onset Akut )</label>
                            <b-form-checkbox-group
                                id="aukic_pemeriksaan_fisik"
                                v-model="rowKriteriaICU.aukic_pemeriksaan_fisik"
                                :options="Config.mr.icuPemeriksaanFisik"
                                name="aukic_pemeriksaan_fisik"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </template>

                    <template v-if="rowKriteriaICU.aukic_type == 'PCNC'">
                        <h6>PARAMETER MASUK PICU / NICU</h6>
                        <b-form-group class="mt-3">
                            <label>Tanda Vital</label>
                            <b-form-checkbox-group
                                id="aukic_nc_ttv"
                                v-model="rowKriteriaICU.aukic_nc_ttv"
                                :options="Config.mr.nicuNilaiTTV"
                                name="aukic_nc_ttv"
                            ></b-form-checkbox-group>
                        </b-form-group>

                        <b-form-group class="mt-3">
                            <label>Pemeriksaan Radiografi</label>
                            <b-form-checkbox-group
                                id="aukic_nc_radiografi"
                                v-model="rowKriteriaICU.aukic_nc_radiografi"
                                :options="Config.mr.nicuNilaiRadiografi"
                                name="aukic_nc_radiografi"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </template>

                    
                    
                    <!--
                    <div>
                        <div class="custom-control custom-radio">
                            <b-form-radio
                                v-model="rowKriteriaICU.aukic_kriteria"
                                value="1" :name="'kriteriaICU'"
                                class="form-check-input-styled">

                                <div>
                                    <strong class="text-uppercase">Prioritas 1</strong>
                                    <div class="criteria-content">
                                    <p>Penyakit atau gangguan akut pada organ vital yang membutuhkan terapi intensif dan agresif, seperti:</p>
                                    <ul>
                                        <li>Gagal nafas akut</li>
                                        <li>Kegagalan sirukulasi / syok</li>
                                        <li>Gangguan susunan saraf</li>
                                    </ul>
                                    </div>
                                </div>
                            
                            </b-form-radio>
                        </div>
                        <div class="custom-control custom-radio">
                            <b-form-radio
                                v-model="rowKriteriaICU.aukic_kriteria"
                                value="2" :name="'kriteriaICU'"
                                class="form-check-input-styled">
                                <div>
                                    <strong class="text-uppercase">Prioritas 2</strong>
                                    <div class="criteria-content">
                                    <p>Pasien yang memerlukan pemantauan intesif secara eksklusif atas keadaan yang dapat menimbulkan ancaman gangguan pada organ vital seperti:</p>
                                    <ul>
                                        <li>Pasien pasca operasi</li>
                                        <li>Riwayat cardiac arrest/pasca resusitasi</li>
                                        <li>Pasien yang membutuhkan obat-obatan intropic dan antiaritmia yang memerlukan monitoring intensif</li>
                                    </ul>
                                    </div>
                                </div>
                            </b-form-radio>
                        </div>
                        <div class="custom-control custom-radio">
                            <b-form-radio
                                v-model="rowKriteriaICU.aukic_kriteria"
                                value="3" :name="'kriteriaICU'"
                                class="form-check-input-styled">
                                <div>
                                    <strong class="text-uppercase">Prioritas 3</strong>
                                    <div class="criteria-content">
                                    <p>Pasien dalam keadaan sangat kritis dan tidak stabil yang harapan sembuhnya kecil. Pasien hanya membutuhkan perawatan intensif pada penyakit akutnya saja, tidak memerlukan intubasi/resusitasi kardiopulmer</p>
                                    </div>
                                </div>
                            </b-form-radio>
                        </div>
                    </div>
                    <VValidate 
                        name="Kriteria ICU" 
                        v-model="rowKriteriaICU.aukic_kriteria" 
                        :rules="toValidate(mrValidationKriteriaICU.aukic_kriteria)"
                    />
                    -->
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="text-right">
                    <button type="button" class="btn" @click="isParent.formModal = false" data-dismiss="modal">Batal</button>
                    <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                    </div>
                </div>
            </div>
        </b-form>
    </validation-observer>  
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowKriteriaICU: {},
            mrValidationKriteriaICU: {},
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        
        doSubmit(){
            this.$refs['VForm'].validate().then(success => {
                 if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowKriteriaICU
                            data.type = 'submit-kriteria-icu'
                            
                            this.loadingOverlay = true
                            this.isParent.formModal = false

                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },

        apiGetKriteriaICU(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-kriteria-icu',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetKriteriaICU()
    }
}

</script>

