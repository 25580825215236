<template>
    <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                <label for="inputPermintaanDarah1">Pasien Pernah Transfusi?<strong class="text-danger">*</strong></label>
                <b-form-radio-group
                :options="Config.mr.pernahTidakPernah"
                v-model="rowPermintaanDarah.aufpd_is_transfusi"
                />
                <VValidate 
                    name="Pasien Pernah Tranfusi" 
                    v-model="rowPermintaanDarah.aufpd_is_transfusi" 
                    :rules="{required: 1}"
                />
                </div>
                <div class="form-group">
                <label for="inputPermintaanDarah2">Indikasi Transfusi<strong class="text-danger">*</strong></label>
                <b-form-input v-model="rowPermintaanDarah.aufpd_indikasi" type="text" class="form-control" id="inputPermintaanDarah2" />
                <VValidate 
                    name="Indikasi Transfusi" 
                    v-model="rowPermintaanDarah.aufpd_indikasi" 
                    :rules="toValidate(mrValidationPermintaanDarah.aufpd_indikasi)"
                />
                </div>
                <div class="form-group">
                <label for="inputPermintaanDarah3">Golongan Darah<strong class="text-danger">*</strong></label>
                <b-form-input v-model="rowPermintaanDarah.aufpd_golongan_darah" type="text" class="form-control" id="inputPermintaanDarah3"/>
                <VValidate 
                    name="Golongan Darah" 
                    v-model="rowPermintaanDarah.aufpd_golongan_darah" 
                    :rules="toValidate(mrValidationPermintaanDarah.aufpd_golongan_darah)"
                />
                
                </div>
                <div class="form-group">
                <label for="inputPermintaanDarah4">Jenis Darah yang Diperlukan<strong class="text-danger">*</strong></label>
                <v-select placeholder="Jenis Darah" v-model="rowPermintaanDarah.aufpd_jenis_darah"
                  :options="Config.mr.JenisDarah" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>
                <VValidate 
                    name="Jenis Darah yang Diperlukan" 
                    v-model="rowPermintaanDarah.aufpd_jenis_darah" 
                    :rules="toValidate(mrValidationPermintaanDarah.aufpd_jenis_darah)"
                />

                </div>
                <div class="form-group">
                    <label for="inputPermintaanDarah5">Tanggal Diperlukan<strong class="text-danger">*</strong></label>
                    <div class="input-group">
                        <datepicker input-class="form-control transparent" placeholder="Tanggal Awal"
                        class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="rowPermintaanDarah.aufpd_tanggal"
                        >
                        </datepicker>
                        <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                        
                    </div>
                    
                    <VValidate 
                        name="Tanggal Diperlukan" 
                        v-model="rowPermintaanDarah.aufpd_tanggal" 
                        :rules="toValidate(mrValidationPermintaanDarah.aufpd_tanggal)"
                    />  
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.formModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        </b-form>
    </validation-observer>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowPermintaanDarah: {},
            mrValidationPermintaanDarah: {},
        }
    },
    components:{ Datepicker },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        
        doSubmit(){
            this.$refs['VForm'].validate().then(success => {
                 if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },500)
                        }
                    })
                }

                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowPermintaanDarah
                            data.type = 'submit-data-permintaan-darah'
                            
                            this.loadingOverlay = true
                            this.isParent.formModal = false

                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },

        apiGetPermintaanDarah(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-permintaan-darah',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetPermintaanDarah()
    }
}

</script>

