<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <div class="row" v-if="!rowSuratSakit.ausis_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowSuratSakit['ausis_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowSuratSakit.ausis_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowSuratSakit.ausis_is_ttd == 'Digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
                <div class="form-group">
                <label for="">Tanda Tangan Dokter Pemeriksa</label>
                <div class="signing-element" data-target-id="ttdDokterPemeriksa">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdDokterPemeriksa"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdDokterPemeriksa','ausis_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdDokterPemeriksa','ausis_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                    <div style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;">
                        <div>
                            <table border="1" style="border-collapse: collapse;width: 100%;">
                            <tbody><tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;" border="0">
                                    <tbody><tr>
                                    <td style="padding:8px 12px;">
                                        <div style="float: left;width: 12%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                        <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="width: 100%;" alt="">
                                        </div>
                                        <div style="text-transform: uppercase;float: left;width: 88%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;padding-right: 12%;text-align: center;">
                                        <h1 style="margin:0;font-size: 18px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan Magelang</h1>
                                        <p style="margin:0;font-size: 13px;">Jl. P. Senopati No. 11 Magelang 56123<br>Telp. (0293)364033 s/d 364035 Fax. (0293)364037</p>
                                        </div>
                                        <div style="display: block; clear: both;"></div>
                                    </td>
                                    </tr>
                                </tbody></table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;" border="0">
                                    <tbody>
                                    <tr>
                                        <td style="padding:12px 24px;" colspan="2">
                                        <h3 style="font-weight: bold;text-transform: uppercase;letter-spacing: 6px;text-decoration: underline;text-align: center;font-size: 20px;margin: 12px 0;">Surat Keterangan</h3>
                                        <div style="font-size: 16px;line-height: 26px;">
                                            <p style="margin: 8px 0;">Yang bertanda tangan dibawah ini menerangkan bahwa:</p>
                                            <table style="width:100%;margin: 0;">
                                            <tbody><tr>
                                                <td width="72">Nama</td>
                                                <td width="20" style="text-align:center;">:</td>
                                                <td style="border-bottom: 1px solid;">{{rowPasien.ap_fullname||"-"}}</td>
                                            </tr>
                                            <tr>
                                                <td width="72">Umur</td>
                                                <td width="20" style="text-align:center;">:</td>
                                                <td style="border-bottom: 1px solid;">{{rowPasien.ap_usia||"0"}} tahun</td>
                                            </tr>
                                            <tr>
                                                <td width="72">Alamat</td>
                                                <td width="20" style="text-align:center;">:</td>
                                                <td style="border-bottom: 1px solid;">{{rowPasien.ap_address||"-"}}</td>
                                            </tr>
                                            </tbody></table>
                                            <p style="margin: 8px 0;">Oleh karena <strong style="text-transform: uppercase;">Sakit / Hamil</strong>, perlu diberikan <strong style="text-transform: uppercase;">Istirahat / Cuti Hamil (Bersalin)</strong>.</p>
                                            <p style="margin: 8px 0;">Selama <strong style="margin:0 4px;width: 120px;display: inline-block;border-bottom: 1px solid #000;">{{rowSuratSakit.diffTime}}</strong> hari terhitung mulai tanggal <strong style="margin:0 4px;width: 160px;display: inline-block;border-bottom: 1px solid #000;">{{rowSuratSakit.ausis_tanggal_awal | moment("DD MMMM YYYY")}}</strong> s/d <strong style="margin:0 4px;width: 160px;display: inline-block;border-bottom: 1px solid #000;">{{rowSuratSakit.ausis_tanggal_akhir | moment("DD MMMM YYYY")}}</strong></p>
                                            <p style="margin-top: 8px;">Demikianlah surat keterangan ini dibuat sebenar-benarnya dan untuk dipergunakan semestinya.</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align: top;" width="50%"></td>
                                        <td style="vertical-align: top;">
                                        <table border="0" style="width: 100%;text-align: center;margin-bottom: 8px;font-size: 14px;">
                                            <tbody><tr>
                                            <td></td>
                                            <td>
                                                <span>Magelang,{{now}}</span>
                                                <br>
                                                <span>Dokter Pemeriksa,</span><br/>
                                                <span v-if="rowSuratSakit.ausis_is_ttd == 'Digital'">
                                                <img :src="rowSuratSakit.ausis_ttd ? rowSuratSakit.ausis_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                                <br/>
                                                </span>
                                                <span v-else>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>                          
                                                </span>
                                            </td>
                                            <td></td>
                                            </tr>
                                            
                                            <tr>
                                            <td></td>
                                            <td style="border-bottom: 1px solid;">
                                                {{rowReg.bu_full_name}}
                                            </td>
                                            <td></td>
                                            </tr>
                                        </tbody></table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </td>
                            </tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowSuratSakit.ausis_is_ttd == 'Manual' ? 'row' : 'd-none'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowSuratSakit: {},
            rowPasien: {},
            rowReg: {},
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowSuratSakit[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowSuratSakit[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if(this.rowSuratSakit.ausis_is_ttd == 'Digital'){
                    if(!this.rowSuratSakit.ausis_ttd){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                }

                this.rowSuratSakit.ausis_template = $(".document-framer").html()


                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowSuratSakit
                            data.type = 'submit-data-ttd-surat-sakit'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDSuratSakit(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-surat-sakit',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDSuratSakit()
        setTimeout(()=>{
            if(this.$refs['ttdDokterPemeriksa']){
                this.$refs['ttdDokterPemeriksa'].fromDataURL(this.rowSuratSakit.ausis_ttd)
            }
        },1500)
    }
}      
</script>
