<template>
    <div class="content">
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <div class="d-flex align-items-center">
              <h6 class="card-title font-weight-semibold">Catatan Partus/Persalinan</h6>
            </div>
          </div>
    

        <template v-if="!isDetail">
          <validation-observer ref="VForm">
              <b-form @submit.prevent="doSubmit" class="card">
              <div class="form-underlying card-body p-3">
                <div class="form-group">
                  <label for="">Pengisian Form</label>
                  <div>
                      <b-form-radio-group
                      :options="Config.mr.pengisianPartus"
                      v-model="rowPartus.aup_pengisian"
                    />
                  </div>
                </div>
                <div id="partusManual" class="partusPengisianOpt" v-if="rowPartus.aup_pengisian == 'Manual'">
                  <div class="alert alert-info">
                    <div class="d-flex">
                      <i class="icon-info22 align-middle"></i>
                      <span class="ml-2 align-middle">Unggah dokumen partus yang telah diisi manual. Apabila diperlukan, Anda dapat <a href="javascript:;" class="text-underline"><strong>mengunduh dokumen partus disini</strong></a>.</span>
                    </div>
                  </div>
                  <div class="form-group">
                      <label for="">Unggah Dokumen Partus <strong class="text-danger">*</strong></label>
                      <Uploader v-model="rowPartus.aup_file" isDocument type="doc_only" />
                      <VValidate 
                          name="Dokumen Partus" 
                          v-model="rowPartus.aup_file" 
                          :rules="toValidate(mrValidationPartus.aup_file)"
                      />
                  </div>
                </div>
                <div id="partusDigital" class="partusPengisianOpt" v-if="rowPartus.aup_pengisian == 'App'">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                          <label for="">Tanggal<strong class="text-danger">*</strong></label>
                          <div class="input-group mb-3">
                              <datepicker input-class="form-control transparent"
                              placeholder="Pilih Tanggal" class="my-datepicker"
                              calendar-class="my-datepicker_calendar" v-model="rowPartus.aup_date">
                              </datepicker>
                              <div class="input-group-append calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i
                                  class="icon-calendar"></i></span>
                              </div>
                          </div>
                        
                          <VValidate 
                              name="Tanggal" 
                              v-model="rowPartus.aup_date" 
                              :rules="toValidate(mrValidationPartus.aup_date)"
                          />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                          <label for="">Penolong Persalinan<strong class="text-danger">*</strong></label>
                          <b-form-input v-model="rowPartus.aup_penolong_persalinan" type="text" class="form-control"/>
                          <VValidate 
                              name="Tanggal" 
                              v-model="rowPartus.aup_penolong_persalinan" 
                              :rules="toValidate(mrValidationPartus.aup_penolong_persalinan)"
                          />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                          <label for="tempatPersalinan">Tempat Persalinan<strong class="text-danger">*</strong></label>
                          <v-select placeholder="Pilih Tempat Persalinan" v-model="rowPartus.aup_tempat_persalinan"
                              :options="Config.mr.partusPersalinan" label="text" :clearable="true"
                              :reduce="v=>v.value"></v-select>
                          <VValidate 
                              name="Tempat Persalinan" 
                              v-model="rowPartus.aup_tempat_persalinan" 
                              :rules="toValidate(mrValidationPartus.aup_tempat_persalinan)"
                          />
                      </div>
                    </div>
                    <div class="col-md-3" v-if="rowPartus.aup_tempat_persalinan == 'L'">
                      <div class="form-group">
                          <label for="tempatPersalinan">Tempat Persalinan Lainnya<strong class="text-danger">*</strong></label>
                          <b-form-input v-model="rowPartus.aup_tempat_persalinan_text" type="text" class="form-control"/>
                          <VValidate 
                              name="Tempat Persalinan Lainnya" 
                              v-model="rowPartus.aup_tempat_persalinan_text" 
                              :rules="{required:1,min:2,max:128}"
                          />
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3 class="text-uppercase">Kala I</h3>
                    <div class="form-group">
                      <div class="custom-control custom-radio custom-control-inline" style="margin-left: -30px;">
                          <b-form-radio v-model="rowPartus.aup_k1_partograf"  value="Y" name="kalaIParto">Partograf melewati garis waspada</b-form-radio>
                          <div class="d-inline-flex" >
                            <b-form-radio class="custom-control custom-radio ml-2" v-model="rowPartus.aup_k1_partograf"  value="L" name="kalaIParto">Lainnya</b-form-radio>
                            <input v-if="rowPartus.aup_k1_partograf == 'L'" v-model="rowPartus.aup_k1_partograf_text" type="text" class="form-control ml-2">
                          </div>
                      </div>
                      <VValidate 
                          v-if="rowPartus.aup_k1_partograf == 'L'"
                          name="Partograf Lainnya" 
                          v-model="rowPartus.aup_k1_partograf_text" 
                          :rules="{required:1,min:2,max:128}"
                      />
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="penatalaksanaanKalaI">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                          <b-form-textarea v-model="rowPartus.aup_k1_pelaksana" name="penatalaksanaanKalaI" id="penatalaksanaanKalaI" rows="3" class="form-control">
                          </b-form-textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="hasilPenatalaksanaanKalaI">Bagaimana Hasilnya?</label>
                          <b-form-textarea v-model="rowPartus.aup_k1_hasil" name="hasilPenatalaksanaanKalaI" id="hasilPenatalaksanaanKalaI" rows="3" class="form-control">
                          </b-form-textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3 class="text-uppercase">Kala II</h3>
                    <div class="row">
                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="">Lama Kala II</label>
                          <div class="input-group">
                            <b-form-input v-model="rowPartus.aup_k2_lama_kalla" :formatter="numberOnly" type="text" class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">menit</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-3">
                        <div class="mb-2">
                          <label for="">Episiotomi</label>
                          <div class="d-flex">
                          <b-form-radio-group
                              :options="Config.mr.yesNoOptV2"
                              v-model="rowPartus.aup_k2_episiotomi"
                          />
                          </div>
                        </div>
                        <div v-if="rowPartus.aup_k2_episiotomi == 'Y'">
                          <label for="">Indikasi</label>
                          <input v-model="rowPartus.aup_k2_episiotomi_text" type="text" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="pendamping">Pendamping pada saat persalinan</label>
                          <v-select placeholder="Pendamping saat persalinan" v-model="rowPartus.aup_k2_pendamping"
                              :options="Config.mr.partusK2Pendamping" label="text" :clearable="true"
                              :reduce="v=>v.value"></v-select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="gawatJanin">Gawat Janin</label>
                          <v-select placeholder="Pendamping saat persalinan" v-model="rowPartus.aup_k2_gawat_janin"
                              :options="Config.mr.partusK2GawatJanin" label="text" :clearable="true"
                              :reduce="v=>v.value"></v-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8 col-lg-6">
                        <div class="form-group">
                          <label for="">Distosia Bahu</label>
                          <div class="d-flex">
                              <b-form-radio v-model="rowPartus.aup_k2_distosia_bahu"  value="Y" name="kalaIIBahu">Manuver McRobert Ibu merangkang</b-form-radio>
                              <div class="d-inline-flex">
                              <b-form-radio class="custom-control custom-radio ml-2" v-model="rowPartus.aup_k2_distosia_bahu"  value="L" name="kalaIIBahu">Lainnya</b-form-radio>
                              <input v-if="rowPartus.aup_k2_distosia_bahu == 'L'" v-model="rowPartus.aup_k2_distosia_bahu_text" type="text" class="form-control ml-2">
                              </div>
                          </div>
                          <VValidate 
                              v-if="rowPartus.aup_k2_distosia_bahu == 'L'"
                              name="Partograf Lainnya" 
                              v-model="rowPartus.aup_k2_distosia_bahu_text" 
                              :rules="{required:1,min:2,max:128}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="penatalaksanaanKalaI">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                          <b-form-textarea v-model="rowPartus.aup_k2_penatalaksanaan_masalah"
                          name="penatalaksanaanKalaI" id="penatalaksanaanKalaI" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="hasilPenatalaksanaanKalaI">Bagaimana Hasilnya?</label>
                          <b-form-textarea v-model="rowPartus.aup_k2_hasil"
                          name="hasilPenatalaksanaanKalaI" id="hasilPenatalaksanaanKalaI" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3 class="text-uppercase">Kala III</h3>
                    <div class="row">
                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="">Lama Kala III</label>
                          <div class="input-group">
                            <b-form-input v-model="rowPartus.aup_k3_lama_kalla" :formatter="numberOnly" type="text" class="form-control"/>
                            <div class="input-group-append"><span class="input-group-text">menit</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="">Jumlah Pendarahan</label>
                          <div class="input-group">
                            <b-form-input v-model="rowPartus.aup_k3_jumlah_pendarahan" :formatter="numberOnly"  type="text" class="form-control"/>
                            <div class="input-group-append"><span class="input-group-text">ml</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7">
                        <div class="mb-3">
                          <table class="table table-bordered table-sm">
                            <thead>
                              <tr class="table-primary"><th class="text-uppercase" colspan="4">Tindakan Kala III</th></tr>
                              <tr>
                                <th width="20">No</th>
                                <th>Tindakan</th>
                                <th>Pelaksanaan</th>
                                <th>Alasan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Pemberian Lainnya 10 U IM kurang dari 2 menit?</td>
                                <td>
                                  <div>
                                      <b-form-radio-group
                                          :options="Config.mr.yesNoOptV2"
                                          v-model="rowPartus.aup_k3_tindakan1"
                                      />
                                  </div>
                                </td>
                                <td>
                                  <b-form-input v-model="rowPartus.aup_k3_alassan1" type="text" class="form-control"/>
                                </td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>Pemberian Oksitosin ulang (2x)?</td>
                                <td>
                                  <div>
                                      <b-form-radio-group
                                          :options="Config.mr.yesNoOptV2"
                                          v-model="rowPartus.aup_k3_tindakan2"
                                      />
                                  </div>
                                </td>
                                <td>
                                  <b-form-input v-model="rowPartus.aup_k3_alassan2" type="text" class="form-control"/>
                                </td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>Pemegangan tali pusat terkendali?</td>
                                <td>
                                  <div>
                                      <b-form-radio-group
                                          :options="Config.mr.yesNoOptV2"
                                          v-model="rowPartus.aup_k3_tindakan3"
                                      />
                                  </div>
                                </td>
                                <td>
                                  <b-form-input v-model="rowPartus.aup_k3_alassan3" type="text" class="form-control"/>
                                </td>
                              </tr>
                              <tr>
                                <td>4</td>
                                <td>Masase tundus uteri?</td>
                                <td>
                                  <div>
                                      <b-form-radio-group
                                          :options="Config.mr.yesNoOptV2"
                                          v-model="rowPartus.aup_k3_tindakan4"
                                      />
                                  </div>
                                </td>
                                <td>
                                  <b-form-input v-model="rowPartus.aup_k3_alassan4" type="text" class="form-control"/>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="">Laserasi Perineum Derajat</label>
                              <b-form-input v-model="rowPartus.aup_k3_laserasi" type="text" class="form-control"/>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="">Tindakan</label>
                              <div class="form-row">
                                <div class="col-md-6">
                                  <v-select placeholder="Pilih Tindakan" v-model="rowPartus.aup_k3_tindakan"
                                  :options="Config.mr.partusK3Tindakan" label="text" :clearable="true"
                                  :reduce="v=>v.value"></v-select>
                                </div>
                                <div class="col-md-6" v-if="rowPartus.aup_k3_tindakan == 'L'">
                                  <b-form-textarea v-model="rowPartus.aup_k3_tindakan_text" name="" id="" rows="2" class="form-control">
                                  </b-form-textarea>
                                  <VValidate 
                                      name="Tindakan Lainnya" 
                                      v-model="rowPartus.aup_k3_tindakan_text" 
                                      :rules="{required:1,min:2,max:128}"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="">Atonia uteri</label>
                              <div class="form-row">
                                <div class="col-md-6">
                                  <v-select placeholder="Pilih Tindakan" v-model="rowPartus.aup_k3_atonia_uteri"
                                  :options="Config.mr.partusK3AtoniaUteri" label="text" :clearable="true"
                                  :reduce="v=>v.value"></v-select>
                                </div>
                                <div class="col-md-6" v-if="rowPartus.aup_k3_atonia_uteri == 'L'">
                                  <b-form-textarea v-model="rowPartus.aup_k3_atonia_uteri_text" name="" id="" rows="2" class="form-control">
                                  </b-form-textarea>
                                  <VValidate 
                                      name="Atonia uteri Lainnya" 
                                      v-model="rowPartus.aup_k3_atonia_uteri_text" 
                                      :rules="{required:1,min:2,max:128}"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="penatalaksanaanKalaI">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                          <b-form-textarea v-model="rowPartus.aup_k3_penatalaksanaan_masalah" name="penatalaksanaanKalaI" id="penatalaksanaanKalaI" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="hasilPenatalaksanaanKalaI">Bagaimana Hasilnya?</label>
                          <b-form-textarea v-model="rowPartus.aup_k3_hasil" name="hasilPenatalaksanaanKalaI" id="hasilPenatalaksanaanKalaI" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3 class="text-uppercase">Bayi Baru Lahir</h3>
                    <div class="row">
                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="">Berat Badan</label>
                          <div class="input-group">
                            <b-form-input v-model="rowPartus.aup_bbl_bb" :formatter="numberOnly" type="text" class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">gram</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-2">
                        <div class="form-group">
                          <label for="">Panjang</label>
                          <div class="input-group">
                          <b-form-input v-model="rowPartus.aup_bbl_panjang" :formatter="numberOnly" type="text" class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">cm</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-xl-2">
                        <div class="form-group">
                          <label for="">Jenis Kelamin</label>
                          <div>
                              <b-form-radio-group
                                  :options="Config.mr.hdJK"
                                  v-model="rowPartus.aup_bbl_jenis_kelamin"
                              />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Nilai APGAR</label>
                          <div class="input-group">
                            <b-form-input v-model="rowPartus.aup_bbl_nilai_apgar_1" :formatter="numberOnly" type="text" class="form-control"/>
                            <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                            <b-form-input v-model="rowPartus.aup_bbl_nilai_apgar_2" :formatter="numberOnly" type="text" class="form-control"/>
                            <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                            <b-form-input v-model="rowPartus.aup_bbl_nilai_apgar_3" :formatter="numberOnly" type="text" class="form-control"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="mb-2">
                          <label for="">Pemberian ASI kurang dari 1 Jam</label>
                          <div>
                              <b-form-radio-group
                                  :options="Config.mr.yesNoOptV2"
                                  v-model="rowPartus.aup_bbl_pemberian_asi"
                              />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="">Alasan</label>
                          <b-form-input v-model="rowPartus.aup_bbl_alasan" name="" id="" rows="2" class="form-control"></b-form-input>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-2">
                          <label for="">Bayi Baru Lahir Pucat/Biru/Lemas</label>
                          <v-select placeholder="Pilih Status" v-model="rowPartus.aup_bbl_status"
                          :options="Config.mr.partusBBLStatus" label="text" :clearable="true"
                          :reduce="v=>v.value"></v-select>
                        </div>
                        <template v-if="rowPartus.aup_bbl_status == 'L' || rowPartus.aup_bbl_status == 'Cacat Bawaan'">
                            <b-form-textarea v-model="rowPartus.aup_bbl_status_lainnya" name="" id="" rows="2" class="form-control"></b-form-textarea>
                        </template>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="penatalaksanaanKalaI">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                          <b-form-textarea v-model="rowPartus.aup_bbl_penatalaksanaan" name="penatalaksanaanKalaI" id="penatalaksanaanKalaI" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="hasilPenatalaksanaanKalaI">Bagaimana Hasilnya?</label>
                          <b-form-textarea v-model="rowPartus.aup_bbl_hasil" name="hasilPenatalaksanaanKalaI" id="hasilPenatalaksanaanKalaI" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3 class="text-uppercase">Pemantauan Persalinan Kala IV</h3>
                    <div class="mb-3">
                      <div class="table-responsive">
                      <table class="table table-sm table-bordered table-input">
                          <thead>
                              <tr>
                              <th width="20">Jam Ke</th>
                              <th width="80">Pukul</th>
                              <th width="290">Tekanan Darah</th>
                              <th width="170">Nadi</th>
                              <th width="170">Suhu</th>
                              <th width="70">Tinggi Fundus Uterus</th>
                              <th width="70">Kontraksi Uterus</th>
                              <th width="70">Kandung Kemih</th>
                              <th width="70">Perdarahan</th>
                              </tr>
                          </thead>
                          
                          <tr v-for="(v,k) in (rowPartus.aup_k5_data||[])" :key="k">
                            <td v-if="v.isHead" :rowspan="v.rowspan">{{v.no}}</td>
                            <td class="input-cell">
                              <div class="input-group bootstrap-timepicker timepicker">
                                  <!--
                                  <span class="input-group-prepend input-group-addon">
                                      <span class="input-group-text"><i class="icon-alarm"></i></span>
                                  </span>
                                  -->
                                  <vue-timepicker @input="autoFillTime($event,k)" format="HH:mm" input-class="form-control custom-timepicker" v-model="v.time">
                                  </vue-timepicker>
                              </div>
                            </td>
                            <td class="input-cell">
                              <div class="input-group input-group-sm">
                                <b-form-input type="text" :formatter="numberOnly" v-model="v.tekanan_darah_min" class="form-control form-control-sm" />
                                <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                                <b-form-input type="text" :formatter="numberOnly" v-model="v.tekanan_darah_max" class="form-control form-control-sm" />
                                <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                              </div>
                            </td>
                            <td class="input-cell">
                              <div class="input-group input-group-sm">
                                <b-form-input :formatter="numberOnly" type="text" v-model="v.nadi" class="form-control form-control-sm" />
                                <div class="input-group-append">
                                  <span class="input-group-text">x/mnt</span>
                                </div>
                              </div>
                            </td>

                            <td v-if="!v.isHead" class="bg-primary"></td>
                            <td v-else class="input-cell">
                              <div class="input-group input-group-sm">
                                <b-form-input :formatter="number" v-model="v.suhu" type="text" class="form-control form-control-sm" />
                                <div class="input-group-append"><span class="input-group-text">&deg;C</span></div>
                              </div>
                            </td>

                            <td class="input-cell">
                              <b-form-input :formatter="normalText" v-model="v.tfu" type="text" class="form-control form-control-sm" />
                            </td>
                            <td class="input-cell">
                              <b-form-input :formatter="normalText"  v-model="v.kontraksi_uterus" type="text" class="form-control form-control-sm" />
                            </td>
                            <td class="input-cell">
                              <b-form-input :formatter="normalText"  v-model="v.kandung_kemih" type="text" class="form-control form-control-sm" />
                            </td>
                            <td class="input-cell">
                              <b-form-input :formatter="normalText"  v-model="v.pendarahan" type="text" class="form-control form-control-sm" />
                            </td>
                          </tr>
                          
                      </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="penatalaksanaanKalaIV">Masalah Kala IV</label>
                          <b-form-textarea v-model="rowPartus.aup_k5_problem" name="penatalaksanaanKalaIV" id="penatalaksanaanKalaIV" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="penatalaksanaanKalaIV">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                          <b-form-textarea v-model="rowPartus.aup_k5_penatalaksanaan" name="penatalaksanaanKalaIV" id="penatalaksanaanKalaIV" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="hasilPenatalaksanaanKalaIV">Bagaimana Hasilnya?</label>
                          <b-form-textarea v-model="rowPartus.aup_k5_hasil" name="hasilPenatalaksanaanKalaIV" id="hasilPenatalaksanaanKalaIV" rows="3" class="form-control"></b-form-textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3 class="text-uppercase">KIE</h3>
                    <table class="table table-sm table-bordered table-input">
                      <thead>
                        <tr>
                          <th width="20">No</th>
                          <th width="25%">Tanggal</th>
                          <th width="25%">Materi</th>
                          <th width="25%">Pelaksana</th>
                          <th width="25%">Keterangan</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (rowPartus.aup_kie||[])" :key="k" style="
                              text-align: center;
                              /* margin-top: 31px; */
                          ">
                          <td class="input-cell pt-2">{{k+1}}</td>
                          <td class="input-cell pt-2">
                              <div class="input-group mb-3">
                                  <datepicker input-class="form-control transparent"
                                  placeholder="Pilih Tanggal" class="my-datepicker"
                                  calendar-class="my-datepicker_calendar" v-model="rowPartus.aup_kie[k]['date']">
                                  </datepicker>
                                  <div class="input-group-append calendar-group">
                                  <span class="input-group-text" id="basic-addon2"><i
                                      class="icon-calendar"></i></span>
                                  </div>
                              </div>
                          </td>
                          <td class="input-cell pt-2">
                              <v-select placeholder="Pilih materi" v-model="rowPartus.aup_kie[k]['materi']"
                              :options="Config.mr.partusMateri" label="text" :clearable="true"
                              :reduce="v=>v.value"></v-select>
                          </td>
                          <td class="input-cell pt-2">
                            <b-form-input placeholder="Pelaksana" v-model="rowPartus.aup_kie[k]['pelaksana']" type="text" class="form-control form-control-sm"/>
                          </td>
                          <td class="input-cell pt-2">
                            <b-form-input placeholder="Keterangan" v-model="rowPartus.aup_kie[k]['keterangan']" type="text" class="form-control form-control-sm"/>
                          </td>
                          <td class="input-cell pt-2">
                              <a href="javascript:;" @click="rowPartus.aup_kie.splice(k,1)" class="list-icons-item"
                              data-toggle="tooltip" data-placement="top" title="Delete"><i
                              class="icon-bin"></i></a>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td class="text-center" colspan="5">
                            <a href="javascript:;" @click="addKIE" class="btn btn-sm alpha-blue border-blue">
                              <i class="icon-plus2 mr-2"></i>
                              <span>Tambah KIE</span>
                            </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="text-right">
                  <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
                  <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
              </div>
              </b-form>
          </validation-observer>
        </template>

        
        <template v-else>
          <div class="form-underlying card-body p-3">
            <table class="table table-bordered table-sm">
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Pengisian Form</h4>
                    <p>{{getConfigDynamic(Config.mr.pengisianPartus,rowPartus.aup_pengisian)||"-"}}</p>
                  </div>
                </td>
                <template v-if="rowPartus.aup_pengisian == 'Manual'">
                  <td>
                    <div class="result_tab">
                      <h4>Dokumen Partus</h4>
                      <p>
                        <a :href="uploader(rowPartus.aup_file)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh Dokumen Partus</span></a>
                      </p>
                    </div>
                  </td>
                </template>
                <template v-else-if="rowPartus.aup_pengisian == 'App'">
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal</h4>
                      <p>{{rowPartus.aup_date | moment("DD MMMM YYYY")}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Penolong Persalinan</h4>
                      <p>{{rowPartus.aup_penolong_persalinan || "-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Tempat Persalinan</h4>
                      <p>
                        <span>
                          {{getConfigDynamic(Config.mr.partusPersalinan,rowPartus.aup_tempat_persalinan)||"-"}}
                        </span>
                        <span v-if="rowPartus.aup_tempat_persalinan == 'L'"> - {{rowPartus.aup_tempat_persalinan_text || "-"}}</span>
                      </p>
                    </div>
                  </td>
                </template>
              </tr>
            </table>
            <!-- <div id="partusManual" class="partusPengisianOpt" v-if="rowPartus.aup_pengisian == 'Manual'">
              <div class="alert alert-info">
                <div class="d-flex">
                  <i class="icon-info22 align-middle"></i>
                  <span class="ml-2 align-middle">Unggah dokumen partus yang telah diisi manual. Apabila diperlukan, Anda dapat <a href="javascript:;" class="text-underline"><strong>mengunduh dokumen partus disini</strong></a>.</span>
                </div>
              </div>
              <div class="form-group">
                  <label for="">Dokumen Partus</label>
                  <a :href="uploader(rowPartus.aup_file)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh Dokumen Partus</span></a>
              </div>
            </div> -->
            <div id="partusDigital" class="partusPengisianOpt" v-if="rowPartus.aup_pengisian == 'App'">
              <!-- <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Tanggal</label>
                    <h6 class="font-weight-600">{{rowPartus.aup_date | moment("DD MMMM YYYY")}}</h6>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                      <label for="">Penolong Persalinan</label>
                      <h6 class="font-weight-600">{{rowPartus.aup_penolong_persalinan || "-"}}</h6>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                      <label for="tempatPersalinan">Tempat Persalinan</label>
                      <h6 class="font-weight-600">{{getConfigDynamic(Config.mr.partusPersalinan,rowPartus.aup_tempat_persalinan)||"-"}}</h6>
                  </div>
                </div>
                <div class="col-md-3" v-if="rowPartus.aup_tempat_persalinan == 'L'">
                  <div class="form-group">
                      <label for="tempatPersalinan">Tempat Persalinan Lainnya</label>
                      <h6 class="font-weight-600">{{rowPartus.aup_tempat_persalinan_text || "-"}}</h6>
                  </div>
                </div>
              </div> -->

              <div class="wrap_line">
                <h3 class="text-uppercase">Kala I</h3>
                <table class="table table-bordered table-sm">
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <p>
                          <span class="font-weight-600" v-if="rowPartus.aup_k1_partograf == 'L'">{{rowPartus.aup_k1_partograf_text||"-"}}</span>
                          <span class="font-weight-600" v-else>Partograf melewati garis waspada</span>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Penatalaksanaan yang dilaksanakan untuk masalah tersebut</h4>
                        <p>{{rowPartus.aup_k1_pelaksana || "-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Bagaimana Hasilnya?</h4>
                        <p>{{rowPartus.aup_k1_hasil || "-"}}</p>
                      </div>
                    </td>
                  </tr>
                </table>
                <!-- <div class="form-group">
                    <h6 class="font-weight-600" v-if="rowPartus.aup_k1_partograf == 'L'">
                      {{rowPartus.aup_k1_partograf_text||"-"}}
                    </h6>
                    <h6 class="font-weight-600" v-else>
                      Partograf melewati garis waspada
                    </h6>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="penatalaksanaanKalaI">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                      <h6 class="font-weight-600">{{rowPartus.aup_k1_pelaksana || "-"}}</h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="hasilPenatalaksanaanKalaI">Bagaimana Hasilnya?</label>
                      <h6 class="font-weight-600">{{rowPartus.aup_k1_hasil || "-"}}</h6>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="wrap_line">
                <h3 class="text-uppercase">Kala II</h3>
                <table class="table table-sm table-bordered">
                  <tr>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Lama Kala II</h4>
                        <p>{{rowPartus.aup_k2_lama_kalla||"-"}} menit</p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Episiotomi</h4>
                        <p>
                          <span>{{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k2_episiotomi)||"-"}}</span>
                          <span v-if="rowPartus.aup_k2_episiotomi == 'Y'">, {{rowPartus.aup_k2_episiotomi_text||"-"}}</span>
                        </p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Pendamping pada saat persalinan</h4>
                        <p>{{getConfigDynamic(Config.mr.partusK2Pendamping,rowPartus.aup_k2_pendamping)||"-"}}</p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Gawat Janin</h4>
                        <p>{{getConfigDynamic(Config.mr.partusK2GawatJanin,rowPartus.aup_k2_gawat_janin)||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <div class="result_tab">
                        <h4>Distosia Bahu</h4>
                        <p v-if="rowPartus.aup_k2_distosia_bahu == 'L'">{{rowPartus.aup_k2_distosia_bahu||"-"}}</p>
                        <p v-else>Manuver McRobert Ibu merangkang</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Penatalaksanaan yang dilaksanakan untuk masalah tersebut</h4>
                        <p>{{rowPartus.aup_k2_penatalaksanaan_masalah||"-"}}</p>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Bagaimana Hasilnya?</h4>
                        <p>{{rowPartus.aup_k2_hasil||"-"}}</p>
                      </div>
                    </td>
                  </tr>  
                </table>
                <!-- <div class="row">
                  <div class="col-md-3 col-lg-2">
                    <div class="form-group">
                      <label for="">Lama Kala II</label>
                      <h6 class="font-weight-600">{{rowPartus.aup_k2_lama_kalla||"-"}}</h6>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="mb-2">
                      <label for="">Episiotomi</label>
                      <h6 class="font-weight-600">{{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k2_episiotomi)||"-"}}</h6>
                    </div>
                    <div v-if="rowPartus.aup_k2_episiotomi == 'Y'">
                      <label for="">Indikasi</label>
                      <h6 class="font-weight-600">{{rowPartus.aup_k2_episiotomi_text||"-"}}</h6>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="pendamping">Pendamping pada saat persalinan</label>
                      <h6 class="font-weight-600">{{getConfigDynamic(Config.mr.partusK2Pendamping,rowPartus.aup_k2_pendamping)||"-"}}</h6>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="gawatJanin">Gawat Janin</label>
                      <h6 class="font-weight-600">{{getConfigDynamic(Config.mr.partusK2GawatJanin,rowPartus.aup_k2_gawat_janin)||"-"}}</h6>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-8 col-lg-6">
                    <div class="form-group">
                      <label for="">Distosia Bahu</label>
                      <h6 class="font-weight-600" v-if="rowPartus.aup_k2_distosia_bahu == 'L'">
                        {{rowPartus.aup_k2_distosia_bahu||"-"}}
                      </h6>
                      <h6 class="font-weight-600" v-else>
                        Manuver McRobert Ibu merangkang
                      </h6>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="penatalaksanaanKalaI">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_k2_penatalaksanaan_masalah||"-"}}
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="hasilPenatalaksanaanKalaI">Bagaimana Hasilnya?</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_k2_hasil||"-"}}
                      </h6>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="wrap_line">
                <h3 class="text-uppercase">Kala III</h3>
                <div class="row">
                  <div class="col-md-6">
                    <table class="table table-bordered table-sm">
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Lama Kala III</h4>
                            <p>{{rowPartus.aup_k3_lama_kalla||"-"}} menit</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Jumlah Pendarahan</h4>
                            <p>{{rowPartus.aup_k3_jumlah_pendarahan||"-"}} ml</p>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="my-3">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="2" class="text-uppercase">Tindakan Kala III</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="50%">
                          <div class="result_tab">
                            <h4>Pemberian Lainnya 10 U IM kurang dari 2 menit?</h4>
                            <p>{{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k3_tindakan1)||"-"}}, alasan: {{rowPartus.aup_k3_alassan1||"-"}}</p>
                          </div>
                        </td>
                        <td width="50%">
                          <div class="result_tab">
                            <h4>Pemberian Oksitosin ulang (2x)?</h4>
                            <p>{{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k3_tindakan2)||"-"}}, alasan: {{rowPartus.aup_k3_alassan2||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td width="50%">
                          <div class="result_tab">
                            <h4>Pemegangan tali pusat terkendali?</h4>
                            <p>{{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k3_tindakan3)||"-"}}, alasan: {{rowPartus.aup_k3_alassan3||"-"}}</p>
                          </div>
                        </td>
                        <td width="50%">
                          <div class="result_tab">
                            <h4>Masase tundus uteri?</h4>
                            <p>{{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k3_tindakan4)||"-"}}, alasan: {{rowPartus.aup_k3_alassan4||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table class="table table-bordered table-sm">
                    <tr>
                      <td colspan="2" width="33%">
                        <div class="result_tab">
                          <h4>Laserasi Perineum Derajat</h4>
                          <p>{{rowPartus.aup_k3_laserasi||"-"}}</p>
                        </div>
                      </td>
                      <td colspan="2" width="33%">
                        <div class="result_tab">
                          <h4>Tindakan</h4>
                          <p>
                            {{ rowPartus.aup_k3_tindakan == 'L' ? `Lainnya, ${rowPartus.aup_k3_tindakan_text||"-"}` : (getConfigDynamic(Config.mr.partusK3Tindakan,rowPartus.aup_k3_tindakan)||"-") }}
                          </p>
                        </div>
                      </td>
                      <td colspan="2" width="33%">
                        <div class="result_tab">
                          <h4>Atonia uteri</h4>
                          <p>
                            {{ rowPartus.aup_k3_atonia_uteri == 'L' ? `Lainnya, ${rowPartus.aup_k3_atonia_uteri_text||"-"}` : (getConfigDynamic(Config.mr.partusK3AtoniaUteri,rowPartus.aup_k3_atonia_uteri)||"-") }}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" width="50%">
                        <div class="result_tab">
                          <h4>Penatalaksanaan yang dilaksanakan untuk masalah tersebut</h4>
                          <p>{{rowPartus.aup_k3_penatalaksanaan_masalah||"-"}}</p>
                        </div>
                      </td>
                      <td colspan="3" width="50%">
                        <div class="result_tab">
                          <h4>Bagaimana hasilnya?</h4>
                          <p>{{rowPartus.aup_k3_hasil||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- <div class="row">
                  <div class="col-md-7">
                    <div class="mb-3">
                      <table class="table table-bordered table-sm">
                        <thead>
                          <tr class="table-primary"><th class="text-uppercase" colspan="4">Tindakan Kala III</th></tr>
                          <tr>
                            <th width="20">No</th>
                            <th>Tindakan</th>
                            <th>Pelaksanaan</th>
                            <th>Alasan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Pemberian Lainnya 10 U IM kurang dari 2 menit?</td>
                            <td>
                              <div>
                                {{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k3_tindakan1)||"-"}}
                              </div>
                            </td>
                            <td>
                              {{rowPartus.aup_k3_alassan1||"-"}}
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Pemberian Oksitosin ulang (2x)?</td>
                            <td>
                              <div>
                                {{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k3_tindakan2)||"-"}}
                              </div>
                            </td>
                            <td>
                              {{rowPartus.aup_k3_alassan2||"-"}}
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Pemegangan tali pusat terkendali?</td>
                            <td>
                              <div>
                                {{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k3_tindakan3)||"-"}}
                              </div>
                            </td>
                            <td>
                              {{rowPartus.aup_k3_alassan3||"-"}}
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Masase tundus uteri?</td>
                            <td>
                              <div>
                                {{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_k3_tindakan4)||"-"}}
                              </div>
                            </td>
                            <td>
                              {{rowPartus.aup_k3_alassan4||"-"}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="">Laserasi Perineum Derajat</label>
                          <h6 class="font-weight-600">
                            {{rowPartus.aup_k3_laserasi||"-"}}
                          </h6>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="">Tindakan</label>
                          <div class="form-row">
                            <div class="col-md-6">
                              <h6 class="font-weight-600" v-if="rowPartus.aup_k3_tindakan == 'L'">
                                {{rowPartus.aup_k3_tindakan_text||"-"}}
                              </h6>
                              <h6 class="font-weight-600" v-else>
                                {{getConfigDynamic(Config.mr.partusK3Tindakan,rowPartus.aup_k3_tindakan)||"-"}}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="">Atonia uteri</label>
                          <div class="form-row">
                            <div class="col-md-6">
                              <h6 class="font-weight-600" v-if="rowPartus.aup_k3_atonia_uteri == 'L'">
                                {{rowPartus.aup_k3_atonia_uteri_text||"-"}}
                              </h6>
                              <h6 class="font-weight-600" v-else>
                                {{getConfigDynamic(Config.mr.partusK3AtoniaUteri,rowPartus.aup_k3_atonia_uteri)||"-"}}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="penatalaksanaanKalaI">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_k3_penatalaksanaan_masalah||"-"}}
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="hasilPenatalaksanaanKalaI">Bagaimana Hasilnya?</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_k3_hasil||"-"}}
                      </h6>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="wrap_line">
                <h3 class="text-uppercase">Bayi Baru Lahir</h3>
                <table class="table table-bordered table-sm">
                  <tr>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Berat Badan</h4>
                        <p>{{rowPartus.aup_bbl_bb||"-"}} {{rowPartus.aup_bbl_bb ? 'gram' : ''}}</p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Panjang</h4>
                        <p>{{rowPartus.aup_bbl_panjang||"-"}} {{rowPartus.aup_bbl_panjang ? 'cm' : ''}}</p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Jenis Kelamin</h4>
                        <p>{{getConfigDynamic(Config.mr.hdJK,rowPartus.aup_bbl_jenis_kelamin)||"-"}}</p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Nilai APGAR</h4>
                        <p>{{ `${rowPartus.aup_bbl_nilai_apgar_1||"-"} / ${rowPartus.aup_bbl_nilai_apgar_2||"-"} / ${rowPartus.aup_bbl_nilai_apgar_3||"-"}` }}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Pemberian ASI kurang dari 1 Jam</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_bbl_pemberian_asi)||"-"}}, alasan: {{rowPartus.aup_bbl_alasan||"-"}}</p>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Bayi Baru Lahir Pucat/Biru/Lemas</h4>
                        <p>{{ rowPartus.aup_bbl_status == 'L' ? `Lain-lain, ${rowPartus.aup_bbl_status_text||"-"}` : (getConfigDynamic(Config.mr.partusBBLStatus,rowPartus.aup_bbl_status)||"-") }}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Penatalaksanaan yang dilaksanakan untuk masalah tersebut</h4>
                        <p>{{rowPartus.aup_bbl_penatalaksanaan||"-"}}</p>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Bagaimana Hasilnya?</h4>
                        <p>{{rowPartus.aup_bbl_hasil||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </table>
                <!-- <div class="row">
                  <div class="col-md-3 col-lg-2">
                    <div class="form-group">
                      <label for="">Berat Badan</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_bbl_bb||"-"}} {{rowPartus.aup_bbl_bb ? 'gram' : ''}}
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <div class="form-group">
                      <label for="">Panjang</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_bbl_panjang||"-"}} {{rowPartus.aup_bbl_panjang ? 'cm' : ''}}
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-3 col-xl-2">
                    <div class="form-group">
                      <label for="">Jenis Kelamin</label>
                      <h6 class="font-weight-600">
                          {{getConfigDynamic(Config.mr.hdJK,rowPartus.aup_bbl_jenis_kelamin)||"-"}}
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="">Nilai APGAR</label>
                      <h6 class="font-weight-600">{{rowPartus.aup_bbl_nilai_apgar_1||"-"}}</h6>
                      <h6 class="font-weight-600">{{rowPartus.aup_bbl_nilai_apgar_2||"-"}}</h6>
                      <h6 class="font-weight-600">{{rowPartus.aup_bbl_nilai_apgar_3||"-"}}</h6>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-3">
                    <div class="mb-2">
                      <label for="">Pemberian ASI kurang dari 1 Jam</label>
                      <h6 class="font-weight-600">
                          {{getConfigDynamic(Config.mr.yesNoOptV2,rowPartus.aup_bbl_pemberian_asi)||"-"}}
                      </h6>
                    </div>
                    <div class="form-group">
                      <label for="">Alasan</label>
                      <h6 class="font-weight-600">{{rowPartus.aup_bbl_alasan||"-"}}</h6>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <h6 class="font-weight-600" v-if="rowPartus.aup_bbl_status == 'L'">
                      {{rowPartus.aup_bbl_status_text||"-"}}
                    </h6>
                    <h6 class="font-weight-600" v-else>
                      {{getConfigDynamic(Config.mr.partusBBLStatus,rowPartus.aup_bbl_status)||"-"}}
                    </h6>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="penatalaksanaanKalaI">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_bbl_penatalaksanaan||"-"}}
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="hasilPenatalaksanaanKalaI">Bagaimana Hasilnya?</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_bbl_hasil||"-"}}
                      </h6>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="wrap_line">
                <h3 class="text-uppercase">Pemantauan Persalinan Kala IV</h3>
                <div class="mb-3">
                  <div class="table-responsive">
                  <table class="table table-sm table-bordered">
                      <thead>
                          <tr>
                          <th width="20">Jam Ke</th>
                          <th width="80">Pukul</th>
                          <th width="150">Tekanan Darah</th>
                          <th width="80">Nadi</th>
                          <th width="60">Suhu</th>
                          <th width="70">Tinggi Fundus Uterus</th>
                          <th width="70">Kontraksi Uterus</th>
                          <th width="70">Kandung Kemih</th>
                          <th width="70">Perdarahan</th>
                          </tr>
                      </thead>
                      
                      <tr v-for="(v,k) in (rowPartus.aup_k5_data||[])" :key="k">
                        <td v-if="v.isHead" :rowspan="v.rowspan">{{v.no}}</td>
                        <td class="input-cell">
                          <div class="input-group bootstrap-timepicker timepicker">
                            {{v.time||"-"}}
                          </div>
                        </td>
                        <td class="input-cell">
                          {{v.tekanan_darah_min}}/{{v.tekanan_darah_max}} {{v.tekanan_darah_min && v.tekanan_darah_max ?'mmHG':''}}
                        </td>
                        <td class="input-cell">
                          {{v.nadi||"-"}} {{v.nadi?'x/mnt':''}}
                        </td>

                        <td v-if="!v.isHead" class="bg-primary"></td>
                        <td v-else class="input-cell">
                          {{v.suhu||"-"}} {{v.suhu?'°C':''}}
                        </td>

                        <td class="input-cell">
                          {{v.tfu||"-"}}
                        </td>
                        <td class="input-cell">
                          {{v.kontraksi_uterus||"-"}}
                        </td>
                        <td class="input-cell">
                          {{v.kandung_kemih||"-"}}
                        </td>
                        <td class="input-cell">
                          {{v.pendarahan||"-"}}
                        </td>
                      </tr>
                      
                  </table>
                  </div>
                </div>
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Masalah Kala IV</h4>
                        <p>{{rowPartus.aup_k5_problem||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Penatalaksanaan yang dilaksanakan untuk masalah tersebut</h4>
                        <p>{{rowPartus.aup_k5_penatalaksanaan||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Bagaimana Hasilnya?</h4>
                        <p>{{rowPartus.aup_k5_hasil||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </table>
                <!-- <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="penatalaksanaanKalaIV">Masalah Kala IV</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_k5_problem||"-"}}
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="penatalaksanaanKalaIV">Penatalaksanaan yang dilaksanakan untuk masalah tersebut</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_k5_penatalaksanaan||"-"}}
                      </h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="hasilPenatalaksanaanKalaIV">Bagaimana Hasilnya?</label>
                      <h6 class="font-weight-600">
                        {{rowPartus.aup_k5_hasil||"-"}}
                      </h6>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="wrap_line">
                <h3 class="text-uppercase">KIE</h3>
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th width="20">No</th>
                      <th width="25%">Tanggal</th>
                      <th width="25%">Materi</th>
                      <th width="25%">Pelaksana</th>
                      <th width="25%">Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in (rowPartus.aup_kie||[])" :key="k" style="
                          text-align: center;
                          /* margin-top: 31px; */
                      ">
                      <td>{{k+1}}</td>
                      <td>
                          {{rowPartus.aup_kie[k]['date'] || "-"}}
                      </td>
                      <td>
                        {{getConfigDynamic(Config.mr.partusMateri,rowPartus.aup_kie[k]['materi'])||"-"}}
                      </td>
                      <td>
                        {{rowPartus.aup_kie[k]['pelaksana'] || "-"}}        
                      </td>
                      <td>
                        {{rowPartus.aup_kie[k]['keterangan'] || "-"}}        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
            </div>
          </div>
        </template>

        </div>
        <!-- /content area -->
        <!-- Footer -->
        <!-- /footer -->
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

const moment = require('moment')
export default{
    extends: GlobalVue, 
    components: {
      Datepicker,
      VueTimepicker
    },   
    computed: {
        isParent(){
            return this.$parent
        },
        isDetail(){
            return this.$route.query.isDetailForm
        },
        now(){
            return moment().format('DD MMM YYYY')
        },
    },
    data(){
        return {
            rowPartus: {},
            mrValidationPartus: {},
        }
    },
    methods: {
        autoFillTime(val,k){
            if(this.user.levelId == this.uBidan){
              const formattedTime = moment(val, "HH:mm").format("HH:mm")
              if(formattedTime != "Invalid date"){
                  if(k == 0){
                      for (let i = 0; i < (this.rowPartus.aup_k5_data||[]).length; i++) {
                          if(i < ((this.rowPartus.aup_k5_data||[]).length) - 1){
                              let originalTime = this.rowPartus.aup_k5_data[i]['time']
                              let format = "HH:mm"
                              let originalMoment = moment(originalTime, format)
                              let newMoment = originalMoment.clone().add(30, "minutes")
                              let newTime = newMoment.format(format)
                              this.rowPartus.aup_k5_data[i+1]['time'] = newTime
                          }
                      }
                    
                  }
              } 
            }else{
              const formattedTime = moment(val, "HH:mm").format("HH:mm")
              if(formattedTime != "Invalid date"){
                  if(k == 0){
                      for (let i = 0; i < (this.rowPartus.aup_k5_data||[]).length; i++) {
                          if(i < ((this.rowPartus.aup_k5_data||[]).length) - 1){
                              let originalTime = this.rowPartus.aup_k5_data[i]['time']
                              let format = "HH:mm"
                              let originalMoment = moment(originalTime, format)
                              let newMoment = originalMoment.clone().add(15, "minutes")
                              let newTime = newMoment.format(format)
                              this.rowPartus.aup_k5_data[i+1]['time'] = newTime
                          }
                      }
                    
                  }
              }
            }
        },
        addKIE(){
            this.rowPartus.aup_kie.push({
                date : moment().format('YYYY-MM-DD'),
                materi : null,
                pelaksana : null,
                keterangan : null,
            })
        },
        apiGetPartus(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-partus',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })

        },

        toValidate(val){
            return {...val}
        },
        getTimeF(){
            this.jamPindah = moment().format('HH:mm')
        },
           
        autoSave: _.debounce(function (data) {
          data.type = 'auto-save-data-partus'
          Gen.apiRest(
            "/do/" + 'UGDTindakLanjut', {
              data: data
            },
            "POST"
          )
        }, 1000),

        doSubmit(){
            this.$refs['VForm'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})

                            },500)
                        }
                    })
                }
                        
                if (success) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            
                            let data = this.rowPartus
                            data.type = 'submit-data-partus'
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },

    },
    mounted() {
        this.apiGetPartus()
        setInterval(()=>{
            this.getTimeF()
        },1000)
    },
    watch: {
      rowPartus: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
    }
}

</script>

<style>
    .input-cell .vs__dropdown-toggle{
      border : 0px !important;
    }
</style>
