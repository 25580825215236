<template>
  <div class="content">
    <div class="card">
      <template v-if="!isDetail">
        <validation-observer ref="VForm">
          <b-form @submit.prevent="doSubmit" class="card">
            <div class="form-underlying card-body">
              <div class="wrap_line">
                <h3> <span>Identifikasi Bayi</span></h3>
                <div class="mb-3">
                  <table class="table table-bordered">
                    <tr>
                      <td width="25%">
                        <div class="result_tab">
                          <h4>Nama Ibu</h4>
                          <p>{{rowPasien.ap_fullname||"-"}}</p>
                        </div>
                      </td>
                      <td width="25%">
                        <div class="result_tab">
                          <h4>No. RM Ibu</h4>
                          <p>{{rowPasien.ap_code||"-"}}</p>
                        </div>
                      </td>
                      <td width="25%">
                        <div class="result_tab">
                          <h4>Dokter/Bidan Penolong</h4>
                          <p>{{rowReg.nama_perawat||"-"}}</p>
                        </div>
                      </td>
                      <td width="25%">
                        <div class="result_tab">
                          <h4>Nama Ayah</h4>
                          <b-form-input v-model="rowBBL.aub_father_name" type="text" class="form-control form-control-sm"
                            placeholder="e.g. Rahmat Wijayana" />

                          <VValidate name="Nama Ayah" v-model="rowBBL.aub_father_name"
                            :rules="toValidate(mrValidationBBL.aub_father_name)" />
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="row gx-4">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="form-group">
                          <label for="">No. Peneng<strong class="text-danger">*</strong></label>
                          <b-form-input v-model="rowBBL.aub_no_peneng" type="text"
                            class="form-control form-control-sm" />
                          <VValidate name="No. Peneng" v-model="rowBBL.aub_no_peneng"
                            :rules="toValidate(mrValidationBBL.aub_no_peneng)" />
                        </div>
                      </div>
                      <div class="col-md-7">
                        <div class="form-group">
                          <label for="">Nama Pemberi<strong class="text-danger">*</strong></label>
                          <b-form-input v-model="rowBBL.aub_nama_pemberi" type="text"
                            class="form-control form-control-sm" />
                          <VValidate name="Nama Pemberi" v-model="rowBBL.aub_nama_pemberi"
                            :rules="toValidate(mrValidationBBL.aub_nama_pemberi)" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">TTD Pemberi<strong class="text-danger">*</strong></label>
                          <b-form-input v-model="rowBBL.aub_ttd_pemberi" type="text"
                            class="form-control form-control-sm" />
                          <VValidate name="TTD Pemberi" v-model="rowBBL.aub_ttd_pemberi"
                            :rules="toValidate(mrValidationBBL.aub_ttd_pemberi)" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 border-left">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Tgl. Lahir Bayi <strong class="text-danger">*</strong></label>
                          <div class="input-group mb-3">
                            <datepicker input-class="form-control transparent" placeholder="Pilih Tanggal"
                              class="my-datepicker" calendar-class="my-datepicker_calendar"
                              v-model="rowBBL.aub_tgl_lahir">
                            </datepicker>
                            <div class="input-group-append calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                            </div>
                          </div>
                        </div>
                        <VValidate name="Tgl. Lahir Bayi" v-model="rowBBL.aub_tgl_lahir"
                          :rules="toValidate(mrValidationBBL.aub_tgl_lahir)" />
                      </div>
                      
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Jam<strong class="text-danger">*</strong></label>

                          <vue-timepicker format="HH:mm" input-class="form-control custom-timepicker"
                            v-model="rowBBL.aub_jam_lahir">
                          </vue-timepicker>
                        </div>
                      </div>

                      <div class="col-md-5">
                        <div class="form-group">
                          <label for="">Jenis Kelamin <strong class="text-danger">*</strong></label>
                          <b-form-radio-group :options="Config.mr.hdJK" v-model="rowBBL.aub_jk" />
                          <VValidate name="Jenis Kelamin" v-model="rowBBL.aub_jk"
                            :rules="toValidate(mrValidationBBL.aub_jk)" />
                        </div>
                      </div>  
                    </div>
                    
                    <div class="w-100"></div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="bayiWarnaKulit">Warna Kulit <strong class="text-danger">*</strong></label>
                        <b-form-input v-model="rowBBL.aub_warna_kulit" type="text" class="form-control form-control-sm" />
                        <VValidate name="Warna Kulit" v-model="rowBBL.aub_warna_kulit"
                          :rules="toValidate(mrValidationBBL.aub_warna_kulit)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mt-3 mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Cap Ibu & Bayi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-3">
                      <label for="">Cap Ibu Jari Tangan Ibu Bayi <strong class="text-danger">*</strong></label>
                      <Uploader v-model="rowBBL.aub_cap_jari" isDocument type="docimagewithdocx" />
                      <VValidate name="Cap Ibu Jari Tangan Ibu Bayi" v-model="rowBBL.aub_cap_jari"
                        :rules="toValidate(mrValidationBBL.aub_cap_jari)" />
                    </div>

                    <div class="col-md-3">
                      <label for="">Cap Kaki Kiri Bayi <strong class="text-danger">*</strong></label>
                      <Uploader v-model="rowBBL.aub_cap_kaki_kiri" isDocument type="docimagewithdocx" />
                      <VValidate name="Cap Kaki Kiri Bayi " v-model="rowBBL.aub_cap_kaki_kiri"
                        :rules="toValidate(mrValidationBBL.aub_cap_kaki_kiri)" />
                    </div>

                    <div class="col-md-3">
                      <label for="">Cap Kaki Kanan Bayi <strong class="text-danger">*</strong></label>
                      <Uploader v-model="rowBBL.aub_cap_kaki_kanan" isDocument type="docimagewithdocx" />
                      <VValidate name="Cap Kaki Kanan Bayi " v-model="rowBBL.aub_cap_kaki_kanan"
                        :rules="toValidate(mrValidationBBL.aub_cap_kaki_kanan)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="wrap_line">
              <h3> <span>Kondisi Bayi</span></h3>
              <div class="card mb-2">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Status Praesens</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="bayiBeratBadan">Umur Bayi <strong class="text-danger">*</strong>
                          <small class="text-secondary">(Umur Bayi Ketika
                            Pengisian)</small></label>
                        
                        <div class="input-group">
                          <b-form-input :formatter="numberOnly" v-model="rowBBL.aub_umur" type="text" class="form-control" id="bayiBeratBadan" placeholder="e.g. 12 hari" />
                          <div class="input-group-append"><span class="input-group-text">Hari</span></div>
                        </div>
                          
                        <VValidate name="Umur" v-model="rowBBL.aub_umur"
                          :rules="toValidate(mrValidationBBL.aub_umur)" />
                      </div>

                    </div>
                    <div class="col-md-3 col-lg-2">
                      <div class="form-group">
                        <label for="bayiBeratBadan">Berat Badan <strong class="text-danger">*</strong></label>
                        <div class="input-group">
                          <b-form-input :formatter="numberOnly" v-model="rowBBL.aub_bb" type="text" class="form-control" id="bayiBeratBadan" placeholder="e.g. 3.97" />
                          <div class="input-group-append"><span class="input-group-text">gram</span></div>
                        </div>
                          
                        <VValidate name="Berat Badan" v-model="rowBBL.aub_bb"
                          :rules="toValidate(mrValidationBBL.aub_bb)" />
                      </div>
                    </div>

                    <div class="col-md-3 col-lg-2">
                      <div class="form-group">
                        <label for="bayiPanjang">Panjang <strong class="text-danger">*</strong></label>
                        <div class="input-group">
                          <b-form-input :formatter="numberOnly" v-model="rowBBL.aub_panjang" type="text" class="form-control" id="bayiBeratBadan" placeholder="e.g. 75" />
                          <div class="input-group-append"><span class="input-group-text">cm</span>
                          </div>
                        </div>

                        <VValidate name="Berat Badan" v-model="rowBBL.aub_panjang"
                          :rules="toValidate(mrValidationBBL.aub_panjang)" />
                      </div>
                    </div>

                    <div class="col-md-3 col-lg-2">
                      <div class="form-group">
                        <label>Nadi<small class="txt_mandatory">*</small></label>
                        <div class="input-group">
                          <b-form-input :formatter="numberOnly" v-model="rowBBL.aub_nadi" type="text" class="form-control" id="bayiBeratBadan" placeholder="" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                        </div>
                        <VValidate name="Nadi" v-model="rowBBL.aub_nadi"
                        :rules="toValidate(mrValidationBBL.aub_nadi)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-2">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuKesan">Kesan</label>
                        <b-form-input v-model="rowBBL.aub_kesan" type="text" class="form-control" id="kuKesan" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuPergerakan">Pergerakan</label>
                        <b-form-input v-model="rowBBL.aub_pergerakan" type="text" class="form-control" id="kuPergerakan" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuWarna">Warna</label>
                        <b-form-input v-model="rowBBL.aub_warna" type="text" class="form-control" id="kuWarna" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuSuara">Suara</label>
                        <b-form-input v-model="rowBBL.aub_suara" type="text" class="form-control" id="kuSuara" />                
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuPernafasan">Pernafasan</label>
                        <b-form-input v-model="rowBBL.aub_pernafasan" type="text" class="form-control" id="kuPernafasan" />                
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuTonus">Tonus</label>
                        <b-form-input v-model="rowBBL.aub_tonus" type="text" class="form-control" id="kuTonus" />                
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuSikap">Sikap</label>
                        <b-form-input v-model="rowBBL.aub_sikap" type="text" class="form-control" id="kuSikap" />                
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                      <label for="kuReflekMono">Reflek Moro</label>
                        <b-form-input v-model="rowBBL.aub_reflek_moro" type="text" class="form-control" id="kuReflekMono" />   
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuMemegang">Memegang</label>
                        <b-form-input v-model="rowBBL.aub_memegang" type="text" class="form-control" id="kuMemegang" />   
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuMengenyut">Mengenyut</label>
                        <b-form-input v-model="rowBBL.aub_mengenyut" type="text" class="form-control" id="kuMengenyut" /> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-2">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Kepala</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuBentuk">Bentuk</label>
                        <b-form-input v-model="rowBBL.aub_kepala_bentuk" type="text" class="form-control" id="kuBentuk" /> 
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuSuturae">Suturae</label>
                        <b-form-input v-model="rowBBL.aub_kepala_suturae" type="text" class="form-control" id="kuSuturae" /> 
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuFontanel">Fontanel</label>
                        <b-form-input v-model="rowBBL.aub_kepala_fontanel" type="text" class="form-control" id="kuFontanel" /> 
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuCaputSuccedaenum">Caput Succedaenum</label>
                        <b-form-input v-model="rowBBL.aub_kepala_caput" type="text" class="form-control" id="kuCaputSuccedaenum" /> 
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuSeptalHemotama">Septal Hemotama</label>
                        <b-form-input v-model="rowBBL.aub_kepala_septal" type="text" class="form-control" id="kuSeptalHemotama" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuLingkaran">Lingkaran</label>
                        <b-form-input v-model="rowBBL.aub_kepala_lingkaran" type="text" class="form-control" id="kuLingkaran" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuMata">MATA</label>
                        <b-form-input v-model="rowBBL.aub_kepala_mata" type="text" class="form-control" id="kuMata" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuTelinga">Telinga</label>
                        <b-form-input v-model="rowBBL.aub_kepala_telinga" type="text" class="form-control" id="kuTelinga" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuHidung">Hidung</label>
                        <b-form-input v-model="rowBBL.aub_kepala_hidung" type="text" class="form-control" id="kuHidung" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuMulut">Mulut</label>
                        <b-form-input v-model="rowBBL.aub_kepala_mulut" type="text" class="form-control" id="kuMulut" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuBibir">Bibir</label>
                        <b-form-input v-model="rowBBL.aub_kepala_bibir" type="text" class="form-control" id="kuBibir" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuLidah">Lidah</label>
                        <b-form-input v-model="rowBBL.aub_kepala_lidah" type="text" class="form-control" id="kuLidah" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuRambut">Rambut</label>
                        <b-form-input v-model="rowBBL.aub_kepala_rambut" type="text" class="form-control" id="kuRambut" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="kuPalatum">Palatum</label>
                        <b-form-input v-model="rowBBL.aub_kepala_palatum" type="text" class="form-control" id="kuPalatum" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-2">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Leher</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="leBentuk">Bentuk</label>
                        <b-form-input v-model="rowBBL.aub_leher_bentuk" type="text" class="form-control" id="leBentuk" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="leKelenjar">Kelenjar</label>
                        <b-form-input v-model="rowBBL.aub_leher_kelenjar" type="text" class="form-control" id="leKelenjar" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Dada</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="Pemeriksaan">Pemeriksaan</label>
                            <b-form-textarea v-model="rowBBL.aub_dada_pemeriksaan" type="text" class="form-control" id="Pemeriksaan" />
                          </div>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="paru">Paru-Paru</label>
                            <b-form-input v-model="rowBBL.aub_dada_paru" type="text" class="form-control" id="paru" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="daJantung">Jantung</label>
                            <b-form-input v-model="rowBBL.aub_dada_jantung" type="text" class="form-control" id="daJantung" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Perut</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="ppPemeriksaan">Pemeriksaan</label>
                            <b-form-textarea v-model="rowBBL.aub_perut_pemeriksaan" type="text" class="form-control" id="ppPemeriksaan" />
                          </div>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="daTaliPusat">Tali Pusat</label>
                            <b-form-input v-model="rowBBL.aub_perut_tali_pusat" type="text" class="form-control" id="daTaliPusat" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="daLainnya">Lainnya</label>
                            <b-form-input v-model="rowBBL.aub_perut_lainnya" type="text" class="form-control" id="daLainnya" />
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="kbPersendian">Persendian</label>
                    <b-form-input v-model="rowBBL.aub_persendian" type="text" class="form-control" id="kbPersendian" />
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="kbTlPunggung">Tulang Punggung</label>
                    <b-form-input v-model="rowBBL.aub_tulang_punggung" type="text" class="form-control" id="kbTlPunggung" />
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="kbAlatKelamin">Alat Kelamin</label>
                    <b-form-input v-model="rowBBL.aub_alat_kelamin" type="text" class="form-control" id="kbAlatKelamin" />
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="kbReflekYonusLeher">Reflek Yonus Leher</label>
                    <b-form-input v-model="rowBBL.aub_reflek_yonus_leher" type="text" class="form-control" id="kbReflekYonusLeher" />
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="kbReflekAnus">Anus Rektum</label>
                    <b-form-input v-model="rowBBL.aub_anus_rektum" type="text" class="form-control" id="kbReflekAnus" />
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <div class="form-group">
                    <div class="mb-2">
                      <label for="kbGerak">Kulit</label>
                      <v-select placeholder="Pilih Item" v-model="rowBBL.aub_kulit"
                          :options="Config.mr.bblKulit" label="text" :clearable="true"
                          :reduce="v=>v.value"></v-select>
                    </div>
                    <template v-if="rowBBL.aub_kulit == 'L'">
                      <b-form-textarea v-model="rowBBL.aub_kulit_lainnya" name="" id="" rows="2" class="form-control"></b-form-textarea>
                      <VValidate name="Kulit Lainnya" v-model="rowBBL.aub_kulit_lainnya"
                        :rules="{required : 1, min:2, max: 256}" />
                    </template>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <div class="form-group">
                    <div class="mb-2">
                      <label for="kbGerak">Anggota Gerak</label>
                      <v-select placeholder="Pilih Item" v-model="rowBBL.aub_anggota_gerak"
                          :options="Config.mr.bblGerak" label="text" :clearable="true"
                          :reduce="v=>v.value"></v-select>
                    </div>
                    <template v-if="rowBBL.aub_anggota_gerak == 'L'">
                      <b-form-textarea v-model="rowBBL.aub_anggota_gerak_lainnya" name="" id="" rows="2" class="form-control"></b-form-textarea>
                      <VValidate name="Anggota Gerak Lainnya" v-model="rowBBL.aub_anggota_gerak_lainnya"
                        :rules="{required : 1, min:2, max: 256}" />
                    </template>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="">Kelainan Lainnya</label>
                    <b-form-textarea v-model="rowBBL.aub_kelainan_lainnya" name="" id="" cols="30" rows="2" class="form-control"></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="wrap_line">
              <h3><span>Ikhtisar</span></h3>
              <div class="row">
                <div class="col-md-6 col-lg-5">
                  <div class="form-group"><label for="ikhtisar1">Resume</label>
                  <b-form-textarea v-model="rowBBL.aub_ikhtisar_resume" name="ikthisar1" id="ikhtisar1"
                      rows="3" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-6 col-lg-5">
                  <div class="form-group"><label for="ikhtisar2">Ikhtisar Persalinan</label>
                  <b-form-textarea v-model="rowBBL.aub_ikhtisar_persalinan" name="ikthisar2"
                      id="ikhtisar2" rows="3" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-6 col-lg-5">
                  <div class="form-group"><label for="ikhtisar3">Ibu</label>
                  <b-form-textarea v-model="rowBBL.aub_ikhtisar_ibu" name="ikthisar3" id="ikhtisar3"
                      rows="3" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-6 col-lg-5">
                  <div class="form-group"><label for="ikhtisar4">Anak</label>
                  <b-form-textarea v-model="rowBBL.aub_ikhtisar_anak" name="ikthisar4" id="ikhtisar4"
                      rows="3" class="form-control"></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
      
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
      
      <template v-else>
        <div class="form-underlying card-body">
          <div class="wrap_line">
            <h3> <span>Identifikasi Bayi</span></h3>
            <div class="mb-3">
              <table class="table table-bordered">
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Nama Ibu</h4>
                      <p>{{rowPasien.ap_fullname||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>No. RM Ibu</h4>
                      <p>{{rowPasien.ap_code||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Dokter/Bidan Penolong</h4>
                      <p>{{rowReg.nama_perawat||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Nama Ayah</h4>
                      <p>{{rowReg.aub_father_name||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>No. Peneng</h4>
                      <p>{{rowBBL.aub_no_peneng||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Nama Pemberi</h4>
                      <p>{{rowBBL.aub_nama_pemberi||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>TTD Pemberi</h4>
                      <p>{{rowBBL.aub_ttd_pemberi||"-"}}</p>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tgl. Lahir Bayi </h4>
                      <p v-if="rowBBL.aub_ttd_pemberi">{{rowBBL.aub_ttd_pemberi | moment("DD MMMM YYYY")}}</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jam</h4>
                      <p>{{rowBBL.aub_jam_lahir||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jenis Kelamin </h4>
                      <p>{{getConfigDynamic(Config.mr.hdJK,rowBBL.aub_jk)||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                    <h4 for="bayiWarnaKulit">Warna Kulit</h4>
                    <p>{{rowBBL.aub_warna_kulit||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <!-- <div class="row gx-4">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group">
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="form-group">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8 border-left">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                    </div>
                  </div>
                  
                  <div class="col-md-2">
                    <div class="form-group">
                    </div>
                  </div>
                  
                  <div class="col-md-5">
                    <div class="form-group">
                    </div>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-3">
                  <div class="form-group">
                  </div>
                </div>
              </div>
            </div> -->

            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Cap Ibu & Bayi</h6>
              </div>
              <table class="table table-bordered table-sm">
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Cap Ibu Jari Tangan Ibu Bayi</h4>
                      <a v-if="rowBBL.aub_cap_jari" :href="uploader(rowBBL.aub_cap_jari)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh File</span></a>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Cap Kaki Kiri Bayi</h4>
                      <a v-if="rowBBL.aub_cap_kaki_kiri" :href="uploader(rowBBL.aub_cap_kaki_kiri)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh File</span></a>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Cap Kaki Kanan Bayi</h4>
                      <a v-if="rowBBL.aub_cap_kaki_kanan" :href="uploader(rowBBL.aub_cap_kaki_kanan)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh File</span></a>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td width="25%"></td>
                </tr>
              </table>
              <!-- <div class="card-body p-3">
                <div class="row">
                  <div class="col-md-3">
                    <label for="">Cap Ibu Jari Tangan Ibu Bayi</label>
                    <a v-if="rowBBL.aub_cap_jari" :href="uploader(rowBBL.aub_cap_jari)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh File</span></a>
                    <div v-else> - </div>
                  </div>

                  <div class="col-md-3">
                    <label for="">Cap Kaki Kiri Bayi</label>
                    <a v-if="rowBBL.aub_cap_kaki_kiri" :href="uploader(rowBBL.aub_cap_kaki_kiri)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh File</span></a>
                    <div v-else> - </div>
                  </div>

                  <div class="col-md-3">
                    <label for="">Cap Kaki Kanan Bayi</label>
                    <a v-if="rowBBL.aub_cap_kaki_kanan" :href="uploader(rowBBL.aub_cap_kaki_kanan)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh File</span></a>
                    <div v-else> - </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <div class="wrap_line">
            <h3> <span>Kondisi Bayi</span></h3>
            <div class="card mb-2">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Status Praesens</h6>
              </div>
              <table class="table table-bordered table-sm">
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Umur Bayi
                      <small class="text-secondary">(Umur Bayi Ketika
                        Pengisian)</small></h4>
                      <p>{{rowBBL.aub_umur||"-"}} {{rowBBL.aub_umur?"hari":""}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Berat Badan</h4>
                      <p>{{rowBBL.aub_bb||"-"}} {{rowBBL.aub_bb?"gram":""}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Panjang</h4>
                      <p>{{rowBBL.aub_panjang||"-"}} {{rowBBL.aub_panjang?"cm":""}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Nadi</h4>
                      <p>{{rowBBL.aub_nadi||"-"}} {{rowBBL.aub_nadi?"x/mnt":""}}</p>
                    </div>
                  </td>
                </tr>
              </table>
              <!-- <div class="card-body p-3">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="bayiBeratBadan">Umur Bayi
                      <small class="text-secondary">(Umur Bayi Ketika
                        Pengisian)</small></label>
                      <p>{{rowBBL.aub_umur||"-"}} {{rowBBL.aub_umur?"hari":""}}</p>
                    </div>
  
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <div class="form-group">
                      <label for="bayiBeratBadan">Berat Badan</label>
                      <p>{{rowBBL.aub_bb||"-"}} {{rowBBL.aub_bb?"gram":""}}</p>
                    </div>
                  </div>
  
                  <div class="col-md-3 col-lg-2">
                    <div class="form-group">
                      <label for="bayiPanjang">Panjang</label>
                      <p>{{rowBBL.aub_panjang||"-"}} {{rowBBL.aub_panjang?"cm":""}}</p>
                    </div>
                  </div>
  
                  <div class="col-md-3 col-lg-2">
                    <div class="form-group">
                      <label>Nadi</label>
                      <p>{{rowBBL.aub_nadi||"-"}} {{rowBBL.aub_nadi?"x/mnt":""}}</p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="card mb-2">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
              </div>
              <table class="table table-sm table-bordered">
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Kesan</h4>
                      <p>{{rowBBL.aub_kesan||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Pergerakan</h4>
                      <p>{{rowBBL.aub_pergerakan||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Warna</h4>
                      <p>{{rowBBL.aub_warna||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Suara</h4>
                      <p>{{rowBBL.aub_suara||"-"}}</p>  
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Pernafasan</h4>
                      <p>{{rowBBL.aub_pernafasan||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Tonus</h4>
                      <p>{{rowBBL.aub_tonus||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Sikap</h4>
                      <p>{{rowBBL.aub_sikap||"-"}}</p> 
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Reflek Moro</h4>
                      <p>{{rowBBL.aub_reflek_moro||"-"}}</p> 
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Memegang</h4>
                      <p>{{rowBBL.aub_memegang||"-"}}</p> 
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Mengenyut</h4>
                      <p>{{rowBBL.aub_mengenyut||"-"}}</p>   
                    </div>
                  </td>
                  <td width="25%"></td>
                  <td width="25%"></td>
                </tr>
              </table>
              <!-- <div class="card-body p-3">
                <div class="row">
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Kesan</label>
                      <p>{{rowBBL.aub_kesan||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Pergerakan</label>
                      <p>{{rowBBL.aub_pergerakan||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Warna</label>
                      <p>{{rowBBL.aub_warna||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Suara</label>
                      <p>{{rowBBL.aub_suara||"-"}}</p>            
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Pernafasan</label>
                      <p>{{rowBBL.aub_pernafasan||"-"}}</p>                          
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Tonus</label>
                      <p>{{rowBBL.aub_tonus||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Sikap</label>
                      <p>{{rowBBL.aub_sikap||"-"}}</p>          
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                    <label>Reflek Moro</label>
                      <p>{{rowBBL.aub_reflek_moro||"-"}}</p>             
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Memegang</label>
                      <p>{{rowBBL.aub_memegang||"-"}}</p>               
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Mengenyut</label>
                      <p>{{rowBBL.aub_mengenyut||"-"}}</p>               
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="card mb-2">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Kepala</h6>
              </div>
              <table class="table table-bordered table-sm">
                <tr>
                  <td width="25%">
                     <div class="result_tab">
                      <h4>Bentuk</h4>
                      <p>{{rowBBL.aub_kepala_bentuk||"-"}}</p>   
                     </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Suturae</h4>
                      <p>{{rowBBL.aub_kepala_suturae||"-"}}</p>                      
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Fontanel</h4>
                      <p>{{rowBBL.aub_kepala_fontanel||"-"}}</p>                        
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Caput Succedaenum</h4>
                      <p>{{rowBBL.aub_kepala_caput||"-"}}</p>                        
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Septal Hemotama</h4>
                      <p>{{rowBBL.aub_kepala_septal||"-"}}</p>                       
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Lingkaran</h4>
                      <p>{{rowBBL.aub_kepala_lingkaran||"-"}}</p>                        
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>MATA</h4>
                      <p>{{rowBBL.aub_kepala_mata||"-"}}</p>                      
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Telinga</h4>
                      <p>{{rowBBL.aub_kepala_telinga||"-"}}</p>                      
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Hidung</h4>
                      <p>{{rowBBL.aub_kepala_hidung||"-"}}</p>                      
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Mulut</h4>
                      <p>{{rowBBL.aub_kepala_mulut||"-"}}</p>                      
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Bibir</h4>
                      <p>{{rowBBL.aub_kepala_bibir||"-"}}</p>                      
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Lidah</h4>
                      <p>{{rowBBL.aub_kepala_lidah||"-"}}</p>                      
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Rambut</h4>
                      <p>{{rowBBL.aub_kepala_rambut||"-"}}</p>                      
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Palatum</h4>
                      <p>{{rowBBL.aub_kepala_palatum||"-"}}</p>                      
                    </div>
                  </td>
                  <td width="25%"></td>
                  <td width="25%"></td>
                </tr>
              </table>
              <!-- <div class="card-body p-3">
                <div class="row">
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuBentuk">Bentuk</label>
                      <p>{{rowBBL.aub_kepala_bentuk||"-"}}</p>               
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuSuturae">Suturae</label>
                      <p>{{rowBBL.aub_kepala_suturae||"-"}}</p>               
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuFontanel">Fontanel</label>
                      <p>{{rowBBL.aub_kepala_fontanel||"-"}}</p>               
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuCaputSuccedaenum">Caput Succedaenum</label>
                      <p>{{rowBBL.aub_kepala_caput||"-"}}</p>               
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuSeptalHemotama">Septal Hemotama</label>
                      <p>{{rowBBL.aub_kepala_septal||"-"}}</p>               
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuLingkaran">Lingkaran</label>
                      <p>{{rowBBL.aub_kepala_lingkaran||"-"}}</p>               
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuMata">MATA</label>
                      <p>{{rowBBL.aub_kepala_mata||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuTelinga">Telinga</label>
                      <p>{{rowBBL.aub_kepala_telinga||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuHidung">Hidung</label>
                      <p>{{rowBBL.aub_kepala_hidung||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuMulut">Mulut</label>
                      <p>{{rowBBL.aub_kepala_mulut||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuBibir">Bibir</label>
                      <p>{{rowBBL.aub_kepala_bibir||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuLidah">Lidah</label>
                      <p>{{rowBBL.aub_kepala_lidah||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuRambut">Rambut</label>
                      <p>{{rowBBL.aub_kepala_rambut||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="kuPalatum">Palatum</label>
                      <p>{{rowBBL.aub_kepala_palatum||"-"}}</p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="card mb-2">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Leher</h6>
              </div>
              <table class="table table-bordered table-sm">
                <tr>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Bentuk</h4>
                      <p>{{rowBBL.aub_leher_bentuk||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%">
                    <div class="result_tab">
                      <h4>Kelenjar</h4>
                      <p>{{rowBBL.aub_leher_kelenjar||"-"}}</p>
                    </div>
                  </td>
                  <td width="25%"></td>
                  <td width="25%"></td>
                </tr>
              </table>
              <!-- <div class="card-body p-3">
                <div class="row">
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="leBentuk">Bentuk</label>
                      <p>{{rowBBL.aub_leher_bentuk||"-"}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label for="leKelenjar">Kelenjar</label>
                      <p>{{rowBBL.aub_leher_kelenjar||"-"}}</p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Dada</h6>
                  </div>
                  <table class="table table-bordered table-sm">
                    <tr>
                      <td colspan="2">
                        <div class="result_tab">
                          <h4>Pemeriksaan</h4>
                          <p>{{rowBBL.aub_dada_pemeriksaan||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Paru-Paru</h4>
                          <p>{{rowBBL.aub_dada_paru||"-"}}</p>
                        </div>
                      </td>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Jantung</h4>
                          <p>{{rowBBL.aub_dada_jantung||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <!-- <div class="card-body p-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="Pemeriksaan">Pemeriksaan</label>
                          <p>{{rowBBL.aub_dada_pemeriksaan||"-"}}</p>
                        </div>
                      </div>
                      <div class="w-100"></div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="paru">Paru-Paru</label>
                          <p>{{rowBBL.aub_dada_paru||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="daJantung">Jantung</label>
                          <p>{{rowBBL.aub_dada_jantung||"-"}}</p>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Perut</h6>
                  </div>
                  <table class="table table-bordered table-sm">
                    <tr>
                      <td colspan="2">
                        <div class="result_tab">
                          <label for="ppPemeriksaan">Pemeriksaan</label>
                          <p>{{rowBBL.aub_perut_pemeriksaan||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%">
                        <div class="result_tab">
                          <label for="daTaliPusat">Tali Pusat</label>
                          <p>{{rowBBL.aub_perut_tali_pusat||"-"}}</p>
                        </div>
                      </td>
                      <td width="50%">
                        <div class="result_tab">
                          <label for="daLainnya">Lainnya</label>
                          <p>{{rowBBL.aub_perut_lainnya||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <!-- <div class="card-body p-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="ppPemeriksaan">Pemeriksaan</label>
                          <p>{{rowBBL.aub_perut_pemeriksaan||"-"}}</p>
                        </div>
                      </div>
                      <div class="w-100"></div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="daTaliPusat">Tali Pusat</label>
                          <p>{{rowBBL.aub_perut_tali_pusat||"-"}}</p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="daLainnya">Lainnya</label>
                          <p>{{rowBBL.aub_perut_lainnya||"-"}}</p>
                        </div>
                        
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <table class="table table-bordered table-sm">
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <label for="kbPersendian">Persendian</label>
                    <p>{{rowBBL.aub_persendian||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <label for="kbTlPunggung">Tulang Punggung</label>
                    <p>{{rowBBL.aub_tulang_punggung||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <label for="kbAlatKelamin">Alat Kelamin</label>
                    <p>{{rowBBL.aub_alat_kelamin||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <label for="kbReflekYonusLeher">Reflek Yonus Leher</label>
                    <p>{{rowBBL.aub_reflek_yonus_leher||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <label for="kbReflekAnus">Anus Rektum</label>
                    <p>{{rowBBL.aub_anus_rektum||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <label for="kbGerak">Kulit</label>
                    <p>{{ rowBBL.aub_kulit == 'L' ? `Lainnya, ${rowBBL.aub_kulit_lainnya||"-"}` : (getConfigDynamic(Config.mr.bblKulit,rowBBL.aub_kulit)||"-")  }}</p>
                    <!-- <p v-if="rowBBL.aub_kulit == 'L'">
                      {{rowBBL.aub_kulit_lainnya||"-"}}
                    </p>
                    <p v-else>
                      {{getConfigDynamic(Config.mr.bblKulit,rowBBL.aub_kulit)||"-"}}
                    </p> -->
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <label for="kbGerak">Anggota Gerak</label>
                    <p>{{ rowBBL.aub_anggota_gerak == 'L' ? `Lainnya, ${rowBBL.aub_anggota_gerak_lainnya||"-"}` : (getConfigDynamic(Config.mr.bblGerak,rowBBL.aub_anggota_gerak)||"-") }}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <label for="">Kelainan Lainnya</label>
                    <p>{{rowBBL.aub_kelainan_lainnya||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
            <!-- <div class="row">
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <label for="kbPersendian">Persendian</label>
                  <p>{{rowBBL.aub_persendian||"-"}}</p>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <label for="kbTlPunggung">Tulang Punggung</label>
                  <p>{{rowBBL.aub_tulang_punggung||"-"}}</p>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <label for="kbAlatKelamin">Alat Kelamin</label>
                  <p>{{rowBBL.aub_alat_kelamin||"-"}}</p>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <label for="kbReflekYonusLeher">Reflek Yonus Leher</label>
                  <p>{{rowBBL.aub_reflek_yonus_leher||"-"}}</p>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <label for="kbReflekAnus">Anus Rektum</label>
                  <p>{{rowBBL.aub_anus_rektum||"-"}}</p>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <div class="mb-2">
                    <label for="kbGerak">Kulit</label>
                    <p v-if="rowBBL.aub_kulit == 'L'">
                      {{rowBBL.aub_kulit_lainnya||"-"}}
                    </p>
                    <p v-else>
                      {{getConfigDynamic(Config.mr.bblKulit,rowBBL.aub_kulit)||"-"}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <div class="mb-2">
                    <label for="kbGerak">Anggota Gerak</label>
                    <p v-if="rowBBL.aub_anggota_gerak == 'L'">
                      {{rowBBL.aub_anggota_gerak_lainnya||"-"}}
                    </p>
                    <p v-else>
                      {{getConfigDynamic(Config.mr.bblGerak,rowBBL.aub_anggota_gerak)||"-"}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="form-group">
                  <label for="">Kelainan Lainnya</label>
                  <p>
                    {{rowBBL.aub_kelainan_lainnya||"-"}}
                  </p>
                </div>
              </div>
            </div> -->
          </div>
  
          <div class="wrap_line">
            <h3><span>Ikhtisar</span></h3>
            <table class="table table-bordered table-sm">
              <tr>
                <td>
                  <div class="result_tab">
                    <label for="ikhtisar1">Resume</label>
                    <p>{{rowBBL.aub_ikhtisar_resume||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <label for="ikhtisar2">Ikhtisar Persalinan</label>
                    <p>{{rowBBL.aub_ikhtisar_persalinan||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <label for="ikhtisar3">Ibu</label>
                    <p>{{rowBBL.aub_ikhtisar_ibu||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <label for="ikhtisar4">Anak</label>
                    <p>{{rowBBL.aub_ikhtisar_anak||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
            <!-- <div class="row">
              <div class="col-md-6 col-lg-5">
                <div class="form-group"><label for="ikhtisar1">Resume</label>
                  <p>
                    {{rowBBL.aub_ikhtisar_resume||"-"}}
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="form-group"><label for="ikhtisar2">Ikhtisar Persalinan</label>
                  <p>
                    {{rowBBL.aub_ikhtisar_persalinan||"-"}}
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="form-group"><label for="ikhtisar3">Ibu</label>
                  <p>
                    {{rowBBL.aub_ikhtisar_ibu||"-"}}
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="form-group"><label for="ikhtisar4">Anak</label>
                  <p>
                    {{rowBBL.aub_ikhtisar_anak||"-"}}
                  </p>
                </div>
              </div>
            </div> -->
          </div>
        </div>

  
        <div class="card-footer">
          <div class="text-right">
            <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
          </div>
        </div>
      </template>

    </div>
  </div>
</template>


<script>
  import $ from 'jquery'
  const _ = global._

  import Gen from '@/libs/Gen.js'
  import GlobalVue from '@/libs/Global.vue'
  const moment = require('moment')
  import Datepicker from 'vuejs-datepicker'
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'

  export default {
    extends: GlobalVue,
    computed: {
      isParent() {
        return this.$parent
      },
      isDetail() {
        return this.$route.query.isDetailForm
      },
      now() {
        return moment().format('DD MMM YYYY')
      },
    },
    components: {
      Datepicker,
      VueTimepicker
    },
    data() {
      return {
        rowBBL: {},
        rowPasien: {},
        rowReg: {},
        mrValidationBBL: {},
        jamPindah: null
      }
    },
    methods: {
      apiGetBBL() {
        this.loadingOverlay = true
        Gen.apiRest('/do/' + 'UGDTindakLanjut', {
          data: {
            type: 'get-data-bbl',
            id: this.$route.params.pageSlug,
          }
        }, 'POST').then(res => {
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          this.loadingOverlay = false
        })

      },
      toValidate(val) {
        return {
          ...val
        }
      },

      autoSave: _.debounce(function (data) {
        data.type = 'auto-save-data-bbl'
        Gen.apiRest(
          "/do/" + 'UGDTindakLanjut', {
            data: data
          },
          "POST"
        )
      }, 1000),

      
      getConfigDynamic(master,value){
          let text = ''
          if(value){
              let index = (master||[]).findIndex(x => x.value == value)
              if(index !== -1){
                  text = master[index]['text']
              }
          }
          return text
      },

      doSubmit() {
        this.$refs['VForm'].validate().then(success => {
          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll(
                    ".label_error:not([style='display: none;'])")
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }
                  if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                }, 500)
              }
            })
          }

          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {

                let data = this.rowBBL
                data.type = 'submit-data-bbl'
                this.loadingOverlay = true
                Gen.apiRest(
                  "/do/" + 'UGDTindakLanjut', {
                    data: data
                  },
                  "POST"
                ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.$router.push({
                      name: 'UGDTindakLanjut',
                      params: {
                        pageSlug: this.$route.params
                          .pageSlug
                      },
                      query: {
                        isDetail: true
                      }
                    })
                  })
                }).catch(err => {
                  this.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })
              }
            })
          }
        })
      }

    },
    mounted() {
      this.apiGetBBL()
    },
    watch: {
      rowBBL: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
    }
  }
</script>