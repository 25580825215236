<template>
    <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit">
            <div class="row mt-2">
                <div class="col-md-12">
                    <div>
                        <label for="">Pilih Kriteria Masuk Isolasi</label>
                        <table class="table table-sm table-bordered">
                            <thead>
                            <tr>
                                <th>Kriteria Pasien Masuk Isolasi Airborne</th>
                                <th>Kriteria Pasien Masuk Isolasi Imunosupresi</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="align-top">
                                <div>
                                    <div class="custom-control custom-radio">
                                    <b-form-radio
                                        @input="inputRadio('airBorne')"
                                        v-model="rowKriteriaIsolasi.auki_airborne"
                                        value="1" :name="'kriteriaAirBone'"
                                        class="form-check-input-styled">
                                        <div>
                                        <strong class="text-uppercase">Suspek TB</strong>
                                        <div class="criteria-content">
                                            <ul>
                                            <li>Klinis</li>
                                            <li>Radiologis</li>
                                            </ul>
                                        </div>
                                        </div>
                                    </b-form-radio>
                                    </div>
                                    
                                    <div class="custom-control custom-radio">
                                    <b-form-radio
                                        @input="inputRadio('airBorne')"
                                        v-model="rowKriteriaIsolasi.auki_airborne"
                                        value="2" :name="'kriteriaAirBone'"
                                        class="form-check-input-styled">
                                    
                                        <div>
                                        <strong class="text-uppercase">Campak</strong>
                                        </div>
                                    </b-form-radio>
                                    </div>
                                    <div class="custom-control custom-radio">
                                    <b-form-radio
                                        @input="inputRadio('airBorne')"
                                        v-model="rowKriteriaIsolasi.auki_airborne"
                                        value="3" :name="'kriteriaAirBone'"
                                        class="form-check-input-styled">
                                    
                                        <div>
                                        <strong class="text-uppercase">Varicella</strong>
                                        </div>
                                    </b-form-radio>

                                    </div>
                                    <div class="custom-control custom-radio">
                                    <b-form-radio
                                        @input="inputRadio('airBorne')"
                                        v-model="rowKriteriaIsolasi.auki_airborne"
                                        value="4" :name="'kriteriaAirBone'"
                                        class="form-check-input-styled">

                                        <div>
                                        <strong class="text-uppercase">Penyakit Lain</strong>
                                        <div class="criteria-content">
                                            <b-form-input v-model="rowKriteriaIsolasi.auki_airborne_text" type="text" class="form-control form-control-sm" />
                                        </div>
                                        </div>
                                    </b-form-radio>

                                    </div>
                                </div>
                                

                                <VValidate 
                                    v-if="rowKriteriaIsolasi.auki_airborne == 4"
                                    name="Kriteria Isolasi Airborne Lainnya" 
                                    v-model="rowKriteriaIsolasi.auki_airborne_text" 
                                    :rules="{required:1,min:2,max:128}"
                                />

                                </td>
                                <td class="align-top">
                                <div>
                                    <div class="custom-control custom-radio">
                                    <b-form-radio
                                        @input="inputRadio('imunoSup')"
                                        v-model="rowKriteriaIsolasi.auki_imuno"
                                        value="1" :name="'kriteriaImuno'"
                                        class="form-check-input-styled">
                                        <div>
                                        <strong class="text-uppercase">HIV</strong>
                                        </div>
                                    </b-form-radio>
                                    </div>
                                    <div class="custom-control custom-radio">
                                    <b-form-radio
                                        @input="inputRadio('imunoSup')"
                                        v-model="rowKriteriaIsolasi.auki_imuno"
                                        value="2" :name="'kriteriaImuno'"
                                        class="form-check-input-styled">

                                        <div>
                                        <strong class="text-uppercase">SLE</strong>
                                        </div>
                                    </b-form-radio>
                                    </div>
                                    <div class="custom-control custom-radio">
                                    <b-form-radio
                                        @input="inputRadio('imunoSup')"
                                        v-model="rowKriteriaIsolasi.auki_imuno"
                                        value="3" :name="'kriteriaImuno'"
                                        class="form-check-input-styled">
                                        <div>
                                        <strong class="text-uppercase">Gangguan Imunologis Lain</strong>
                                        <div class="criteria-content">
                                            <b-form-input v-model="rowKriteriaIsolasi.auki_imuno_text" type="text" class="form-control form-control-sm" />
                                        </div>
                                        </div>
                                    </b-form-radio>
                                    </div>
                                </div>
                                
                                <VValidate 
                                    v-if="rowKriteriaIsolasi.auki_imuno == 3"
                                    name="Kriteria Isolasi Imunosupresi Lainnya" 
                                    v-model="rowKriteriaIsolasi.auki_imuno_text" 
                                    :rules="{required:1,min:2,max:128}"
                                />
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <!--
                        <VValidate 
                            name="Kriteria Isolasi Airborne" 
                            v-model="rowKriteriaIsolasi.auki_airborne" 
                            :rules="toValidate(mrValidationKriteriaIsolasi.auki_airborne)"
                        />
                        
                        <VValidate 
                            name="Kriteria Isolasi Imunosupresi" 
                            v-model="rowKriteriaIsolasi.auki_imuno" 
                            :rules="toValidate(mrValidationKriteriaIsolasi.auki_imuno)"
                        />
                        -->
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="text-right">
                    <button type="button" class="btn" @click="isParent.formModal = false" data-dismiss="modal">Batal</button>
                    <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
                    </button>
                    </div>
                </div>
            </div>
        </b-form>
    </validation-observer>  
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowKriteriaIsolasi: {},
            mrValidationKriteriaIsolasi: {},
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        inputRadio(inp){
            if(inp == 'airBorne'){
                this.rowKriteriaIsolasi.auki_imuno = null
            }
            if(inp == 'imunoSup'){
                this.rowKriteriaIsolasi.auki_airborne = null
            }
        },
        doSubmit(){
            if(!this.rowKriteriaIsolasi.auki_imuno && !this.rowKriteriaIsolasi.auki_airborne){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Kriteria tidak diisi'
                })
            }

            this.$refs['VForm'].validate().then(success => {
                 if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowKriteriaIsolasi
                            data.type = 'submit-data-kriteria-isolasi'

                            this.loadingOverlay = true
                            this.isParent.formModal = false                            
                            
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },

        apiGetKriteriaIsolasi(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-kriteria-isolasi',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetKriteriaIsolasi()
    }
}

</script>

