<template>
    <div class="content">
        <div class="card mb-2">
            <div class="card-header bg_head_panel">
                <div class="d-flex align-items-center">
                <h6 class="card-title font-weight-semibold">Form Transfer Intra RS (Rawat Inap)</h6>
                </div>
            </div>
                
            <template v-if="!isDetail">
                <validation-observer ref="VForm">
                    <b-form @submit.prevent="doSubmit" class="card">
                    <div class="form-underlying card-body p-3">
                    <div class="card mb-2">
                        <div class="card-header p-0" role="tab">
                        <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
                            <div class="d-flex align-items-center">
                            <div class="label_code m-0">
                                <h2>S</h2>
                            </div>
                            <strong class="ml-2">Situation</strong>
                            </div>
                        </a>
                        </div>
                        <div class="wrap_line collapse show" id="formIntra1">
                        <table class="table table-bordered">
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Nama</h4>
                                <p>{{rowPasien.ap_fullname||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>No. Rekam Medis</h4>
                                <p>{{rowPasien.ap_code||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>NIK</h4>
                                <p>{{rowPasien.ap_nik||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Tanggal Lahir</h4>
                                <p>{{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>DPJP</h4>
                                <p>{{rowReg.bu_full_name||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Jam Datang</h4>
                                <p>{{rowIntraRanap.auira_jam_datang||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Diagnosa</h4>
                                <p>{{rowIntraRanap.auira_diagnosa||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Kondisi Saat Ini</h4>
                                <p>{{rowIntraRanap.auira_kondisi_saat_ini||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4></h4>
                                <p></p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Dari Ruang</h4>
                                <p>{{rowIntraRanap.auira_dari_ruang||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Ke Ruang</h4>
                                <p>{{rowIntraRanap.auira_ke_ruang||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Jam Pindah</h4>
                                <p>{{jamPindah||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                        </table>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-header p-0" role="tab">
                        <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
                            <div class="d-flex align-items-center">
                            <div class="label_code m-0">
                                <h2>B</h2>
                            </div>
                            <strong class="ml-2">Background</strong>
                            </div>
                        </a>
                        </div>
                        <div class="wrap_line collapse show" id="formIntra2">
                        <div class="row">
                            <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Intervensi yang Telah Dilakukan<strong class="text-danger">*</strong></label>
                                    <b-form-textarea v-model="rowIntraRanap.auira_background_intervensi" name="" id="" cols="30" rows="3" class="form-control" />
                                    
                                    <VValidate 
                                        name="Intervensi yang Telah Dilakukan" 
                                        v-model="rowIntraRanap.auira_background_intervensi" 
                                        :rules="toValidate(mrValidationIntraRanap.auira_background_intervensi)"
                                    />
                                </div>  
                                </div>
                                <div class="col-md-6">
                                <div class="form-group">
                                    <label for="asuhanPembedahan">Riwayat Pembedahan <strong class="text-danger">*</strong></label>
                                    <b-form-textarea v-model="rowIntraRanap.auira_riwayat_pembedahan" name="asuhanPembedahan" id="asuhanPembedahan" rows="3"
                                    class="form-control form-control-sm" />
                                    
                                    <VValidate 
                                        name="Riwayat Pembedahan" 
                                        v-model="rowIntraRanap.auira_riwayat_pembedahan" 
                                        :rules="toValidate(mrValidationIntraRanap.auira_riwayat_pembedahan)"
                                    />
                                </div>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Obat-obatan yang Diberikan<strong class="text-danger">*</strong></label>
                                    <b-form-textarea v-model="rowIntraRanap.auira_obat_obatan_yang_diberikan" name="" id="" rows="3" class="form-control" />
                                    
                                    <VValidate 
                                        name="Obat-obatan yang Diberikan" 
                                        v-model="rowIntraRanap.auira_obat_obatan_yang_diberikan" 
                                        :rules="toValidate(mrValidationIntraRanap.auira_obat_obatan_yang_diberikan)"
                                    />
                                </div>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Cairan Parenteral yang Diberikan<strong class="text-danger">*</strong></label>
                                    <b-form-textarea v-model="rowIntraRanap.auira_cairan_parental_yang_diberikan" name="" id="" rows="3" class="form-control" />
                                    
                                    <VValidate 
                                        name="Cairan Parenteral yang Diberikan" 
                                        v-model="rowIntraRanap.auira_cairan_parental_yang_diberikan" 
                                        :rules="toValidate(mrValidationIntraRanap.auira_cairan_parental_yang_diberikan)"
                                    />
                                </div>
                                </div>
                                <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Riwayat Alergi<strong class="text-danger">*</strong></label>
                                    <b-form-textarea v-model="rowIntraRanap.auira_riwayat_alergi" name="" id="" rows="3" class="form-control" value="Obat: Amoxicilin, Amlodipin. Lainnya: Dingin" />
                                    
                                    <VValidate 
                                        name="Riwayat Alergi" 
                                        v-model="rowIntraRanap.auira_riwayat_alergi" 
                                        :rules="toValidate(mrValidationIntraRanap.auira_riwayat_alergi)"
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="">Tindakan Invasif</label>
                                <table class="table table-sm table-bordered">
                                <thead>
                                    <tr>
                                    <th width="48">#</th>
                                    <th>Tindakan</th>
                                    <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in (rowIntraRanap.auira_tindakan_invasif||[])" :key="k">
                                    <td>{{k+1}}</td>
                                    <td>
                                        <input type="text" v-model="rowIntraRanap.auira_tindakan_invasif[k]" class="form-control form-control-sm">
                                        <VValidate 
                                            :name="'Tindakan Invasif '+(k+1)" 
                                            v-model="rowIntraRanap.auira_tindakan_invasif[k]" 
                                            :rules="{required : 1}"
                                        />
                                    </td>
                                    <td><a href="javascript:;" class="list-icons-item"
                                        @click="rowIntraRanap.auira_tindakan_invasif.splice(k,1)"
                                        data-toggle="tooltip" data-placement="top" title="Delete"><i
                                            class="icon-bin"></i></a></td>
                                    </tr>

                                    <tr v-if="!(rowIntraRanap.auira_tindakan_invasif||[]).length">
                                    <td colspan="3" class="text-center">
                                        Tidak ada Data
                                    </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                    <td colspan="3" class="text-center">
                                        <a href="javascript:;" @click="rowIntraRanap.auira_tindakan_invasif.push('')" class="btn btn-outline-primary"><i
                                            class="icon-plus2 mr-2"></i>Tambah Tindakan</a>
                                    </td>
                                    </tr>
                                </tfoot>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-header p-0" role="tab">
                        <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
                            <div class="d-flex align-items-center">
                            <div class="label_code m-0">
                                <h2>A</h2>
                            </div>
                            <strong class="ml-2">Assessment</strong>
                            </div>
                        </a>
                        </div>
                        <div class="wrap_line collapse show" id="formIntra3">
                        <div class="card border shadow-0">
                            <div class="bg-light card-header py-2">
                            <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                            </div>
                            <div class="card-body">
                            <table class="table table-bordered">
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Skala Nyeri</h4>
                                    <p>{{rowIntraRanap.auira_skala_nyeri_value||0}} 
                                        <span v-if="rowIntraRanap.auira_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 1 && rowIntraRanap.auira_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 4 && rowIntraRanap.auira_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                    <br />(Numeric Rating Scale)</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Tingkat Kesadaran</h4>
                                    <p>{{rowIntraRanap.auira_skala_nyeri_value||0}} 
                                        <span v-if="rowIntraRanap.auira_skala_nyeri_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 12 && rowIntraRanap.auira_skala_nyeri_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 10 && rowIntraRanap.auira_skala_nyeri_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 7 && rowIntraRanap.auira_skala_nyeri_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 5 && rowIntraRanap.auira_skala_nyeri_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 4 && rowIntraRanap.auira_skala_nyeri_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                        <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                    <br />(GCS)</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Risiko Jatuh</h4>
                                    <p>{{rowIntraRanap.auira_skala_nyeri_value||0}} 
                                        <span v-if="rowIntraRanap.auira_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 7 && rowIntraRanap.auira_skala_nyeri_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                                    <br />(Kajian Humpty Dumpty)</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Tekanan Darah</h4>
                                    <p>{{rowIntraRanap.auira_tekanan_darah_min||"-"}}/{{rowIntraRanap.auira_tekanan_darah_max||"-"}} mmHG</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Nadi</h4>
                                    <p>{{rowIntraRanap.auira_nadi||"-"}}x per menit</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Suhu</h4>
                                    <p>{{rowIntraRanap.auira_suhu||"-"}} &deg;C</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Respiration Rate</h4>
                                    <p>{{rowIntraRanap.auira_respiration||"-"}}x per menit</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>SpO2</h4>
                                    <p>{{rowIntraRanap.auira_spo2||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4></h4>
                                    <p></p>
                                    </div>
                                </td>
                                </tr>
                            </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                            <div class="form-group">
                                <label for="">Pemeriksaan Lab</label>
                                <b-form-textarea v-model="rowIntraRanap.auira_lab" name="" id="" rows="3" class="form-control" />
                            </div>
                            </div>
                            <div class="col-md-5">
                            <div class="form-group">
                                <label for="">Radiologi</label>
                                <b-form-textarea v-model="rowIntraRanap.auira_radiologi" name="" id="" rows="3" class="form-control" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-header p-0" role="tab">
                        <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
                            <div class="d-flex align-items-center">
                            <div class="label_code m-0">
                                <h2>R</h2>
                            </div>
                            <strong class="ml-2">Recommendation</strong>
                            </div>
                        </a>
                        </div>
                        <div class="wrap_line collapse show" id="formIntra4">
                        <div class="row">
                            <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Tindakan yang Perlu Dilanjutkan<strong class="text-danger">*</strong></label>
                                <b-form-textarea v-model="rowIntraRanap.auira_tindakan" name="" id="" rows="3" class="form-control" />
                                <VValidate 
                                    name="Tindakan yang Perlu Dilanjutkan" 
                                    v-model="rowIntraRanap.auira_tindakan" 
                                    :rules="toValidate(mrValidationIntraRanap.auira_tindakan)"
                                />
                            </div>
                            </div>
                            <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Usulan Kolaborasi<strong class="text-danger">*</strong></label>
                                <b-form-textarea v-model="rowIntraRanap.auira_usulan_kolab" name="" id="" rows="3" class="form-control" />
                                <VValidate 
                                    name="Usulan Kolaborasi" 
                                    v-model="rowIntraRanap.auira_usulan_kolab" 
                                    :rules="toValidate(mrValidationIntraRanap.auira_usulan_kolab)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="card-footer">
                    <div class="text-right">
                        <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
                        <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                    </div>
                    </div>
                    </b-form>
                </validation-observer>
            </template>
            <template v-else>
                <div class="form-underlying card-body p-3">
                    <div class="wrap_line" id="formIntra1">
                        <table class="table table-bordered">
                            <tr>
                                <td colspan="3">
                                <div class="d-flex align-items-center">
                                    <div class="label_code m-0">
                                    <h2>S</h2>
                                    </div>
                                    <strong class="ml-2">Situation</strong>
                                </div>
                                </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Nama</h4>
                                <p>{{rowPasien.ap_fullname||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>No. Rekam Medis</h4>
                                <p>{{rowPasien.ap_code||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>NIK</h4>
                                <p>{{rowPasien.ap_nik||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Tanggal Lahir</h4>
                                <p>{{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>DPJP</h4>
                                <p>{{rowReg.bu_full_name||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Jam Datang</h4>
                                <p>{{rowIntraRanap.auira_jam_datang||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Diagnosa</h4>
                                <p>{{rowIntraRanap.auira_diagnosa||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Kondisi Saat Ini</h4>
                                <p>{{rowIntraRanap.auira_kondisi_saat_ini||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4></h4>
                                <p></p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Dari Ruang</h4>
                                <p>{{rowIntraRanap.auira_dari_ruang||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Ke Ruang</h4>
                                <p>{{rowIntraRanap.auira_ke_ruang||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Jam Pindah</h4>
                                <p>{{jamPindah||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                        </table>
                    </div>
                    
                    <div class="wrap_line" id="formIntra2">
                        <table class="table table-sm table-bordered">
                        <tbody><tr>
                            <td colspan="3">
                            <div class="d-flex align-items-center">
                                <div class="label_code m-0">
                                <h2>B</h2>
                                </div>
                                <strong class="ml-2">Background</strong>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Intervensi yang Telah Dilakukan</h4>
                                <p>{{rowIntraRanap.auira_background_intervensi||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Riwayat Pembedahan</h4>
                                <p>{{rowIntraRanap.auira_riwayat_pembedahan||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Obat-obatan yang diberikan</h4>
                                <p>{{rowIntraRanap.auira_obat_obatan_yang_diberikan||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Cairan Parenteral yang Diberikan</h4>
                                <p>{{rowIntraRanap.auira_cairan_parental_yang_diberikan||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Riwayat Alergi</h4>
                                <p>{{rowIntraRanap.auira_riwayat_alergi||"-"}}</p>
                            </div>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="3">
                            <div class="result_tab">
                                <h4>Tindakan Invasif</h4>
                                <ol class="mb-0" v-if="(rowIntraRanap.auira_tindakan_invasif||[]).length">
                                    <li v-for="(v,k) in (rowIntraRanap.auira_tindakan_invasif||[])" :key="k">{{v}}</li>
                                </ol>
                                <span v-else>Tidak ada data</span>
                            </div>
                            </td>
                        </tr>
                        </tbody></table>
                    </div>

                    <div class="wrap_line collapse show" id="formIntra3">
                        <div class="card border shadow-0">
                        <div class="card-header bg-white py-2">
                            <div class="d-flex align-items-center">
                            <div class="label_code m-0">
                                <h2>A</h2>
                            </div>
                            <strong class="ml-2">Assessment</strong>
                            </div>
                        </div>
                        <div class="bg-light card-header py-2">
                            <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Skala Nyeri</h4>
                                    <p>{{rowIntraRanap.auira_skala_nyeri_value||0}} | 
                                        <span v-if="rowIntraRanap.auira_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 1 && rowIntraRanap.auira_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 4 && rowIntraRanap.auira_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                    <br />(Numeric Rating Scale)</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Tingkat Kesadaran</h4>
                                    <p>{{rowIntraRanap.auira_skala_nyeri_value||0}} | 
                                        <span v-if="rowIntraRanap.auira_skala_nyeri_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 12 && rowIntraRanap.auira_skala_nyeri_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 10 && rowIntraRanap.auira_skala_nyeri_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 7 && rowIntraRanap.auira_skala_nyeri_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 5 && rowIntraRanap.auira_skala_nyeri_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 4 && rowIntraRanap.auira_skala_nyeri_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                        <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                    <br />(GCS)</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Risiko Jatuh</h4>
                                    <p>{{rowIntraRanap.auira_skala_nyeri_value||0}} | 
                                        <span v-if="rowIntraRanap.auira_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 7 && rowIntraRanap.auira_skala_nyeri_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                                        <span v-else-if="rowIntraRanap.auira_skala_nyeri_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                                    <br />(Kajian Humpty Dumpty)</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Tekanan Darah</h4>
                                    <p>{{rowIntraRanap.auira_tekanan_darah_min||"-"}}/{{rowIntraRanap.auira_tekanan_darah_max||"-"}} mmHG</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Nadi</h4>
                                    <p>{{rowIntraRanap.auira_nadi||"-"}}x per menit</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Suhu</h4>
                                    <p>{{rowIntraRanap.auira_suhu||"-"}} &deg;C</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Respiration Rate</h4>
                                    <p>{{rowIntraRanap.auira_respiration||"-"}}x per menit</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>SpO2</h4>
                                    <p>{{rowIntraRanap.auira_spo2||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4></h4>
                                    <p></p>
                                    </div>
                                </td>
                                </tr>
                            </table>
                        </div>
                        <div class="card-body">
                            <div class="result_tab">
                            <h4>Radiologi</h4>
                            <p>{{rowIntraRanap.auira_radiologi||"-"}}</p>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="wrap_line collapse show" id="formIntra4">
                        <table class="table table-bordered table-sm">
                        <tbody><tr>
                            <td colspan="2">
                            <div class="d-flex align-items-center">
                                <div class="label_code m-0">
                                <h2>R</h2>
                                </div>
                                <strong class="ml-2">Recommendation</strong>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Tindakan yang Perlu Dilanjutkan</h4>
                                <p>{{rowIntraRanap.auira_tindakan||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Usulan Kolaborasi</h4>
                                <p>{{rowIntraRanap.auira_usulan_kolab||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody></table>
                    </div>

                    </div>
                    <div class="card-footer">
                    <div class="text-right">
                        <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue,    
    computed: {
        isParent(){
            return this.$parent
        },
        isDetail(){
            return this.$route.query.isDetailForm
        },
        now(){
            return moment().format('DD MMM YYYY')
        },
    },
    data(){
        return {
            rowIntraRanap: {},
            rowPasien: {},
            rowReg: {},
            mrValidationIntraRanap: {},
            jamPindah: null
        }
    },
    methods: {
        apiGetIntraRanap(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-intra-ranap',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })

        },
        toValidate(val){
            return {...val}
        },
        getTimeF(){
            this.jamPindah = moment().format('HH:mm')
        },
           
        autoSave: _.debounce(function (data) {
          data.type = 'auto-save-data-intra-ranap'
          Gen.apiRest(
            "/do/" + 'UGDTindakLanjut', {
              data: data
            },
            "POST"
          )
        }, 1000),

        doSubmit(){
            this.$refs['VForm'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                        
                if (success) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            
                            let data = this.rowIntraRanap
                            data.type = 'submit-data-intra-ranap'
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true}})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        }

    },
    mounted() {
        this.apiGetIntraRanap()
        setInterval(()=>{
            this.getTimeF()
        },1000)
    },
    watch: {
      rowIntraRanap: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
    }
}

</script>




