<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <div class="row" v-if="!rowPermintaanDarah.aufpd_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowPermintaanDarah['aufpd_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowPermintaanDarah.aufpd_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowPermintaanDarah.aufpd_is_ttd == 'Digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
                <div class="form-group">
                <label for="">Tanda Tangan Petugas</label>
                <div class="signing-element" data-target-id="ttdPetugas">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPetugas"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdPetugas','aufpd_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdPetugas','aufpd_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 14px;">
                        <div style="padding: 8px;border: 4px solid #000;">
                            <table style="border-collapse: collapse;width: 100%;font-size: 14px;line-height: 1.5;">
                            <tbody><tr>
                                <td colspan="3">
                                <div style="text-align: left;">
                                    <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="max-width: 100%;width: 10%;float: left;" alt="">
                                    <div style="float: left;padding-left: 12px;width: 90%;box-sizing: border-box;font-size: 12px;">
                                    <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 20px;margin-bottom: 2px;">
                                        Rumah Sakit Harapan</h2>
                                    <div>Jl. Panembahan Senopati No. 11 Magelang 56123</div>
                                    <div>Telp. (0293) 364033 - 364035, Fax. (0293) 364037</div>
                                    <div>INDONESIA</div>
                                    </div>
                                    <div style="clear: both;"></div>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="3" style="padding: 8px;">
                                <h2 style="font-size: 16px;text-transform: uppercase;margin: 0;display: inline-block;">Formulir Permintaan Darah</h2>
                                </th>
                            </tr>
                            <tr>
                                <td colspan="3" style="padding: 4px 8px;text-align: justify;">
                                <div>
                                    <table style="border-collapse: collapse;font-size: 14px;line-height: 1.5;">
                                    <tbody><tr>
                                        <td style="padding: 1px 8px;padding-left: 0;">Dokter yang Meminta</td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">
                                        <span>{{rowReg.bu_full_name||"-"}}</span>
                                        <span style="margin-left: 16px;">UPF/Ruang: UGD</span>
                                        <span style="margin-left: 16px;">Kelas: -</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 1px 8px;padding-left: 0;">Nama Pasien</td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">
                                        <span>{{rowPasien.ap_fullname||"-"}}</span>
                                        <span style="margin-left: 16px;">Tgl. Lahir: {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</span>
                                        <span style="margin-left: 16px;">L/P: {{rowPasien.ap_gender == 1 ? "L" : "P"}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 1px 8px;padding-left: 0;">No. RM</td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">
                                        <span>{{rowPasien.ap_code||"-"}}</span>
                                        <span style="margin-left: 16px;">NIK: {{rowPasien.ap_nik||"-"}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 1px 8px;padding-left: 0;">Diagnosa Sementara</td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">
                                        <span>{{rowPermintaanDarah.diagnosa||"-"}}</span>
                                        <span style="margin-left: 16px;">Indikasi Transfusi: 
                                        {{rowPermintaanDarah.aufpd_indikasi||"-"}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">
                                        <span v-if="rowPermintaanDarah.aufpd_is_transfusi">
                                        {{rowPermintaanDarah.aufpd_is_transfusi == 'TP' ? 'Tidak Pernah Transfusi' : 'Pernah Transfusi'}}
                                        </span>
                                        <span v-else> - 
                                        </span>
                                        </td>
                                    </tr>
                                    <!--
                                    <tr>
                                        <td style="padding: 1px 8px;padding-left: 0;vertical-align: top;">Bagi pasien wanita
                                        </td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">Pernah hamil</td>
                                    </tr>
                                    -->
                                    <tr>
                                        <td style="padding: 1px 8px;padding-left: 0;vertical-align: top;">Golongan darah</td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">
                                        {{rowPermintaanDarah.aufpd_golongan_darah||"-"}} 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 1px 8px;padding-left: 0;vertical-align: top;">Jenis darah yang diperlukan</td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">{{rowPermintaanDarah.aufpd_jenis_darah||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 1px 8px;padding-left: 0;vertical-align: top;">Tanggal diperlukan</td>
                                        <td>:</td>
                                        <td style="padding: 1px 8px">{{ rowPermintaanDarah.aufpd_tanggal | moment("DD MMMM YYYY")}}</td>
                                    </tr>
                                    </tbody></table>
                                </div>
                                <div style="margin-top: 16px;">
                                    <h2 style="font-size: 16px;text-transform: uppercase;margin: 0;margin-bottom: 4px;">Telah Terima</h2>
                                    <table width="100%">
                                    <tbody><tr>
                                        <td width="50%">
                                        <table style="border-collapse: collapse;font-size: 14px;line-height: 1.5;">
                                            <tbody><tr>
                                            <td style="padding: 1px 8px;padding-left: 0;">Jumlah</td>
                                            <td>:</td>
                                            <td style="padding: 1px 8px">
                                                <span style="margin-left: 48px;">Kantong</span>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style="padding: 1px 8px;padding-left: 0;">Gol</td>
                                            <td>:</td>
                                            <td style="padding: 1px 8px"></td>
                                            </tr>
                                            <tr>
                                            <td style="padding: 1px 8px;padding-left: 0;">Nama</td>
                                            <td>:</td>
                                            <td style="padding: 1px 8px"></td>
                                            </tr>
                                            <tr>
                                            <td style="padding: 1px 8px;padding-left: 0;">Alamat</td>
                                            <td>:</td>
                                            <td style="padding: 1px 8px"></td>
                                            </tr>
                                            <tr>
                                            <td style="padding: 1px 8px;padding-left: 0;">Tanggal</td>
                                            <td>:</td>
                                            <td style="padding: 1px 8px"></td>
                                            </tr>
                                            <tr>
                                            <td style="padding: 1px 8px;padding-left: 0;">Jam</td>
                                            <td>:</td>
                                            <td style="padding: 1px 8px"></td>
                                            </tr>
                                        </tbody></table>
                                        </td>
                                        <td width="50%">
                                        <table style="border-collapse: collapse;font-size: 14px;line-height: 1.5;">
                                            <tbody><tr>
                                            <td rowspan="4" style="padding: 1px 8px;padding-left: 0;vertical-align: top;">No. Kantong</td>
                                            <td rowspan="4" style="vertical-align: top;">:</td>
                                            <td style="padding: 1px 8px">1</td>
                                            </tr>
                                            <tr>
                                            <td style="padding: 1px 8px">2</td>
                                            </tr>
                                            <tr>
                                            <td style="padding: 1px 8px">3</td>
                                            </tr>
                                            <tr>
                                            <td style="padding: 1px 8px">4</td>
                                            </tr>
                                        </tbody></table>
                                        </td>
                                    </tr>
                                    </tbody></table>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="60%">
                                <span>Tanda tangan penerima</span>
                                <br><br><br><br>
                                </td>
                                <td>
                                <span>Magelang, {{now}}<br>Yang membuat pernyataan,</span>
                                <span v-if="rowPermintaanDarah.aufpd_is_ttd == 'Digital'">
                                <img :src="rowPermintaanDarah.aufpd_ttd ? rowPermintaanDarah.aufpd_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                <br/>
                                </span>
                                <span v-else>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                </span>    

                                ({{user.fullName}})
                                </td>
                            </tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowPermintaanDarah.aufpd_is_ttd == 'Manual' ? 'row' : 'd-none'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>




<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowPermintaanDarah: {},
            rowPasien: {},
            rowData: {},
            rowReg: {}
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowPermintaanDarah[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowPermintaanDarah[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if(this.rowPermintaanDarah.aufpd_is_ttd == 'Digital'){
                    if(!this.rowPermintaanDarah.aufpd_ttd){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                }

                this.rowPermintaanDarah.aufpd_template = $(".document-framer").html()


                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowPermintaanDarah
                            data.type = 'submit-data-ttd-permintaan-darah'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDPermintaanDarah(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-permintaan-darah',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDPermintaanDarah()
        
        setTimeout(()=>{
            if(this.$refs['ttdPetugas']){
                this.$refs['ttdPetugas'].fromDataURL(this.rowPermintaanDarah.aufpd_ttd)
            }
        },1500)
    },
}      
</script>
