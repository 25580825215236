<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <div class="row" v-if="!rowKriteriaICU.aukic_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowKriteriaICU['aukic_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowKriteriaICU.aukic_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowKriteriaICU.aukic_is_ttd == 'Digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
                <div class="form-group">
                <label for="">Tanda Tangan Petugas</label>
                <div class="signing-element" data-target-id="ttdPetugas">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPetugas"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdPetugas','aukic_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdPetugas','aukic_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                <div class="df-content">
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                        <div>
                            <table style="border-collapse: collapse;width: 100%;font-size: 12px;border-color: #000;line-height: 1.5;" border="1">
                            <tbody><tr>
                                <td style="padding: 4px 24px;" width="45%" rowspan="4">
                                <div style="text-align: center;">
                                    <img :src="'https://imr-dev.lingkarhub.com/global_assets/images/logo-rsh-mark.png'" style="max-width: 100%;width: 15.3%;float: left;" alt="">
    
                                    <div style="float: left;padding-left: 8px;text-align: center;width: 84.7%;box-sizing: border-box;">
                                    <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan</h2>
                                    <strong><i>Jl. Panembahan Senopati No. 11<br>Magelang 56123</i></strong>
                                    </div>
                                    <div style="clear: both;"></div>
                                </div>
                                </td>
                                <td style="padding: 4px 8px;" width="55%"><span style="font-weight: bold;">Nama: <strong>{{rowPasien.ap_fullname||"-"}}</strong></span></td>
                            </tr>
                            <tr>
                                <td style="padding: 4px 8px;"><span style="font-weight: bold;">Tgl. Lahir: <strong>{{rowPasien.ap_dob | moment("DD MMMM YYYY") }}</strong></span></td>
                            </tr>
                            <tr>
                                <td style="padding: 4px 8px;"><span style="font-weight: bold;">NIK: <strong>
                                {{rowPasien.ap_nik || "-"}}</strong></span></td>
                            </tr>
                            <tr>
                                <td style="padding: 4px 8px;"><span style="font-weight: bold;">Jenis Kelamin: <strong>{{rowPasien.ap_gender == '1' ? "Laki-Laki" : "Perempuan"}}</strong></span></td>
                            </tr>
                            <tr>
                                <th bgcolor="#eee" style="padding: 4px 8px;"><span style="font-weight: bold;text-transform: uppercase;">Form Masuk ICU/PICU/NICU</span></th>
                                <td style="padding: 4px 8px;"><span style="font-weight: bold;">No. RM: <strong>{{rowPasien.ap_code || "-"}}</strong></span></td>
                            </tr>
                            </tbody></table>
                            <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 12px;line-height: 1.5;">
                            <tbody><tr>
                                <td style="padding: 4px 8px;vertical-align: top;" rowspan="2" width="50%">
                                <span style="text-transform:uppercase;">Diagnosa: </span><strong>{{rowKriteriaICU.diagnosa||"-"}}</strong> 
                                </td>
                                <td style="padding: 4px 8px;" width="50%" colspan="2">
                                <span style="text-transform:uppercase;">DPJP: </span><strong>{{rowReg.bu_full_name||"-"}}</strong> 
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 4px 8px;" colspan="2">
                                <span style="text-transform:uppercase;">Tanggal Masuk ICU: <strong>{{rowKriteriaICU.tanggal_masuk |  moment("DD MMMM YYYY")}}</strong></span><strong></strong> 
                                </td>
                            </tr>
                            <tr>
                                <th style="padding: 4px 8px;" colspan="3">
                                <span style="text-transform:uppercase;">Kriteria Pasien Masuk 
                                {{rowKriteriaICU.aukic_type == 'ICU' ? 'ICU' : 'PICU/NICU'}}
                                </span>
                                </th>
                            </tr>
                            <template v-if="rowKriteriaICU.aukic_type == 'ICU'">
                                <tr v-if="(rowKriteriaICU.aukic_ttv||[]).length">
                                    <td style="padding: 4px 8px;" colspan="3">
                                    <p style="margin: 0;text-transform: uppercase;"></p> 
                                    <p style="margin: 0;">Tanda Vital:</p>
                                    <ul style="margin: 0;">
                                        <li v-for="(v,k) in (rowKriteriaICU.aukic_ttv||[])" :key="k">
                                            {{v||"-"}}
                                        </li>
                                    </ul> 
                                    </td>
                                </tr>
                                <tr v-if="(rowKriteriaICU.aukic_nilai_lab||[]).length">
                                    <td style="padding: 4px 8px;" colspan="3">
                                    <p style="margin: 0;text-transform: uppercase;"></p> 
                                    <p style="margin: 0;">Nilai Laboratorium:</p>
                                    <ul style="margin: 0;">
                                        <li v-for="(v,k) in (rowKriteriaICU.aukic_nilai_lab||[])" :key="k">
                                            {{v||"-"}}
                                        </li>
                                    </ul> 
                                    </td>
                                </tr>
                                <tr v-if="(rowKriteriaICU.aukic_radiografi||[]).length">
                                    <td style="padding: 4px 8px;" colspan="3">
                                    <p style="margin: 0;text-transform: uppercase;"></p> 
                                    <p style="margin: 0;">Radiografi / Ultrasonografi:</p>
                                    <ul style="margin: 0;">
                                        <li v-for="(v,k) in (rowKriteriaICU.aukic_radiografi||[])" :key="k">
                                            {{v||"-"}}
                                        </li>
                                    </ul> 
                                    </td>
                                </tr>
                                <tr v-if="(rowKriteriaICU.aukic_pemeriksaan_fisik||[]).length">
                                    <td style="padding: 4px 8px;" colspan="3">
                                    <p style="margin: 0;text-transform: uppercase;"></p> 
                                    <p style="margin: 0;">Pemeriksaan Fisisk (Onset Akut ):</p>
                                    <ul style="margin: 0;">
                                        <li v-for="(v,k) in (rowKriteriaICU.aukic_pemeriksaan_fisik||[])" :key="k">
                                            {{v||"-"}}
                                        </li>
                                    </ul> 
                                    </td>
                                </tr>
                            </template>

                            <template v-if="rowKriteriaICU.aukic_type == 'PCNC'">
                                <tr v-if="(rowKriteriaICU.aukic_nc_ttv||[]).length">
                                    <td style="padding: 4px 8px;" colspan="3">
                                    <p style="margin: 0;text-transform: uppercase;"></p> 
                                    <p style="margin: 0;">Tanda Vital:</p>
                                    <ul style="margin: 0;">
                                        <li v-for="(v,k) in (rowKriteriaICU.aukic_nc_ttv||[])" :key="k">
                                            {{v||"-"}}
                                        </li>
                                    </ul> 
                                    </td>
                                </tr>
                                <tr v-if="(rowKriteriaICU.aukic_nc_radiografi||[]).length">
                                    <td style="padding: 4px 8px;" colspan="3">
                                    <p style="margin: 0;text-transform: uppercase;"></p> 
                                    <p style="margin: 0;">Radiografi / Ultrasonografi:</p>
                                    <ul style="margin: 0;">
                                        <li v-for="(v,k) in (rowKriteriaICU.aukic_nc_radiografi||[])" :key="k">
                                            {{v||"-"}}
                                        </li>
                                    </ul> 
                                    </td>
                                </tr>
                            </template>

                            <!--
                            <tr v-if="rowKriteriaICU.aukic_kriteria == 1">
                                <td style="padding: 4px 8px;" colspan="2">
                                <p style="margin: 0;text-transform: uppercase;">Prioritas 1</p> 
                                <p style="margin: 0;">Penyakit atau gangguan akut pada organ vital yang membutuhkan terapi intensif dan agresif, seperti:</p>
                                <ul style="margin: 0;">
                                    <li>Gagal nafas akut</li>
                                    <li>Kegagalan sirukulasi / syok</li>
                                    <li>Gangguan susunan saraf</li>
                                </ul> 
                                </td>
                                <th style="padding: 4px 12px;" width="40"></th>
                            </tr>
                            <tr v-else-if="rowKriteriaICU.aukic_kriteria == 2">
                                <td style="padding: 4px 8px;" colspan="2">
                                <p style="margin: 0;text-transform: uppercase;">Prioritas 2</p> 
                                <p style="margin: 0;">Pasien yang memerlukan pemantauan intesif secara eksklusif atas keadaan yang dapat menimbulkan ancaman gangguan pada organ vital seperti:</p>
                                <ul style="margin: 0;">
                                    <li>Pasien pasca operasi</li>
                                    <li>Riwayat cardiac arrest/pasca resusitasi</li>
                                    <li>Pasien yang membutuhkan obat-obatan intropic dan antiaritmia yang memerlukan monitoring intensif</li>
                                </ul> 
                                </td>
                                <th style="padding: 4px 12px;" width="40"></th>
                            </tr>
                            <tr v-else-if="rowKriteriaICU.aukic_kriteria == 3">
                                <td style="padding: 4px 8px;" colspan="2">
                                <p style="margin: 0;text-transform: uppercase;">Prioritas 3</p> 
                                <p style="margin: 0;">Pasien dalam keadaan sangat kritis dan tidak stabil yang harapan sembuhnya kecil. Pasien hanya membutuhkan perawatan intensif pada penyakit akutnya saja, tidak memerlukan intubasi/resusitasi kardiopulmer</p>
                                </td>
                                <th style="padding: 4px 12px;" width="40"></th>
                            </tr>
                            -->

                            <tr>
                                <td style="padding: 4px 8px;vertical-align: top;" width="50%">
                                <p style="margin: 0;">Petugas: <br><strong>( <span>{{user.fullName||"-"}}</span> )</strong></p>
                                </td>
                                <td style="padding: 4px 8px;vertical-align: top;" width="50%" colspan="2">
                                <p style="margin: 0;">Paraf:</p>
                                <div style="text-align:center;">
                                    <span v-if="rowKriteriaICU.aukic_is_ttd == 'Digital'">
                                    <img :src="rowKriteriaICU.aukic_ttd ? rowKriteriaICU.aukic_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                    <br/>
                                    </span>
                                    <span v-else>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>         
                                    </span>         
                                </div>
                                </td>
                            </tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        
        <div :class="rowKriteriaICU.aukic_is_ttd == 'Manual' ? 'row' : 'd-none'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowKriteriaICU: {},
            rowPasien: {},
            rowData: {},
            rowReg: {}
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowKriteriaICU[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowKriteriaICU[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if(this.rowKriteriaICU.aukic_is_ttd == 'Digital'){
                    if(!this.rowKriteriaICU.aukic_ttd){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                }

                this.rowKriteriaICU.aukic_template = $(".document-framer").html()


                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowKriteriaICU
                            data.type = 'submit-data-ttd-kriteria-icu'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDPemaparanRM(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-kriteria-icu',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDPemaparanRM()
        setTimeout(()=>{
            if(this.$refs['ttdPetugas']){
                this.$refs['ttdPetugas'].fromDataURL(this.rowKriteriaICU.aukic_ttd)
            }
        },1500)
    },
}      
</script>
