<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{rowReg.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong
                                        class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong
                                        class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>

                        <!--
                        <div v-if="typeKajian=='kajian-dokter'||typeKajian=='kajian-dokter-terapi'||typeKajian=='kajian-gizi'" class="d-flex flex-column">
                            <a href="javascript:;" @click="viewKajianPerawat()" data-toggle="modal" class="btn btn-outline-light">Lihat Kajian Perawat</a>

                            <a href="javascript:;" v-if="typeKajian=='kajian-dokter' && rowReg.kajianLatestDokter" @click="doOpenRiwayatDokter(rowReg.kajianLatestDokter)" data-toggle="modal" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis</a>
                        </div>
                        
                        
                        <div v-else class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                        -->
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
                <i class="icon-arrow-up5 align-middle"></i>
                <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>

        <template v-if="!isForm">

            <!-- User Bidan -->
            <div class="content" v-if="$parent.uBidan == $parent.user.levelId">
                <div class="card">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex align-items-center">
                                <h6 class="card-title font-weight-semibold mr-auto">Dokumen Terkait Pasien</h6>
                            </div>
                        </div>
                        <table class="table table-bordered table-sm patient-table">
                            <thead>
                                <tr>
                                    <th>Nama Dokumen</th>
                                    <th>Status</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                            <template v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1">
                            <tr>
                                <td>Pemeriksaan EKG</td>
                                <td>
                                    <span v-if="rowReg.aur_pemeriksaan_egd_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-info">Unggah Berkas</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="uploadModalTTD('PemeriksaanEKG',rowReg.aur_pemeriksaan_egd_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Isi Dokumen"><i class="icon-upload"></i></a>

                                    <template v-if="rowReg.aur_pemeriksaan_egd_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_pemeriksaan_egd_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>Pemeriksaan CTG</td>
                                <td>
                                    <span v-if="rowReg.aur_pemeriksaan_ctg_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-info">Unggah Berkas</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="uploadModalTTD('PemeriksaanCTG',rowReg.aur_pemeriksaan_ctg_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Isi Dokumen"><i class="icon-upload"></i></a>

                                    <template v-if="rowReg.aur_pemeriksaan_ctg_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_pemeriksaan_ctg_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>Partograf</td>
                                <td>
                                    <span v-if="rowReg.aur_pemeriksaan_partograf_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-info">Unggah Berkas</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="uploadModalTTD('PemeriksaanPartograf',rowReg.aur_pemeriksaan_partograf_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Isi Dokumen"><i class="icon-upload"></i></a>

                                    <template v-if="rowReg.aur_pemeriksaan_partograf_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_pemeriksaan_partograf_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>Catatan Partus</td>
                                <td>
                                    <span v-if="rowReg.aur_partus_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                <a href="javascript:;" @click="toForm('Partus')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Isi Dokumen"><i class="icon-pencil7"></i></a>
                                
                                <a href="javascript:;" @click="toForm('Partus',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td>Form Bayi Baru Lahir</td>
                                <td>
                                    <span v-if="rowReg.aur_bbl_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                <a href="javascript:;" @click="toForm('BBL')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Isi Dokumen"><i class="icon-pencil7"></i></a>

                                <a href="javascript:;" @click="toForm('BBL',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                </td>
                            </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="content" v-else>
                <div class="card">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="card-title font-weight-semibold">Persetujuan Tindakan Kedokteran (Informed Consent)</h6>
                        </div>
                        <div class="card-body">
                            <div class="row row-gutter">
                            <div class="col-md-4" v-for="(v,k) in (informConcent||[])" :key="k">
                                <div class="wrap_line mb-0 h-100">
                                    <div class="mb-2">
                                        <h3 class="pb-0">[{{v.auic_created_on}}] {{v.auic_name||"-"}}</h3>
                                    </div>
                                    <div>
                                        <a href="javascript:;" @click="openTemplateIC(v)" class="btn alpha-success border-success text-success-800 mb-1">
                                            <b><i class="icon-download"></i></b>
                                            <span class="ml-2">Unduh Template Dokumen</span>
                                        </a>
                                        <Uploader @input="uploadDokStatus(v,k)" v-model="v.auic_file" isDocument type="doc_only" />
                                    </div>
                                    
                                    <span class="badge badge-primary" v-if="v.auic_created_on == 'PetugasRadiologi'">
                                        Radiologi
                                    </span>
                                    <span class="badge badge-primary" v-else>
                                        Dokter
                                    </span>

                                    <div class="d-flex align-items-center justify-content-between mt-2">
                                        <span v-if="v.auic_status == 'DONE' || v.auic_file" class="badge badge-success">Selesai</span>
                                        <span v-else class="badge badge-info">Unggah TTD</span>
                                        <div>
                                            <a v-if="v.auic_file" :href="$parent.uploader(v.auic_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>

                                            <a href="javascript:;" @click="openInformConsentDetail(v)" data-toggle="modal" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-eye"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="!(informConcent||[]).length">
                                <p>Tidak ada Data Inform Consent</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex align-items-center">
                                <h6 class="card-title font-weight-semibold mr-auto">Dokumen Terkait Pasien</h6>
                            </div>
                        </div>
                        <table class="table table-bordered table-sm patient-table">
                            <thead>
                                <tr>
                                    <th>Nama Dokumen</th>
                                    <th>Status</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Form Do Not Resuscitate</td>
                                <td>
                                    <span v-if="rowReg.aur_dnr_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_dnr_status == 'U'" class="badge badge-warning">Unggah Dokumen</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="toForm('DNR')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Isi Dokumen"><i class="icon-pencil7"></i></a>
                                    
                                    <template v-if="rowReg.aur_dnr_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_dnr_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>

                                    
                                    <template v-if="rowReg.aur_dnr_status == 'U'">
                                        <a href="javascript:;" @click="uploadModalTTD('SuratDNR',rowReg.aur_dnr_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                        
                                        <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'SuratDNR')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="rowReg.aur_is_intra_penunjang == 'Y'">
                                <td>Form Transfer Intra RS (Penunjang)</td>
                                <td>
                                    <span v-if="rowReg.aur_intra_rs_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                
                                <a href="javascript:;" @click="toForm('IntraRS')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Isi Dokumen"><i class="icon-pencil7"></i></a>

                                <a href="javascript:;" @click="toForm('IntraRS',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                </td>
                            </tr>
                            <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1">
                                <td>Form Transfer Intra RS (Rawat Inap)</td>
                                <td>
                                    <span v-if="rowReg.aur_intra_ranap_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                <a href="javascript:;" @click="toForm('IntraRanap')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Isi Dokumen"><i class="icon-pencil7"></i></a>
                                <a href="javascript:;" @click="toForm('IntraRanap',true)"  class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td>Surat Keterangan Sehat Dokter</td>
                                <td>
                                    <span v-if="rowReg.aur_skd_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_skd_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                    <span v-else-if="rowReg.aur_skd_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>

                                <a href="javascript:;" @click="toForm('SuratKeteranganDokter')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Ubah Isi Dokumen"><i class="icon-pencil7"></i></a>
                                <template v-if="rowReg.aur_skd_status == 'T' || rowReg.aur_skd_status == 'U'">
                                    <a href="javascript:;" @click="openModalTTD('SuratKeteranganDokter')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right :title="rowReg.aur_skd_status == 'T' ? 'Tanda Tangani Dokumen': 'Edit Tanda Tangan Dokumen'"><i class="icon-pencil"></i></a>
                                </template>
                                <template v-if="rowReg.aur_skd_status == 'U'">
                                    <a href="javascript:;" @click="uploadModalTTD('SuratKeteranganDokter',rowReg.aur_skd_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                    
                                    <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'SuratKeteranganDokter')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>
                                </template>
                                <template v-if="rowReg.aur_skd_status == 'Y'">
                                    <a :href="$parent.uploader(rowReg.aur_skd_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                </template>
                                </td>
                            </tr>
                            <tr v-if="rowReg.aur_tindak_lanjut_rajal == 'KM'">
                                <td>Surat Izin Sakit</td>
                                <td>
                                    <span v-if="rowReg.aur_surat_sakit_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_surat_sakit_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                    <span v-else-if="rowReg.aur_surat_sakit_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="toForm('SuratIzinSakit')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Ubah Isi Dokumen"><i class="icon-pencil7"></i></a>
                                    <template v-if="rowReg.aur_surat_sakit_status == 'T' || rowReg.aur_surat_sakit_status == 'U'">
                                        <a href="javascript:;" @click="openModalTTD('SuratIzinSakit')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right :title="rowReg.aur_surat_sakit_status == 'T' ? 'Tanda Tangani Dokumen': 'Edit Tanda Tangan Dokumen'"><i class="icon-pencil"></i></a>
                                    </template>
                                    <template v-if="rowReg.aur_surat_sakit_status == 'U'">
                                        <a href="javascript:;" @click="uploadModalTTD('SuratIzinSakit',rowReg.aur_surat_sakit_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                        
                                        <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'SuratIzinSakit')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>

                                    </template>
                                    <template v-if="rowReg.aur_surat_sakit_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_surat_sakit_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>Surat Izin Pemaparan Isi Rekam Medis</td>
                                <td>
                                    <span v-if="rowReg.aur_pemaparan_rm_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_pemaparan_rm_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                    <span v-else-if="rowReg.aur_pemaparan_rm_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                <a href="javascript:;" @click="toForm('SuratIzinRM')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Ubah Isi Dokumen"><i class="icon-pencil7"></i></a>
                                <template v-if="rowReg.aur_pemaparan_rm_status == 'T' || rowReg.aur_pemaparan_rm_status == 'U'">
                                    <a href="javascript:;" @click="openModalTTD('SuratIzinRM')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right :title="rowReg.aur_pemaparan_rm_status == 'T' ? 'Tanda Tangani Dokumen': 'Edit Tanda Tangan Dokumen'"><i class="icon-pencil"></i></a>
                                </template>
                                <template v-if="rowReg.aur_pemaparan_rm_status == 'U'">
                                    <a href="javascript:;" @click="uploadModalTTD('SuratIzinRM',rowReg.aur_pemaparan_rm_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                    
                                    <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'SuratIzinRM')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>
                                </template>
                                <template v-if="rowReg.aur_pemaparan_rm_status == 'Y'">
                                    <a :href="$parent.uploader(rowReg.aur_pemaparan_rm_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                </template>
                                </td>
                            </tr>
                            <tr v-if="rowReg.aur_tindak_lanjut_rajal == 'DOA' || rowReg.aur_tindak_lanjut_rajal == 'MU'">
                                <td>Surat Keterangan Kematian</td>
                                <td>
                                    <span v-if="rowReg.aur_surat_kematian_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_surat_kematian_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                    <span v-else class="badge badge-info">Unggah TTD</span>
                                <td>
                                
                                
                                <template v-if="!rowReg.aur_surat_kematian_status || rowReg.aur_surat_kematian_status == 'T' || rowReg.aur_surat_kematian_status == 'U'">
                                    <a href="javascript:;" @click="openModalTTD('SuratKematian')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right :title="rowReg.aur_surat_kematian_status == 'T' ? 'Tanda Tangani Dokumen': 'Edit Tanda Tangan Dokumen'"><i class="icon-pencil"></i></a>
                                </template>
                                <template v-if="rowReg.aur_surat_kematian_status == 'U'">
                                    <a href="javascript:;" @click="uploadModalTTD('SuratKematian',rowReg.aur_surat_kematian_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                    
                                    <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'SuratKematian')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>
                                </template>
                                <template v-if="rowReg.aur_surat_kematian_status == 'Y'">
                                    <a :href="$parent.uploader(rowReg.aur_surat_kematian_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                </template>

                                </td>
                            </tr>
                            <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RU') != -1">
                                <td>Surat Rujukan</td>
                                <td>
                                    <span v-if="rowReg.aur_rujukan_status == 'Y'" class="badge badge-success">Selesai Dicetak</span>
                                    <span v-else class="badge badge-warning">Belum Tercetak</span></td>
                                <td>
                                <a href="javascript:;" @click="generateFile(rowReg.aur_id,'SuratRujukan')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen Akhir"><i class="icon-download"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td>Form Permintaan Darah</td>
                                <td>
                                    <span v-if="rowReg.aur_permintaan_darah_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_permintaan_darah_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                    <span v-else-if="rowReg.aur_permintaan_darah_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="toModal('PermintaanDarah','Form Permintaan Darah','md')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Ubah Isi Dokumen"><i class="icon-pencil7"></i></a>
                                    <template v-if="rowReg.aur_permintaan_darah_status == 'T' || rowReg.aur_permintaan_darah_status == 'U'">
                                        <a href="javascript:;" @click="openModalTTD('PermintaanDarah')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right :title="rowReg.aur_permintaan_darah_status == 'T' ? 'Tanda Tangani Dokumen': 'Edit Tanda Tangan Dokumen'"><i class="icon-pencil"></i></a>
                                    </template>

                                    <template v-if="rowReg.aur_permintaan_darah_status == 'U'">
                                        <a href="javascript:;" @click="uploadModalTTD('PermintaanDarah',rowReg.aur_permintaan_darah_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                    
                                        <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'PermintaanDarah')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>
                                    
                                    </template>
                                    
                                    <template v-if="rowReg.aur_permintaan_darah_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_permintaan_darah_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1">
                                <td>Surat Penetapan DPJP</td>
                                <td>
                                    <span v-if="rowReg.aur_surat_dpjp_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_surat_dpjp_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                    <span v-else class="badge badge-info">Unggah TTD</span>
                                </td>
                                <td>
                                    <template v-if="!rowReg.aur_surat_dpjp_status || rowReg.aur_surat_dpjp_status == 'T' || rowReg.aur_surat_dpjp_status == 'U'">
                                        <a href="javascript:;" @click="openModalTTD('SuratDPJP')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right :title="rowReg.aur_surat_dpjp_status == 'T' ? 'Tanda Tangani Dokumen': 'Edit Tanda Tangan Dokumen'"><i class="icon-pencil"></i></a>
                                    </template>
                                    <template v-if="rowReg.aur_surat_dpjp_status == 'U'">
                                        <a href="javascript:;" @click="uploadModalTTD('SuratDPJP',rowReg.aur_surat_dpjp_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                            
                                        <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'SuratDPJP')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                    <template v-if="rowReg.aur_surat_dpjp_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_surat_dpjp_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <!--  -->
                            <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1">
                                <td>Kriteria Masuk ICU, PICU, NICU</td>
                                <td>
                                    <span v-if="rowReg.aur_kriteria_icu_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_kriteria_icu_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                    <span v-else-if="rowReg.aur_kriteria_icu_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="toModal('KriteriaICU','Kriteria Masuk ICU, PICU, NICU','md')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Ubah Isi Dokumen"><i class="icon-pencil7"></i></a>
                                    <template v-if="rowReg.aur_kriteria_icu_status == 'T' || rowReg.aur_kriteria_icu_status == 'U'">
                                        <a href="javascript:;" @click="openModalTTD('KriteriaICU')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right :title="rowReg.aur_kriteria_icu_status == 'T' ? 'Tanda Tangani Dokumen': 'Edit Tanda Tangan Dokumen'"><i class="icon-pencil"></i></a>
                                    </template>
                                    <template v-if="rowReg.aur_kriteria_icu_status == 'U'">
                                        <a href="javascript:;" @click="uploadModalTTD('KriteriaICU',rowReg.aur_kriteria_icu_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                        
                                        <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'KriteriaICU')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                    <template v-if="rowReg.aur_kriteria_icu_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_kriteria_icu_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <!--
                             
                            -->
                            <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1">
                                <td>Kriteria Masuk Isolasi</td>
                                <td>
                                    <span v-if="rowReg.aur_kriteria_isolasi_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else-if="rowReg.aur_kriteria_isolasi_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                    <span v-else-if="rowReg.aur_kriteria_isolasi_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="toModal('KriteriaIsolasi','Kriteria Masuk Isolasi','lg')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Ubah Isi Dokumen"><i class="icon-pencil7"></i></a>
                                    <template v-if="rowReg.aur_kriteria_isolasi_status == 'T' || rowReg.aur_kriteria_isolasi_status == 'U'">
                                        <a href="javascript:;" @click="openModalTTD('KriteriaIsolasi')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right :title="rowReg.aur_kriteria_isolasi_status == 'T' ? 'Tanda Tangani Dokumen': 'Edit Tanda Tangan Dokumen'"><i class="icon-pencil"></i></a>
                                    </template>
                                    <template v-if="rowReg.aur_kriteria_isolasi_status == 'U'">
                                        <a href="javascript:;" @click="uploadModalTTD('KriteriaIsolasi',rowReg.aur_kriteria_isolasi_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Dokumen TTD"><i class="icon-upload"></i></a>
                                        
                                        <a href="javascript:;" @click="downloadFile(rowReg.aur_id,'KriteriaIsolasi')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Unduh Template Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                    <template v-if="rowReg.aur_kriteria_isolasi_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_kriteria_isolasi_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <template v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1">
                            <tr>
                                <td>Pemeriksaan EKG</td>
                                <td>
                                    <span v-if="rowReg.aur_pemeriksaan_egd_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-info">Unggah Berkas</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="uploadModalTTD('PemeriksaanEKG',rowReg.aur_pemeriksaan_egd_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Isi Dokumen"><i class="icon-upload"></i></a>

                                    <template v-if="rowReg.aur_pemeriksaan_egd_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_pemeriksaan_egd_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>Pemeriksaan CTG</td>
                                <td>
                                    <span v-if="rowReg.aur_pemeriksaan_ctg_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-info">Unggah Berkas</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="uploadModalTTD('PemeriksaanCTG',rowReg.aur_pemeriksaan_ctg_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Isi Dokumen"><i class="icon-upload"></i></a>

                                    <template v-if="rowReg.aur_pemeriksaan_ctg_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_pemeriksaan_ctg_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>Partograf</td>
                                <td>
                                    <span v-if="rowReg.aur_pemeriksaan_partograf_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-info">Unggah Berkas</span>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="uploadModalTTD('PemeriksaanPartograf',rowReg.aur_pemeriksaan_partograf_file)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Unggah Isi Dokumen"><i class="icon-upload"></i></a>

                                    <template v-if="rowReg.aur_pemeriksaan_partograf_status == 'Y'">
                                        <a :href="$parent.uploader(rowReg.aur_pemeriksaan_partograf_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>Catatan Partus</td>
                                <td>
                                    <span v-if="rowReg.aur_partus_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                <a href="javascript:;" @click="toForm('Partus')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Isi Dokumen"><i class="icon-pencil7"></i></a>
                                
                                <a href="javascript:;" @click="toForm('Partus',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td>Form Bayi Baru Lahir</td>
                                <td>
                                    <span v-if="rowReg.aur_bbl_status == 'Y'" class="badge badge-success">Selesai</span>
                                    <span v-else class="badge badge-warning">Belum Diisi</span>
                                </td>
                                <td>
                                <a href="javascript:;" @click="toForm('BBL')" class="btn btn-sm alpha-warning border-warning btn-icon rounded-round text-warning-700" v-b-tooltip.hover.right title="Isi Dokumen"><i class="icon-pencil7"></i></a>

                                <a href="javascript:;" @click="toForm('BBL',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                </td>
                            </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="xl" ok-only>
                <table class="table table-bordered table-sm table-input">
                <thead>
                    <tr>
                    <td colspan="3">
                        <div class="result_tab">
                            <h4>Nama Tindakan</h4>
                            <p>{{rowIC.tindakan}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <th width="24">No.</th>
                    <th>Jenis Informasi</th>
                    <th>Isi Informasi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                    <td>
                    {{rowIC.auic_diagnosis||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>Dasar Diagnosis</td>
                    <td>
                    {{rowIC.auic_dasar_diagnosis||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Tindakan Kedokteran</td>
                    <td>
                    {{rowIC.auic_tindakan_dokter||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>Indikasi Tindakan</td>
                    <td>
                    {{rowIC.auic_indikasi_tindakan||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>Tata Cara</td>
                    <td>
                    {{rowIC.auic_tata_cara||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>6</td>
                    <td>Tujuan</td>
                    <td>
                    {{rowIC.auic_tujuan||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>7</td>
                    <td>Risiko</td>
                    <td>
                    {{rowIC.auic_risiko||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>8</td>
                    <td>Komplikasi</td>
                    <td>
                    {{rowIC.auic_kompilasi||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>9</td>
                    <td>Prognosis</td>
                    <td>
                    {{rowIC.auic_prognosis||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>10</td>
                    <td>Alternatif & Risiko</td>
                    <td>
                    {{rowIC.auic_alternatif||"-"}}
                    </td>
                    </tr>
                    <tr>
                    <td>11</td>
                    <td>Lain-lain</td>
                    <td>
                    {{rowIC.auic_lainnya||"-"}}
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                    <td width="50%" colspan="2">
                        <div class="result_tab">
                        <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                        <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                        <p v-else>Tindakan ditolak</p>
                        </div>
                    </td>
                    <td width="50%">
                        <div class="result_tab">
                        <h4>Persetujuan tindakan diberikan kepada</h4>
                        <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
                        <p v-else>Penanggung Jawab</p>
                        </div>
                    </td>
                    </tr>
                </tfoot>
                </table>
            </b-modal>

            <b-modal v-model="formModal" :title="formTitle" :size="formSize" hide-footer>
                <FormKriteriaICU v-if="formParam == 'KriteriaICU'"/>
                <FormKriteriaIsolasi v-if="formParam == 'KriteriaIsolasi'"/>   
                <FormPermintaanDarah v-if="formParam == 'PermintaanDarah'" />
            </b-modal>

            <b-modal v-model="ttdModal" :title="'Tanda tangani Dokumen'" size="xl" hide-footer>
                <TTDSuratIzinRM v-if="ttdData == 'SuratIzinRM'"/>
                <TTDSuratIzinSakit v-if="ttdData == 'SuratIzinSakit'"/>
                <TTDSuratKeteranganDokter v-if="ttdData == 'SuratKeteranganDokter'"/>

                <TTDKriteriaICU v-if="ttdData == 'KriteriaICU'"/>
                <TTDKriteriaIsolasi v-if="ttdData == 'KriteriaIsolasi'"/>
                <TTDPermintaanDarah v-if="ttdData == 'PermintaanDarah'"/>
                <TTDSuratKematian v-if="ttdData == 'SuratKematian'"/>
                <TTDSuratDPJP v-if="ttdData == 'SuratDPJP'"/>


            </b-modal>

            <b-modal v-model="signingModal" :title="isEditUploader ? 'Update Dokumen' : 'Upload Dokumen'" size="sm" hide-footer>
                <div v-if="isEditUploader">
                    <a :href="$parent.uploader(isEditUploader)" target="_blank" class="btn alpha-success border-success text-success-800 mb-1"><b><i class="icon-download"></i></b><span class="ml-2">Unduh Dokumen Tanda Tangan Terakhir</span></a>
                </div>

                <UploadSuratIzinRM v-if="signingData == 'SuratIzinRM'"/>
                <UploadSuratIzinSakit v-if="signingData == 'SuratIzinSakit'"/>
                <UploadSuratKeteranganDokter v-if="signingData == 'SuratKeteranganDokter'"/>

                <UploadKriteriaICU v-if="signingData == 'KriteriaICU'"/>
                <UploadKriteriaIsolasi v-if="signingData == 'KriteriaIsolasi'"/>
                <UploadPermintaanDarah v-if="signingData == 'PermintaanDarah'"/>
                <UploadSuratKematian v-if="signingData == 'SuratKematian'"/>
                <UploadSuratDPJP v-if="signingData == 'SuratDPJP'"/>


                <UploadPemeriksaanEKG v-if="signingData == 'PemeriksaanEKG'"/>
                <UploadPemeriksaanCTG v-if="signingData == 'PemeriksaanCTG'"/>
                <UploadPemeriksaanPartograf v-if="signingData == 'PemeriksaanPartograf'"/>

                <UploadSuratDNR v-if="signingData == 'SuratDNR'"/>

            </b-modal>

            <validation-observer ref="VFormModal">
            <b-modal v-model="openTemplateICModal" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template" title="Unduh Template Dokumen" size="lg">
                <table class="table table-bordered table-sm text-uppercase">
                    <tbody>
                        <tr>
                            <td colspan="3" class="bg-primary">Data Pasien</td>
                        </tr>
                        <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Nama Pasien</h4>
                                <p>{{row.ap_fullname||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Usia</h4>
                                <p>{{row.ap_fullname||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Alamat</h4>
                                <p>{{row.ap_address||"-"}}</p>
                                </div>
                            </td>
                        </tr>
                        <!-- == 'PJ'-->
                        <template v-if="rowIC.auic_persetujuan_tindakan">
                        <tr>
                            <td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
                        </tr>
                        <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Nama Penanggung Jawab</h4>
                                <b-form-input v-model="rowIC.auic_nama_pj" type="text" name="NamaPenanggung" id="NamaPenanggung" class="form-control"
                                placeholder="Nama Penanggung Jawab"/>
                                <VValidate 
                                    name="Nama Penanggung Jawab" 
                                    v-model="rowIC.auic_nama_pj" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Alamat Penanggung Jawab</h4>
                                <b-form-input v-model="rowIC.auic_address_pj" type="text" name="alamat" id="alamat" class="form-control"
                                placeholder="Alamat Penanggung Jawab"/>
                                <VValidate 
                                    name="Alamat Penanggung Jawab" 
                                    v-model="rowIC.auic_address_pj" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </b-modal>
            </validation-observer>
            

            
        </template>
        <template v-else>
            <FormDNR v-if="isForm == 'DNR'"/>
            <FormIntraRS v-if="isForm == 'IntraRS'"/>
            <FormIntraRanap v-if="isForm == 'IntraRanap'"/>
            <FormPartus v-if="isForm == 'Partus'"/>
            <FormBBL v-if="isForm == 'BBL'"/>
            <FormSuratKeteranganDokter v-if="isForm == 'SuratKeteranganDokter'"/>
            <FormSuratIzinRM v-if="isForm == 'SuratIzinRM'"/>
            <FormSuratIzinSakit v-if="isForm == 'SuratIzinSakit'"/>
        </template>

        
    </div>
</template>

<script>
    import $ from 'jquery'
    const _ = global._
    const moment = require('moment')

    import Gen from '@/libs/Gen.js'
    import FormDNR from './FormDNR.vue'
    import FormIntraRS from './FormIntraRS.vue'
    import FormIntraRanap from './FormIntraRanap.vue'
    import FormPartus from './FormPartus.vue'
    import FormBBL from './FormBBL.vue'
    import FormSuratKeteranganDokter from './FormSuratKeteranganDokter.vue'
    import FormSuratIzinRM from './FormSuratIzinRM.vue'
    import FormSuratIzinSakit from './FormSuratIzinSakit.vue'
    import FormKriteriaICU from './FormKriteriaICU.vue'
    import FormKriteriaIsolasi from './FormKriteriaIsolasi.vue'
    import FormPermintaanDarah from './FormPermintaanDarah.vue'



    
    // ttd 
    import TTDSuratIzinRM from './TTDSuratIzinRM.vue'
    import TTDSuratIzinSakit from './TTDSuratIzinSakit.vue'
    import TTDSuratKeteranganDokter from './TTDSuratKeteranganDokter.vue'
    import TTDKriteriaICU from './TTDKriteriaICU.vue'
    import TTDKriteriaIsolasi from './TTDKriteriaIsolasi.vue'
    import TTDPermintaanDarah from './TTDPermintaanDarah.vue'
    import TTDSuratKematian from './TTDSuratKematian.vue'
    import TTDSuratDPJP from './TTDSuratDPJP.vue'




    // upload 
    import UploadSuratIzinRM from './UploadSuratIzinRM.vue'
    import UploadSuratIzinSakit from './UploadSuratIzinSakit.vue'
    import UploadSuratKeteranganDokter from './UploadSuratKeteranganDokter.vue'
    import UploadKriteriaICU from './UploadKriteriaICU.vue'
    import UploadKriteriaIsolasi from './UploadKriteriaIsolasi.vue'
    import UploadPermintaanDarah from './UploadPermintaanDarah.vue'
    import UploadSuratKematian from './UploadSuratKematian.vue'
    import UploadSuratDPJP from './UploadSuratDPJP.vue'
    import UploadSuratDNR from './UploadSuratDNR.vue'

    

    import UploadPemeriksaanEKG from './UploadPemeriksaanEKG.vue'
    import UploadPemeriksaanCTG from './UploadPemeriksaanCTG.vue'
    import UploadPemeriksaanPartograf from './UploadPemeriksaanPartograf.vue'

    export default {
        props: {
            row: Object,
            mrValidation: Object,
            Config: Object,
            rowReg: Object,
            mKesadaran: Array,
            informConcent: Array
        },
        components:{FormDNR,FormIntraRS,FormIntraRanap,FormPartus,FormBBL,FormSuratKeteranganDokter,FormSuratIzinRM,FormSuratIzinSakit, TTDSuratIzinRM,UploadSuratIzinRM, TTDSuratIzinSakit,UploadSuratIzinSakit, TTDSuratKeteranganDokter,UploadSuratKeteranganDokter, FormKriteriaICU, FormKriteriaIsolasi, TTDKriteriaICU, TTDKriteriaIsolasi, UploadKriteriaICU, UploadKriteriaIsolasi, FormPermintaanDarah, TTDPermintaanDarah, UploadPermintaanDarah, TTDSuratKematian, UploadSuratKematian, TTDSuratDPJP, UploadSuratDPJP, UploadPemeriksaanEKG, UploadPemeriksaanCTG, UploadPemeriksaanPartograf,UploadSuratDNR},
        data() {
            return {
                openModalICDetail: false,
                rowIC: {},
                ttdModal: false,
                ttdData: '',
                signingModal: false,
                signingData: '',
                formModal: false,
                formParam: '',
                formTitle: '',
                formSize: '',
                isEditUploader: '',
                openTemplateICModal: false
            }
        },
        computed:{
            isForm(){
                return this.$route.params.typeDokumen
            }
        },
        methods: {
            downloadFile(id,val){
                let data = {exptype: 'xlsx', type: "export", id: id}
                let self = this
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${val}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.$parent.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.$parent.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            openTemplateIC(v){
                this.rowIC = v
                this.rowIC.auic_nama_pj = this.row.arpj_fullname
                this.rowIC.auic_address_pj = this.row.arpj_address
                this.openTemplateICModal = true
            },
            unduhtemplateIC(v){
                this.$refs['VFormModal'].validate().then(success => {
                    if(success){
                        this.uploadDok(v)                
                        setTimeout(()=>{
                            this.openTemplateICModal = false
                            let data = {exptype: 'xlsx', type: "export", id: v.auic_id, regID: this.rowReg.aur_id}
                            let self = this
                            self.$parent.loadingOverlay = true
                            $.ajax({
                                type: "POST",
                                url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'InformConsent'}?token=IXs1029102asoaksoas102901290`,
                                data: data,
                                cache: false,
                                xhrFields:{
                                    responseType: 'blob'
                                },
                                success: data => 
                                {
                                    self.$parent.loadingOverlay = false
                                    var link = document.createElement('a')
                                    link.href = window.URL.createObjectURL(data)
                                    link.download = `${'Inform-consent'}-${moment().format("YYYY-MM-DD")}-${this.rowReg.aur_reg_code}.pdf`
                                    link.click()
                                },
                                fail: data => {
                                    self.$parent.loadingOverlay = false
                                    alert('Not downloaded')
                                }
                            })
                        },2000)
                       
                    }
                })
            },
            generateFile(id,val){
                let data = {exptype: 'xlsx', type: "export", id: id}
                let self = this
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${val}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.$parent.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.$parent.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            toModal(val,title,size){
                this.formModal = true
                this.formParam = val
                this.formTitle = title
                this.formSize = size
            },
            openModalTTD(val){
                if(val){
                    this.ttdData = val
                    this.ttdModal = true
                    setTimeout(()=>{
                        $(".sign-container canvas").attr("width",400)
                        $(".sign-container canvas").attr("height",160)
                    },200)
                }
            },
            uploadModalTTD(val,fileVal){
                if(val){
                    this.signingData = val
                    this.signingModal = true
                    this.isEditUploader = fileVal
                }
            },
            uploadDok(v,k=''){
                Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                    data: {
                        type: 'update-data-ic',
                        id: v.auic_id,
                        auic_file: v.auic_file,
                        auic_nama_pj: v.auic_nama_pj,
                        auic_address_pj: v.auic_address_pj,
                    }
                }, 'POST').then(res => {
                    if(k){
                        this.informConcent[k]['auic_status'] = 'DONE' 
                    }
                })
            },
            uploadDokStatus(v,k=''){
                Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                    data: {
                        type: 'update-data-ic',
                        id: v.auic_id,
                        auic_file: v.auic_file,
                        auic_nama_pj: v.auic_nama_pj,
                        auic_address_pj: v.auic_address_pj,
                        auic_status: 'DONE'
                    }
                }, 'POST').then(res => {
                    this.informConcent[k]['auic_status'] = 'DONE' 
                })
            },
            toForm(isForm,isDetailForm){
                if(isDetailForm){
                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug, typeDokumen: isForm},query: {isDetail: true, isDetailForm: true, regId: this.$route.query.regId}})
                }else{
                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug, typeDokumen: isForm},query: {isDetail: true, regId: this.$route.query.regId}})
                }
                window.scrollTo(0,0)        
            },
            toValidate(val) {
                return {
                    ...val
                }
            },
            openInformConsentDetail(v){
                Gen.apiRest('/do/' + 'UGDFormDokter', {
                    data: {
                        type: 'get-data-ic',
                        id: v.auic_id,
                    }
                }, 'POST').then(res => {
                    this.rowIC = res.data.row 
                    this.openModalICDetail = true                   
                })
            },
            initSticky() {
                const PageHeaderSticky = document.querySelector('.page-header-sticky')
                if (PageHeaderSticky) {
                    const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                    if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
                        PageHeaderToggle.addEventListener('click', function (e) {
                            // e.preventDefault()
                            PageHeaderSticky.classList.toggle('page-header-sticky-open')
                            if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                                PageHeaderToggle.querySelector('span').innerText =
                                    'Sembunyikan Informasi Pasien'
                                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                            } else {
                                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                            }
                        })
                    }
                }
            },
        },        
        mounted() {
            setTimeout(() => {
                this.initSticky()
            }, 1000)
        },
    }
</script>