<template>
    <div class="content">
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <div class="d-flex align-items-center">
              <h6 class="card-title font-weight-semibold">Surat Izin Sakit</h6>
            </div>
          </div>
          <validation-observer ref="VForm">
            <b-form @submit.prevent="doSubmit" class="card">
            <div class="form-underlying card-body p-3">
              <div class="row">
                <div class="col-md-4 col-lg-3">
                  <div>
                    <label for="">Tanggal Awal<strong class="text-danger">*</strong></label>
                    <div class="input-group mb-3">
                        <datepicker input-class="form-control transparent"
                        placeholder="Pilih Tanggal" class="my-datepicker"
                        calendar-class="my-datepicker_calendar" v-model="rowSuratSakit.ausis_tanggal_awal">
                        </datepicker>
                        <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i
                            class="icon-calendar"></i></span>
                        </div>
                    </div>
                    <VValidate :name="`Tanggal Awal`"
                    v-model="rowSuratSakit.ausis_tanggal_awal"
                    :rules="{required: 1}" />
                  </div>
                </div>
                <div class="col-md-4 col-lg-3">
                  <div>
                    <label for="">Tanggal Akhir<strong class="text-danger">*</strong></label>
                    <div class="input-group mb-3">
                        <datepicker input-class="form-control transparent"
                        placeholder="Pilih Tanggal" class="my-datepicker"
                        calendar-class="my-datepicker_calendar" v-model="rowSuratSakit.ausis_tanggal_akhir">
                        </datepicker>
                        <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i
                            class="icon-calendar"></i></span>
                        </div>
                    </div>
                    <VValidate :name="`Tanggal Akhir`"
                    v-model="rowSuratSakit.ausis_tanggal_akhir"
                    :rules="{required: 1}" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'

const moment = require('moment')
export default{
    extends: GlobalVue,    
    computed: {
        isParent(){
            return this.$parent
        },
        now(){
            return moment().format('DD MMM YYYY')
        },
    },
    components: {
      Datepicker
    },
    data(){
        return {
            rowSuratSakit: {},
            mrValidationSuratSakit: {},
        }
    },
    methods: {
        apiGetSuratSakit(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-surat-sakit',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })

        },
        toValidate(val){
            return {...val}
        },
          
        autoSave: _.debounce(function (data) {
          data.type = 'auto-save-data-surat-sakit'
          Gen.apiRest(
            "/do/" + 'UGDTindakLanjut', {
              data: data
            },
            "POST"
          )
        }, 1000),

        doSubmit(){
            this.$refs['VForm'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                this.rowSuratSakit.ausis_tanggal_awal =  moment(this.rowSuratSakit.ausis_tanggal_awal).format('YYYY-MM-DD')
                this.rowSuratSakit.ausis_tanggal_akhir =  moment(this.rowSuratSakit.ausis_tanggal_akhir).format('YYYY-MM-DD')

                if(this.rowSuratSakit.ausis_tanggal_akhir < this.rowSuratSakit.ausis_tanggal_awal){
                    return this.$swal({
                        icon: 'error',
                        title: 'Tanggal Akhir Tidak Boleh Lebih Kecil dari Tanggal Awal'
                    })
                }
                        
                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowSuratSakit
                            data.type = 'submit-data-surat-sakit'
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
    },  
    mounted() {
        this.apiGetSuratSakit()
    },
    watch: {
      rowSuratSakit: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
    }
}      
</script>
